import { GalleryFolder } from "../../@types/gallery"
import { BASE_URL } from "../../utils/api"

// --------------------------------------------------------------------------------

interface FolderTileProps {
    folder: GalleryFolder
    onClick: React.MouseEventHandler<HTMLDivElement>
    name?: boolean
}

export const FolderTile: React.FC<FolderTileProps> = ({ folder, onClick, name }) => {
    const isDataUrl = (url: string) => url.startsWith('data:')

    return (
        <div className="gallery-folder" onClick={onClick}>
            <div>

                <div style={folder.images[2] && {
                    backgroundImage: isDataUrl(folder.images[2].url)
                        ? `url(${folder.images[2].url})`
                        : `url(${BASE_URL}/upload/3/256/${folder.images[2].url})`
                }} />

                <div style={folder.images[1] && {
                    backgroundImage: isDataUrl(folder.images[1].url)
                        ? `url(${folder.images[1].url})`
                        : `url(${BASE_URL}/upload/3/256/${folder.images[1].url})`
                }} />

                <div style={folder.images[0] && {
                    backgroundImage: isDataUrl(folder.images[0].url)
                        ? `url(${folder.images[0].url})`
                        : `url(${BASE_URL}/upload/3/256/${folder.images[0].url})`
                }} />

            </div>
            {name &&
                <div>{folder.name}</div>}
        </div>
    )
}