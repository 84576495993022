import { useCallback, useEffect, useRef, useState } from "react"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { useAppDispatch, useAppSelector } from "../store"
import { autofillImages, setImage } from "../store/reducers/albumSlice"
import { useNavigate } from "react-router-dom"
import { ImageTile } from "./ui/ImageTile"
import { BottomSheetActions, BottomSheetActionsProps, BottomSheetMainAction } from "./ui/BottomSheetActions"
import { deselectFolder, removeFromGallery, selectFolder, setHideUsed } from "../store/reducers/gallerySlice"
import { FolderTile } from "./ui/FolderTile"
import { uploadImages } from "../utils/uploadWithProgress"
import { showSnackbar } from "../store/reducers/snackbarSlice"
import { ApertureIcon, CheckCircleIcon, ChevronLeftIcon, DeleteIcon, EyeIcon, EyeOffIcon, MenuIcon, UploadIcon } from "./ui/icons"

// --------------------------------------------------------------------------------

export const Gallery: React.FC = () => {
    const dndEvents = useDragAndDrop()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { editorData, isVisible } = useAppSelector(state => state.bottomSheet)
    const { gallery, hideUsed, folders, selectedFolder } = useAppSelector(state => state.gallery)

    const ref = useRef<HTMLDivElement>(null)

    const [selectMode, setSelectMode] = useState(false)
    const [deleteList, setDeleteList] = useState<string[]>([])
    const [mainAction, setMainAction] = useState<BottomSheetMainAction>()

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        const imgSrc = target.parentElement?.dataset.imgSrc
        const imgWidth = target.parentElement?.dataset.imgWidth
        const imgHeight = target.parentElement?.dataset.imgHeight

        if (!editorData || !imgSrc) return
        const { itemId, templateComponent } = editorData
        dispatch(setImage({
            itemId,
            component: {
                id: "",
                system_id: "",
                project_item_id: itemId,
                template_component_id: templateComponent.id,
                value: imgSrc,
                style: JSON.stringify({ imgWidth, imgHeight }),
                has_error: "",
            }
        }))
        navigate(-1)
    }

    const handleRemove = useCallback(() => {
        showSnackbar({
            text: 'Удалить фото?',
            actions: {
                submit: 'ДА',
                cancel: 'НЕТ',
                submitAction: () => {
                    dispatch(removeFromGallery(deleteList))
                    setSelectMode(false)
                    setDeleteList([])

                    setTimeout(() => {
                        showSnackbar({
                            text: 'Фото удалены.',
                            position: 'top',
                            leadingIcon:
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#22543D" />
                                    <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" fill="#68D391" />
                                </svg>
                        })
                    }, 500)
                }
            }
        })
    }, [deleteList])

    const actionProps: BottomSheetActionsProps = {
        active: {
            icon: <MenuIcon />,
            title: 'Действия',
        },
        mainAction,
        list: [
            {
                title: 'Загрузить',
                icon: <UploadIcon />,
                action: () => uploadImages()
            },
            {
                title: 'Автозаполнить книгу',
                icon: <ApertureIcon />,
                action: () => showSnackbar({
                    text: 'Заполнить книгу загруженными фото?',
                    actions: {
                        submit: 'ДА',
                        cancel: 'НЕТ',
                        submitAction: () => {
                            navigate('')
                            selectedFolder
                                && dispatch(autofillImages(selectedFolder.images))


                            setTimeout(() => {
                                showSnackbar({
                                    text: 'Книга успешно заполнена.',
                                    position: 'top',
                                    leadingIcon:
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#22543D" />
                                            <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" fill="#68D391" />
                                        </svg>
                                })
                            }, 500)
                        }
                    }
                })
            },
            {
                title: hideUsed ? 'Показать используемые фото' : 'Скрыть используемые фото',
                icon: hideUsed ? <EyeIcon /> : <EyeOffIcon />,
                action: () => dispatch(setHideUsed(!hideUsed)),
                activated: hideUsed
            },
            {
                title: 'Отметить для удаления',
                icon: <CheckCircleIcon />,
                action: () => setSelectMode(!selectMode)
            }
        ]
    }

    // Clear selected on gallery close
    useEffect(() => {
        setSelectMode(false)
        setDeleteList([])

        let scrollTo = 200 - 94
        if (window.visualViewport)
            scrollTo = Math.round(window.visualViewport.height / 2 - 94)

        if (ref.current && isVisible) {
            ref.current.scrollTo(0, scrollTo)
            ref.current.classList.remove('no-scroll')
        }
    }, [selectedFolder, isVisible])

    useEffect(() => {
        if (selectMode)
            setMainAction({
                title: 'Удалить',
                icon: <DeleteIcon />,
                action: handleRemove,
                class: 'delete'
            })
        else
            setMainAction(undefined)
    }, [selectMode, deleteList])

    const scrimStyle: React.CSSProperties = {
        position: "fixed",
        inset: 0,
        background: "var(--md-sys-color-scrim)",
        opacity: 0,
        transition: "opacity .24s ease-in-out",
        zIndex: 1
    }

    return (
        <>
            <div className="bottom-sheet-gallery" ref={ref}>
                <div className="container">

                    {(gallery ?? selectedFolder?.images)?.map((el, i) =>
                        <ImageTile
                            el={el}
                            events={editorData ? { onClick: handleClick } as const : dndEvents}
                            key={`gallery-img-${el.url.slice(-10) + i}`}
                            hideUsed={hideUsed}
                            selectMode={selectMode}
                            deleteList={deleteList}
                            setDeleteList={setDeleteList}
                        />
                    )}

                    {folders && !selectedFolder && folders.map(folder =>
                        <FolderTile
                            folder={folder}
                            key={`gallery-folder-${folder.id}`}
                            onClick={() => dispatch(selectFolder(folder))}
                            name
                        />
                    )}

                </div>
            </div>

            <div className="title">

                <md-icon-button
                    onClick={() => {
                        if (selectedFolder)
                            dispatch(deselectFolder())
                        else
                            navigate('')
                    }}>
                    <ChevronLeftIcon />
                </md-icon-button>

                {selectedFolder
                    ? selectedFolder.name
                    : 'Галерея'}

                {selectMode &&
                    <md-text-button
                        onClick={() => {
                            setSelectMode(false)
                            setDeleteList([])
                        }}
                    >
                        Отменить
                    </md-text-button>}
            </div>

            {selectedFolder &&
                <BottomSheetActions {...actionProps} />}
        </>
    )
}

export default Gallery