import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { disablePriviewMode, resetAlbum, setAlbumTitle } from "../store/reducers/albumSlice"
import { resetUser } from "../store/reducers/authSlice"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"
import { Field, Form, Formik, FormikProps } from "formik"
import FocusLock from "react-focus-lock"
import { enqueueSnackbar } from "notistack"
import { createPortal } from "react-dom"
import { IS_APPLE } from "../utils/environment"
import { CheckIcon, ChevronLeftIcon, CloseIcon, DownloadIcon, EditIcon, EyeIcon, FullscreenExitIcon, FullscreenIcon, HelpIcon, HideAddressBarIcon, LoginIcon, MoreVerticalIcon, SettingsIcon, ShareIcon, SignInIcon } from "./ui/icons"
import { ModalBottomSheet } from "./ui/ModalBottomSheet"
import { disableTextEditMode } from "../store/reducers/textEditorSlice"
import { resetOnboarding, setElPosition } from "../store/reducers/onboardingSlice"
import { applyTheme, argbFromHex, hexFromArgb, themeFromSourceColor } from "@material/material-color-utilities"

// --------------------------------------------------------------------------------

export const Header: React.FC = () => {
    const { isAuthenticated } = useAppSelector(state => state.auth)
    const { album, previewMode } = useAppSelector(state => state.album)
    const { textEditMode, isVisible: textEditorIsVisible } = useAppSelector(state => state.textEditor)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const dispatch = useAppDispatch()

    const headerRef = useRef<HTMLHeadElement>(null)
    const bsRef = useRef<HTMLDivElement>(null)
    const bsScrimRef = useRef<HTMLDivElement>(null)
    const headerFormRef = useRef<HTMLFormElement>(null)
    const formikRef = useRef(null)
    const menuBtnRef = useRef<HTMLDivElement>(null)

    const [fullscreen, setFullscreen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [hider, setHider] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    const [quit, setQuit] = useState(false)

    const [themeColor, setThemeColor] = useState('#2251CC')

    const isMobile = 'ontouchstart' in window

    // set onboarding el position for main step 1 and step 2
    useEffect(() => {
        if (headerFormRef.current && onboardingMode === 'main' && step === 1) {
            const { height, left, top, width } = headerFormRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                height: `${height}px`,
                left: `${left - 8}px`,
                top: `${top}px`,
                width: `${width}px`,
            }))
        }
        if (menuBtnRef.current && onboardingMode === 'main' && step === 2) {
            const { height, left, top, width } = menuBtnRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                height: `${height}px`,
                left: `${left}px`,
                top: `${top}px`,
                width: `40px`,
            }))
        }
    }, [onboardingMode, step])

    const closeBs = useCallback(() => {
        if (bsRef.current)
            bsRef.current.classList.remove('opened')
        bsScrimRef.current?.removeAttribute('style')
        setTimeout(() => {
            quit && setQuit(false)
            menuOpened && setMenuOpened(false)
        }, 300)
    }, [quit, menuOpened])

    const shareData = {
        title: "TCS mobile constructor",
        text: "Try brand new typography constructor",
        url: "https://mobile-constructor.wow2print.com/",
    }

    const handleTitleSubmit = ({ title }: { title: string }) => {
        if (editMode) {
            setEditMode(false)
            dispatch(setAlbumTitle(title))
        } else
            setEditMode(true)
    }

    const toggleFullscreen = () => {

        if (!fullscreen) {
            document.documentElement.requestFullscreen()
                .catch(err => enqueueSnackbar(err.message, { variant: 'error' }))
            setFullscreen(true)
        } else {
            document.exitFullscreen()
            setFullscreen(false)
        }
    }

    const handleLogIn = () => {
        closeBs()
        dispatch(setBottomSheetData({ auth: true }))
    }

    const handleLogOut = () => {
        closeBs()
        dispatch(resetUser())
    }

    const handleThemeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const hexValue = e.target.value
        const theme = themeFromSourceColor(argbFromHex(hexValue))
        setThemeColor(hexValue)

        applyTheme(theme, { target: document.body, dark: false })

        // css string for theme.light.css
        let css = ':root {'
        for (const [key, value] of Object.entries(theme.schemes.light.toJSON())) {
            const token = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
            const color = hexFromArgb(value)
            css += ` --md-sys-color-${token}-light: ${color};`
        }
        css += '}'

        console.log(css)
    }

    // Hide iOS Safari address bar
    useEffect(() => {
        if (!IS_APPLE) return

        if (hider) {
            document.documentElement.style.setProperty('--ios-overflow', `auto`)
            document.documentElement.style.setProperty('--ios-address-hider', `150vh`)
        } else {
            document.documentElement.style.removeProperty('--ios-overflow')
            document.documentElement.style.removeProperty('--ios-address-hider')
        }
    }, [hider])

    // Bottom sheet on quit
    const saveOnQuit = () => {
        closeBs()
        dispatch(setBottomSheetData({ auth: true }))
    }

    const submitQuit = () => {
    }

    const handleNavigateBack = () => {
        if (previewMode)
            return dispatch(disablePriviewMode())

        if (textEditMode)
            return dispatch(disableTextEditMode())

        if (editMode)
            setEditMode(false)
        else
            setQuit(true)
    }

    useEffect(() => {
        if ((menuOpened || quit) && bsRef.current && bsScrimRef.current) {
            bsRef.current.style.bottom = `-${bsRef.current.getBoundingClientRect().height.toFixed()}px`
            bsScrimRef.current.style.opacity = '0.35'
            setTimeout(() => {
                if (bsRef.current)
                    bsRef.current.classList.add('opened')
            }, 100)
        }
    }, [quit, menuOpened])

    // Fixes iOS buggly viewport with the keyboard opened
    useEffect(() => {
        if (!IS_APPLE || !headerRef.current) return

        const header = headerRef.current

        if (textEditorIsVisible) {
            header.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false })
        } else {
            header.removeEventListener('touchmove', (e) => e.preventDefault())
        }
    }, [textEditorIsVisible])

    // Click outside to submit
    const handleTouchOutside = useCallback((e: Event) => {
        if (onboardingMode || isMobile || !formikRef.current) return
        const target = e.target as Element

        if (target.tagName !== 'INPUT' && target.tagName !== 'SPAN') {
            (formikRef.current as FormikProps<{ title: string }>).handleSubmit()
        }
    }, [])

    useEffect(() => {
        if (editMode)
            document.body.addEventListener('click', handleTouchOutside, { passive: true })
        else
            document.body.removeEventListener('click', handleTouchOutside)

    }, [editMode])

    return (
        <header className={`${isMobile ? 'mobile' : 'desktop'}${previewMode ? ' preview' : ''}`} ref={headerRef}>
            {isMobile
                ? <md-icon-button onClick={handleNavigateBack}>
                    <ChevronLeftIcon />
                </md-icon-button>
                // TODO: set image url
                : <img src="/images/logo.png" />}

            <Formik
                initialValues={{ title: album ? album.params.name : '' }}
                onSubmit={handleTitleSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                <Form ref={headerFormRef} className={editMode ? 'edit' : undefined}>
                    {editMode
                        ?
                        <FocusLock>
                            <Field name='title' placeholder='Введите имя проекта' />
                        </FocusLock>
                        :
                        <div className="header-title" onClick={() => !isMobile && setEditMode(true)}>
                            <span>{album && album.params.name}</span>
                        </div>}

                    {!textEditMode && !previewMode && isMobile &&
                        <md-icon-button
                            type="submit"
                        >
                            {editMode
                                ?
                                <CheckIcon />
                                :
                                <EditIcon />}
                        </md-icon-button>}

                </Form>
            </Formik>

            {!isMobile &&
                <div className="header-product-price">
                    {Number(album?.params.price).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}
                </div>}

            {!textEditorIsVisible && isMobile &&
                <md-text-button
                    class={`header-save-button ${editMode ? 'edit' : ''}`}
                    onClick={!isAuthenticated
                        ? () => dispatch(setBottomSheetData({ auth: true }))
                        : undefined}
                >
                    Сохранить
                </md-text-button>}

            {isMobile
                ?
                <div
                    style={{ position: "relative" }}
                    className={`header-menu-button ${editMode || textEditMode ? 'edit' : ''}`}
                    ref={menuBtnRef}
                >
                    <md-icon-button
                        onClick={() => setMenuOpened(true)}
                        class={isAuthenticated ? 'auth' : undefined}
                    >
                        {isAuthenticated
                            ? <LoginIcon />
                            : <MoreVerticalIcon />}

                    </md-icon-button>
                </div>
                :
                <>
                    <md-outlined-button class="login" onClick={() => dispatch(setBottomSheetData({ auth: true }))}>
                        <SignInIcon slotted />
                        Войти
                    </md-outlined-button>
                    <md-text-button class="preview">
                        <EyeIcon slotted />
                        Предпросмотр
                    </md-text-button>
                </>}

            {/* Hider */}
            {hider && createPortal(
                <div id="ios-address-bar-hider">
                    Проведите пальцем по экрану вверх<br />
                    пока не скроется панель адреса,<br />
                    затем нажмите готово
                    <md-text-button
                        onClick={() => setHider(false)}
                    >
                        Готово
                    </md-text-button>
                </div>,
                document.body)}

            {/* Qiut menu */}
            {quit && createPortal(
                <div className="quit-constructor">
                    <div ref={bsScrimRef} />
                    <div ref={bsRef}>
                        <md-elevation />

                        <div>
                            Проект не сохранен
                            <md-icon-button onClick={closeBs}>
                                <CloseIcon />
                            </md-icon-button>
                        </div>

                        <div>
                            У вас есть не сохраненные изменения. При выходе без сохранения все данные потеряются.
                        </div>

                        <div>
                            <md-filled-button onClick={saveOnQuit}>
                                Сохранить
                            </md-filled-button>

                            <md-outlined-button onClick={submitQuit}>
                                Выйти без сохранения
                            </md-outlined-button>
                        </div>
                    </div>
                </div>,
                document.body)}

            {/* Main menu */}
            <ModalBottomSheet
                state={menuOpened}
                setState={setMenuOpened}
                className="main-menu"
            >
                <div>
                    <SettingsIcon />
                    Параметры
                </div>

                <div onClick={() => navigator.share(shareData)}>
                    <ShareIcon />
                    Поделиться
                </div>

                <div onClick={() => dispatch(resetAlbum())}>
                    <DownloadIcon />
                    Скачать в PDF
                </div>

                <div onClick={() => {
                    setMenuOpened(false)
                    dispatch(resetOnboarding())
                }}>
                    <HelpIcon />
                    Помощь
                </div>

                <div onClick={handleLogIn}>
                    <SignInIcon />
                    Войти
                </div>

                <div>
                    <input
                        type="color"
                        style={{ width: '27px', padding: '0', border: 'none' }}
                        value={themeColor}
                        onChange={handleThemeChange}
                    />
                    Тема
                </div>

                {IS_APPLE &&
                    <div onClick={() => { setHider(true); closeBs() }}>
                        <HideAddressBarIcon />
                        Скрыть панель адреса
                    </div>}

                {!!document.documentElement.requestFullscreen &&
                    <div onClick={toggleFullscreen}>
                        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        {fullscreen ? 'Выйти из полноэкранного режима' : 'На весь экран'}
                    </div>}

            </ModalBottomSheet>

        </header >
    )
}