import { Form, Formik, FormikHelpers } from "formik"
import { MdFormikField } from "../ui/MdFormikField"
import { MdFormikCheckbox } from "../ui/MdFormikCheckbox"
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import { useEffect, useRef } from "react"
import api, { ApiRoutes } from "../../utils/api"
import { FetchApiError } from "@russh/fetch-api"
import { ReCAPTCHA } from "../../@types/reCAPTCHA"
import { ChevronLeftIcon, LockIcon, MailIcon, PhoneIcon } from "../ui/icons"

YupPassword(Yup)
declare var grecaptcha: ReCAPTCHA

// --------------------------------------------------------------------------------

interface SignUpProps {
    next: () => void
    prev: () => void
}

export const SignUp: React.FC<SignUpProps> = ({ next, prev }) => {
    const recaptchaRef = useRef<HTMLDivElement>(null)

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Не корректный email')
            .required('Введите электронную почту'),
        password: Yup.string()
            .password()
            .required('Введите пароль')
            .min(8, 'Пароль должен быть более 8 символов')
            .minLowercase(1, 'Минимум 1 буква в нижнем регистре')
            .minUppercase(1, 'Минимум 1 буква в верхнем регистре')
            .minNumbers(1, 'Минимум 1 цифра')
            .minSymbols(1, 'Минимум 1 символ'),
        passwordConfirmation: Yup.string()
            .required('Повторите пароль')
            .oneOf([Yup.ref('password')], 'Пароли не совпадают')
    })

    const initialValues = {
        email: '',
        password: '',
        passwordConfirmation: ''
    }

    const handleSubmit = async (
        { passwordConfirmation, ...data }: typeof initialValues,
        { setErrors }: FormikHelpers<typeof initialValues>
    ) => {
        try {
            await api.post(ApiRoutes.SIGNUP, data)
            next()
        } catch (e) {
            const err = e as FetchApiError
            if (err.fields) setErrors(err.fields)
        }
    }

    // Load recaptcha
    useEffect(() => {
        (() =>
            recaptchaRef.current &&
            grecaptcha.render(recaptchaRef.current, {
                'sitekey': '6LfW_nQoAAAAAJwlmah6rfrXNgXgnnL6OS8Sj-Zm',
                'callback': (responceToken: string) => {
                    console.log(responceToken)
                },
                'theme': 'light'
            })
        )()
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <Form className="sign-up">
                <md-text-button
                    type="button"
                    class="left"
                    onClick={prev}
                >
                    Назад
                    <ChevronLeftIcon slotted />
                </md-text-button>

                <section>
                    <label>
                        Регистрация
                    </label>
                    <MdFormikField
                        name="lastName"
                        label="Фамилия"
                    />
                    <MdFormikField
                        name="firstName"
                        label="Имя"
                    />
                </section>

                <section>
                    <label>
                        Контактные данные
                    </label>
                    <MdFormikField
                        name="email"
                        label="Электронная почта"
                        icon={<MailIcon />}
                    />
                    <MdFormikField
                        name="phone"
                        type="tel"
                        label="Телефон"
                        icon={<PhoneIcon />}
                    />
                </section>

                <section>
                    <label>
                        Пароль
                    </label>
                    <MdFormikField
                        name="password"
                        type="password"
                        label="Пароль"
                        icon={<LockIcon />}
                    />
                    <MdFormikField
                        name="passwordConfirmation"
                        type="password"
                        label="Подтверждение пароля"
                        icon={<LockIcon />}
                    />
                </section>

                <div className="checkboxes">
                    <label>
                        <MdFormikCheckbox name="getEmails" touch-target="wrapper" id="get-emails-checkbox" />
                        <span>Да, я хочу получать письма</span>
                    </label>

                    <label>
                        <MdFormikCheckbox name="agreement" touch-target="wrapper" id="agreement-checkbox" />
                        <span>Настоящим подтверждаю, и даю согласие на обработку своих персональных данных в соответствии с условиями обработки персональных данных</span>
                    </label>
                </div>

                <div
                    ref={recaptchaRef}
                    className="recaptcha-widget"
                    data-sitekey="6Le25nQoAAAAAHbQR09wwzIaKyM0ud3vfL-vfJKR"
                />

                <div className="btns">
                    <md-filled-button
                        id="sign-up-submit-btn"
                        type="submit"
                    >
                        Создать аккаунт
                    </md-filled-button>
                </div>
            </Form>
        </Formik>
    )
}