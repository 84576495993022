import { DragEventHandler, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { uploadImages } from "../utils/uploadWithProgress"
import { ModalBottomSheet } from "./ui/ModalBottomSheet"
import { addToFolder, createFolder, selectFolder } from "../store/reducers/gallerySlice"
import { CloseIcon, FolderListChecked, FolderListUnchecked, UploadLogo } from "./ui/icons"
import { FolderTile } from "./ui/FolderTile"
import Focus from "react-focus-lock"
import { Field, Form, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import { setActive } from "../store/reducers/toolbarSlice"
import { GalleryFolder, GalleryImage } from "../@types/gallery"
import { readImageFile } from "../utils/imageReader"

// --------------------------------------------------------------------------------

export const Upload = () => {
    const { isAuthenticated } = useAppSelector(state => state.auth)
    const { folders, loadedImgs, selectedFolder } = useAppSelector(state => state.gallery)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [folderListShown, setFolderListShown] = useState(false)
    const [addingFolder, setAddingFolder] = useState(false)
    const [selectedFolderInList, setSelectedFolderInList] = useState<GalleryFolder>()

    const [downloadItemsList, setDownloadItemsList] = useState<string[]>([])
    const [isDragOver, setIsDragOver] = useState(false)

    const isMobile = 'ontouchstart' in window

    const handleSubmit = () => {
        selectedFolderInList &&
            dispatch(selectFolder(selectedFolderInList))
        setFolderListShown(false)
        setTimeout(() => {
            dispatch(setActive('gallery'))
            navigate(-1)
        }, 280)
    }

    const handleFolderCreate = ({ title }: { title: string }) => {
        dispatch(createFolder(title))
        setFolderListShown(false)
        setTimeout(() => {
            dispatch(setActive('gallery'))
            navigate(-1)
        }, 280)
    }

    const handleDrop: DragEventHandler<HTMLDivElement> = ev => {
        console.log(ev.dataTransfer.files[0])

        const urls = Array.from(ev.dataTransfer.files).map(file => readImageFile(file))
        Promise.all(urls).then(dataUrls => setDownloadItemsList(dataUrls))
        setIsDragOver(false)
    }

    useEffect(() => {
        if (folderListShown && folders && folders.length > 0)
            setSelectedFolderInList(folders[0])
    }, [folderListShown])

    useEffect(() => {
        if (!loadedImgs) return

        if (selectedFolder) {

            const galleryImgs: GalleryImage[] = loadedImgs.map(url => ({
                id: 0,
                url,
                height: 256,
                width: 256
            }))

            dispatch(addToFolder({ folderId: selectedFolder.id, images: galleryImgs }))
        } else {
            setFolderListShown(true)
        }

    }, [loadedImgs, selectedFolder])

    return (
        <>
            <div className={`bottom-sheet-upload`}>
                {isMobile
                    ?
                    <>
                        <UploadLogo />
                        <div className="load-images">
                            <div>
                                Начните с загрузки<br />фотографий
                            </div>
                            <div>
                                Файл не более <span>25 Мб</span>
                            </div>
                            <div>
                                <div>JPG</div>
                                <div>HEIC</div>
                            </div>
                        </div>
                        <md-filled-button onClick={uploadImages}>
                            Выбрать фото
                        </md-filled-button>
                    </>
                    :
                    <>
                        <div
                            className={`upload-drop-zone ${isDragOver ? 'drag-over' : ''}`}
                            onDrop={handleDrop}
                            onDragOver={() => !isDragOver && setIsDragOver(true)}
                            onDragLeave={() => setIsDragOver(false)}
                            onClick={uploadImages}
                        >
                            <div>
                                Начните с загрузки<br />фотографий
                            </div>
                            <div>
                                <span>Загрузите</span> или перетащите
                                файлы с компьютера<br />
                                Файл не более <span>25 Мб</span>
                            </div>
                            <div>
                                <div>JPG</div>
                                <div>HEIC</div>
                            </div>
                        </div>
                    </>}

                <md-icon-button
                    class="close"
                    onClick={() => navigate(-1)}
                >
                    <CloseIcon />
                </md-icon-button>
            </div>

            <ModalBottomSheet
                state={folderListShown}
                setState={setFolderListShown}
                className="modal-folder-list"
            >
                {!addingFolder
                    ?
                    <>
                        <span>Выберите папку для загрузки фото</span>

                        <div>
                            {folders?.map((folder, index) =>
                                <div
                                    key={`modal-list-folder-${folder.id}`}
                                    onClick={() => setSelectedFolderInList(folder)}
                                >
                                    <FolderTile folder={folder} onClick={() => { }} />
                                    <div>{folder.name}</div>
                                    {selectedFolderInList === folder
                                        ? <FolderListChecked />
                                        : <FolderListUnchecked />}
                                </div>)}
                        </div>

                        <md-filled-button onClick={handleSubmit}>
                            Продолжить
                        </md-filled-button>

                        <md-outlined-button onClick={() => setAddingFolder(true)}>
                            Создать папку
                        </md-outlined-button>
                    </>
                    :
                    <>
                        <span>Создание папки</span>

                        <Formik
                            initialValues={{ title: '' }}
                            onSubmit={handleFolderCreate}
                        >
                            <Form>
                                <Focus>
                                    <Field name='title' />
                                </Focus>

                                <md-filled-button type='submit'>
                                    Создать папку
                                </md-filled-button>
                            </Form>
                        </Formik>
                    </>}

            </ModalBottomSheet>
        </>
    )
}