import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GalleryFolder, GalleryImage } from "../../@types/gallery"
import api, { ApiRoutes } from "../../utils/api"
import { FetchApiError } from "@russh/fetch-api"
import { gallery } from "../../../data"

// --------------------------------------------------------------------------------

export const getGallery = createAsyncThunk('gallery/get', async (payloads: { code: number }, thunkAPI) => {
    try {
        return await api.post(ApiRoutes.SIGNUP_VERIFY, payloads) as Promise<GalleryImage[]>
    } catch (e) {
        const error = e as FetchApiError
        return thunkAPI.rejectWithValue(error)
    }
})

// --------------------------------------------------------------------------------

const hideUsed = localStorage.getItem('hideUsed') === 'true' ? true : false

const initialState: {
    gallery?: GalleryImage[]
    folders?: GalleryFolder[]
    selectedFolder?: GalleryFolder
    loadedImgs?: string[]
    isLoading: boolean
    hideUsed: boolean
} = {
    isLoading: false,
    hideUsed
}

const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {

        // Folders
        setFolders: (state, action: PayloadAction<GalleryFolder[]>) => {
            state.folders = action.payload
        },

        selectFolder: (state, action: PayloadAction<GalleryFolder>) => {
            state.selectedFolder = action.payload
        },

        deselectFolder: (state) => {
            state.selectedFolder = undefined
        },

        createFolder: (state, action: PayloadAction<GalleryFolder['name']>) => {
            const maxId = state.folders ? Math.max(...state.folders.map(folder => folder.id)) : 0
            state.folders = [...state.folders ?? [], {
                id: maxId + 1,
                images: [],
                name: action.payload
            }]
            state.selectedFolder = state.folders.slice().pop()
        },

        removeFolder: (state, action: PayloadAction<GalleryFolder['id']>) => {
            state.folders = state.folders?.filter(folder => folder.id !== action.payload)
        },

        addToFolder: (state, action: PayloadAction<{ folderId: Number, images: GalleryImage[] }>) => {
            if (state.selectedFolder)
                state.selectedFolder.images.unshift(...action.payload.images)

            const index = state.folders?.findIndex(folder => folder.id === action.payload.folderId)
            if (state.folders && index !== undefined)
                state.folders[index].images.unshift(...action.payload.images)

            state.loadedImgs = undefined
        },


        // Gallery
        setGallery: (state, action: PayloadAction<GalleryImage[]>) => {
            state.gallery = action.payload
        },

        addToGallery: (state, action: PayloadAction<GalleryImage[]>) => {
            state.gallery = [...action.payload, ...state.gallery ?? []]
        },

        removeFromGallery: (state, action: PayloadAction<string[]>) => {
            const index = state.folders?.findIndex(folder => folder.id === state.selectedFolder?.id)
            const images = state.selectedFolder?.images.filter(img => !action.payload.includes(img.url))
            if (state.folders && state.selectedFolder && images && index !== undefined) {
                state.selectedFolder.images = images
                state.folders[index].images = images
            } else
                state.gallery = state.gallery?.filter(img => !action.payload.includes(img.url))
        },

        setLoadedImgs: (state, action: PayloadAction<string[]>) => {
            state.loadedImgs = action.payload
        },

        resetLoadedImgs: (state) => {
            state.loadedImgs = undefined
        },

        setHideUsed: (state, action: PayloadAction<boolean>) => {
            state.hideUsed = action.payload
            localStorage.setItem('hideUsed', `${action.payload}`)
        },

    },
    extraReducers: builder => {
        builder

            // GET ------------------------------------------------------------------
            .addCase(getGallery.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getGallery.fulfilled, (state, action) => {
                state.gallery = action.payload
                state.isLoading = false
            })
            .addCase(getGallery.rejected, (state) => {
                state.gallery = initialState.gallery
                state.isLoading = false
            })
    },
})

export const {
    setFolders,
    setGallery,
    addToGallery,
    selectFolder,
    deselectFolder,
    removeFromGallery,
    setHideUsed,
    setLoadedImgs,
    createFolder,
    removeFolder,
    addToFolder,
    resetLoadedImgs
} = gallerySlice.actions

export default gallerySlice.reducer