import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../store"
import { useBottomSheetSwipe } from "../hooks/useBottomSheetSwipe"
import { useNavigate } from "react-router-dom"
import Gallery from "./Gallery"
import Layouts from "./Layouts"
import Background from "./Background"
import { Upload } from "./Upload"
import { Auth } from "./Auth"

// --------------------------------------------------------------------------------

export const BottomSheet: React.FC = () => {
    const bsState = useAppSelector(state => state.bottomSheet)
    const {
        isVisible,
        upload,
        gallery,
        template,
        background,
        auth,
    } = bsState
    const { dragActive } = useAppSelector(state => state.dnd)
    const sheetRef = useRef<HTMLDivElement>(null)
    const swipeEvents = useBottomSheetSwipe()
    const navigate = useNavigate()

    const isMobile = 'ontouchstart' in window

    // Sheet observer
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            const [entry] = entries
            sheetRef.current?.classList.toggle("top", entry.intersectionRatio == 1)
        }, { threshold: [0.1, 1] })
        if (sheetRef.current) observer.observe(sheetRef.current)

        return () => {
            if (sheetRef.current) observer.unobserve(sheetRef.current)
        }
    }, [])

    useEffect(() => {
        sheetRef.current?.scrollTo({ top: 0 })
        document.body.classList.toggle("no-scroll", isVisible)
        if (isVisible)
            switch (true) {
                case gallery:
                    navigate(`gallery`)
                    break

                case background:
                    navigate(`bg`)
                    break
                case template:
                    navigate(`template`)
                    break

                case upload:
                    navigate(`upload`)
                    break

                case auth:
                    navigate(`auth`)
                    break
            }

    }, [bsState])

    useEffect(() => {
        if (dragActive && isVisible) navigate(-1)
    }, [dragActive])

    return (
        <div
            className={`${isVisible ? 'bottom-sheet' : 'bottom-sheet hidden'}${!isMobile ? ' desktop' : ''}`}
            ref={sheetRef}
            {...swipeEvents}
        >
            <md-elevation />
            {upload && <Upload />}
            {gallery && <Gallery />}
            {template && <Layouts />}
            {background && <Background />}
            {auth && <Auth />}
        </div>
    )
}

export default BottomSheet