import { useAppSelector } from "../store"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { useNavigate } from "react-router-dom"
import { useEffect, useRef } from "react"
import { ChevronLeftIcon } from "./ui/icons"

// --------------------------------------------------------------------------------

export const Background: React.FC = () => {
    const { album } = useAppSelector(state => state.album)
    const { isVisible } = useAppSelector(state => state.bottomSheet)
    const dndEvents = useDragAndDrop()
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let scrollTo = 200 - 94
        if (window.visualViewport)
            scrollTo = Math.round(window.visualViewport.height / 2 - 94)

        if (ref.current && isVisible) {
            ref.current.scrollTo(0, scrollTo)
            ref.current.classList.remove('no-scroll')
        }
    }, [isVisible])


    return (
        <>
            <div className="bottom-sheet-background" ref={ref}>
                <div className="container">

                    {album && Object.entries(album.bgColors).map(([id, row]) =>

                        <div key={`background-row-${id}`}>
                            {row.map(el =>

                                <div
                                    key={`background-color-box-${el.id}`}
                                    className="color-box"
                                    style={{ backgroundColor: `rgb(${el.rgb})` }}
                                    data-background-group-id={el.group_id}
                                    data-background-id={el.id}
                                    {...dndEvents}
                                />)}

                        </div>)}
                </div>
            </div>

            <div className="title">

                <md-icon-button
                    onClick={() => navigate(-1)}>
                    <ChevronLeftIcon />
                </md-icon-button>

                Фон
            </div>
        </>
    )
}

export default Background