import { MdOutlinedTextField } from "@material/web/textfield/outlined-text-field"
import { useEffect, useRef, useState } from "react"
import { useField } from "formik"
import { WebComponent } from "../../@types/global"
import { MdCheckbox } from "@material/web/checkbox/checkbox"

// --------------------------------------------------------------------------------

type MdFormikCheckboxProps = WebComponent<MdCheckbox> & {
    name: string
}

export const MdFormikCheckbox: React.FC<MdFormikCheckboxProps> = ({ name, ...props }) => {
    const [{ value }, { error }, { setValue }] = useField(name)
    const checkboxRef = useRef<MdCheckbox>(null)
    const checkbox = checkboxRef.current

    const handleChange = () => {
        checkbox &&
            setValue(checkbox.checked)
    }

    return (
        <md-checkbox ref={checkboxRef} {...props} onChange={handleChange} />
    )
}