import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { ItemComponentStyle } from "../@types/album"
import { setStyles } from "../store/reducers/albumSlice"
import { useBottomSheetSwipe } from "../hooks/useBottomSheetSwipe"
import { useNavigate } from "react-router-dom"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"
import { BASE_URL } from "../utils/api"
import Cropper from 'cropperjs'
import { badEmoji, goodEmoji, midEmoji } from "./ui/emoji"
import { ApertureFillIcon, ChevronLeftIcon, CloseIcon, ImagesIcon, RotateIcon } from "./ui/icons"

// --------------------------------------------------------------------------------

export const ImageEditor: React.FC = () => {
    const dispatch = useAppDispatch()
    const { album } = useAppSelector(state => state.album)
    const { isVisible, templateComponent, itemId } = useAppSelector(state => state.imageEditor)
    const swipeEvents = useBottomSheetSwipe()
    const navigate = useNavigate()

    const sheetRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const cropAreaRef = useRef<HTMLDivElement>(null)
    const cropper = useRef<Cropper>()
    const canvasSize = useRef<{ width: number, height: number }>()

    const [previewIsLoading, setPreviewIsLoading] = useState(true)
    const [grayscale, setGrayscale] = useState(false)
    const [imgSize, setImgSize] = useState<'256' | '512' | '1024' | '2048' | 'original'>()
    const [dotDensity, setDotDensity] = useState<number>()
    const [cropperReady, setCropperReady] = useState(false)
    const [style, setStyle] = useState<ItemComponentStyle | null>()

    const isMobile = 'ontouchstart' in window

    const itemComponent = album && templateComponent && itemId && album.items[itemId]
        ? album.items[itemId].components.find(c => c.template_component_id === templateComponent.id)
        : undefined

    const handleSave = () => {
        if (!cropper.current || !itemId || !templateComponent) return
        const { rotate } = cropper.current.getImageData()

        const newStyle = {
            angle: rotate,
            zoom: "",
            offsetX: "1",
            offsetY: "1",
            cropperCanvas: cropper.current.getCanvasData(),
            cropperBox: cropper.current.getCropBoxData(),
            colorFilter: grayscale ? "bw-filter" : "no-filter",
            imgWidth: style?.imgWidth,
            imgHeight: style?.imgHeight
        }

        navigate(-1)
        dispatch(setStyles({
            itemId: itemId,
            componentId: templateComponent.id,
            style: JSON.stringify(newStyle)
        }))

    }

    const handleChangeImage = () => {
        if (!itemId || !templateComponent) return
        dispatch(setBottomSheetData({ gallery: true, editorData: { itemId, templateComponent } }))
    }

    const dotDensityToResolution = (dotDensity: number, width: number, height: number) => {
        const dotPerMm = dotDensity * 0.0393700787401575
        const dotPerWidth = width * dotPerMm
        const dotPerHeight = height * dotPerMm

        return `${dotPerWidth.toFixed()}×${dotPerHeight.toFixed()} px`
    }

    const handleZoom = () => {
        if (!cropper.current || !templateComponent || !style?.imgWidth || !style?.imgHeight) return

        const { width, height } = cropper.current.getImageData()
        const size = Math.min(width, height)
        const zoom = width / (cropAreaRef.current?.getBoundingClientRect().width ?? 1)
        const widthInInches = +templateComponent.width / 25.4
        setDotDensity(+style.imgWidth / zoom / widthInInches)

        setTimeout(() => {
            switch (true) {
                case size >= 1024: setImgSize('2048'); break;
                case size >= 512: setImgSize('1024'); break;
                case size >= 256: setImgSize('512'); break;
                case size < 256: setImgSize('256'); break;
            }
        }, 500)
    }

    // Sheet observer
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            const [entry] = entries
            sheetRef.current?.classList.toggle("top", entry.intersectionRatio == 1)
        }, { threshold: [0.1, 1] })
        if (sheetRef.current) observer.observe(sheetRef.current)

        return () => {
            if (sheetRef.current) observer.unobserve(sheetRef.current)
        }
    }, [])

    // Prevent touch events propagation to the bottom sheet
    useEffect(() => {
        const stopPropagation = (e: TouchEvent) => e.stopPropagation()
        cropAreaRef.current?.addEventListener('touchmove', stopPropagation, { passive: true })
        return () => {
            cropAreaRef.current?.removeEventListener('touchmove', stopPropagation)
        }
    }, [cropAreaRef.current])

    // Set filter
    useEffect(() => {
        if (!itemComponent) return
        setTimeout(() => setPreviewIsLoading(true))
        setStyle(undefined)

        try {
            const currentStyle = JSON.parse(itemComponent.style) as ItemComponentStyle
            if (currentStyle.imgWidth && currentStyle.imgHeight) {
                const ratio = +currentStyle.imgWidth / +currentStyle.imgHeight
                if (ratio >= 1)
                    canvasSize.current = { width: 256, height: 256 / ratio }
                else
                    canvasSize.current = { height: 256, width: 256 * ratio }
            }
            setStyle(currentStyle)
        } catch {
            setStyle(null)
        }
    }, [itemComponent])

    // Set grayscale on load
    useEffect(() => {
        setGrayscale(style?.colorFilter === "bw-filter" ? true : false)
    }, [templateComponent, style])

    // Create cropper instance
    useEffect(() => {
        if (!cropAreaRef.current || !containerRef.current || !canvasRef.current || !templateComponent || style === undefined) return

        const { width: w, height: h } = containerRef.current.getBoundingClientRect()
        const width = w - 20
        const height = h - 20

        const aspectRatio = +templateComponent.width / +templateComponent.height
        if (aspectRatio >= 1 && height > width / aspectRatio || height * aspectRatio > width) {
            cropAreaRef.current.style.width = `${width.toFixed()}px`
            cropAreaRef.current.style.height = `${(width / aspectRatio).toFixed()}px`
        } else {
            cropAreaRef.current.style.width = `${(height * aspectRatio).toFixed()}px`
            cropAreaRef.current.style.height = `${height.toFixed()}px`
        }

        cropper.current = new Cropper(canvasRef.current, {
            viewMode: 3,
            autoCropArea: 1,
            dragMode: 'move',
            modal: false,
            center: false,
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            minContainerWidth: 0,
            minContainerHeight: 0,
            background: false,
            ready(e) {
                const cropper = e.currentTarget.cropper
                style?.angle && cropper.rotateTo(+style.angle)
                style?.cropperCanvas && cropper.setCanvasData(style.cropperCanvas)
                style?.cropperBox && cropper.setCropBoxData(style.cropperBox)
                handleZoom()
                setCropperReady(true)
            }
        })

        canvasRef.current.addEventListener('zoom', handleZoom)

        return () => {
            cropper.current?.destroy()
            cropper.current = undefined
            setDotDensity(undefined)
            setCropperReady(false)
            setImgSize(undefined)
            canvasRef.current?.removeEventListener('zoom', handleZoom)
        }

    }, [templateComponent, canvasRef.current, style])

    // Load an image of a proper size to preview when zoom in
    useEffect(() => {
        if (!itemComponent || !imgSize) return
        fetch(`${BASE_URL}/upload/3/${imgSize}/${itemComponent.value}`)
            .then(res => res.blob())
            .then(blob => {
                let previewImg = document.querySelector('.cropper-view-box img') as HTMLImageElement
                if (previewImg) previewImg.src = URL.createObjectURL(blob)

                let backdropImg = document.querySelector('.cropper-wrap-box img') as HTMLImageElement
                if (backdropImg) backdropImg.src = URL.createObjectURL(blob)
                setPreviewIsLoading(false)
            })
    }, [imgSize])

    return (
        <div
            className={`image-editor ${isMobile ? 'mobile' : 'desktop'} ${!isVisible ? 'hidden' : ''}`}
            ref={sheetRef}
            {...swipeEvents}
        >
            <div className='image-editor-title'>
                {isMobile
                    ? <>
                        <md-icon-button
                            onClick={() => navigate('')}
                        >
                            <ChevronLeftIcon />
                        </md-icon-button>
                        Редактор

                        <md-text-button
                            onClick={handleSave}>
                            Применить
                        </md-text-button>
                    </>
                    :
                    <>
                        Редактор
                        <md-icon-button onClick={() => navigate('')}>
                            <CloseIcon />
                        </md-icon-button>
                    </>}
            </div>

            <div className="image-editor-container" ref={containerRef}>

                <div
                    ref={cropAreaRef}
                    className={`crop-area ${grayscale ? 'filter' : ''}`}
                >
                    <canvas
                        ref={canvasRef}
                        width={canvasSize.current?.width}
                        height={canvasSize.current?.height}
                        style={{ display: 'none' }}
                    />
                </div>

                {!cropperReady || previewIsLoading &&
                    <md-circular-progress indeterminate />}

            </div>

            <div className="image-editor-actions">
                {isMobile
                    ? <>
                        <md-elevation />

                        <div className="btns">
                            <md-filled-tonal-icon-button onClick={() => cropper.current?.rotate(-90)}>
                                <RotateIcon />
                            </md-filled-tonal-icon-button>
                            <md-filled-tonal-icon-button onClick={() => setGrayscale(!grayscale)}>
                                <ApertureFillIcon />
                            </md-filled-tonal-icon-button>
                            <md-filled-tonal-icon-button onClick={handleChangeImage}>
                                <ImagesIcon />
                            </md-filled-tonal-icon-button>
                        </div>

                        <div className="qlt-desc">
                            {dotDensity && dotDensity >= 240 &&
                                <div className={`good`}><img src={goodEmoji} width={24} />Отличное фото</div>}

                            {dotDensity && dotDensity < 240 && dotDensity > 180 &&
                                <div className={`medium`}><img src={midEmoji} width={24} />Фото среднего качества</div>}

                            {dotDensity && dotDensity <= 180 &&
                                <div className={`bad`}><img src={badEmoji} width={24} />Фото плохого качества</div>}
                        </div>

                        <div className="qlt-sizes">
                            <div>
                                <span>Разрешение</span>
                                <span>
                                    {dotDensity && templateComponent &&
                                        dotDensityToResolution(dotDensity, +templateComponent.width, +templateComponent.height)}
                                </span>
                            </div>
                            <div>
                                <span>Рекомендуемое</span>
                                <span>
                                    {templateComponent &&
                                        dotDensityToResolution(240, +templateComponent.width, +templateComponent.height)}
                                </span>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="color-filter">
                            <span>Цветовой фильтр</span>
                            <div
                                className={!grayscale ? 'active' : ''}
                            >
                                <img
                                    src={`${BASE_URL}/upload/3/256/${itemComponent?.value}`}
                                    onClick={() => setGrayscale(false)}
                                />
                                Цветной
                            </div>

                            <div
                                className={grayscale ? 'active' : ''}
                            >
                                <img
                                    src={`${BASE_URL}/upload/3/256/${itemComponent?.value}`}
                                    onClick={() => setGrayscale(true)}
                                />
                                Ч/б
                            </div>
                        </div>

                        <div className="btns">
                            <md-filled-tonal-icon-button onClick={() => cropper.current?.rotate(-90)}>
                                <RotateIcon />
                            </md-filled-tonal-icon-button>
                            <md-filled-tonal-icon-button onClick={() => cropper.current?.rotate(90)}>
                                <RotateIcon />
                            </md-filled-tonal-icon-button>
                        </div>

                        <md-filled-button
                            onClick={handleSave}
                        >
                            Применить
                        </md-filled-button>
                    </>}

            </div>

        </div>
    )
}
