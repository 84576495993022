import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

// --------------------------------------------------------------------------------

interface ModalBottomSheetProps {
    state: boolean
    setState: Dispatch<SetStateAction<boolean>>
    children?: ReactNode
    className?: string
}

export const ModalBottomSheet: React.FC<ModalBottomSheetProps> = ({ state, setState, children, className }) => {
    const bsRef = useRef<HTMLDivElement>(null)
    const bsScrimRef = useRef<HTMLDivElement>(null)
    const scrollableRef = useRef<HTMLDivElement | null>(null)
    const y = useRef(0)
    const yPrev = useRef(0)

    const [isVisible, setIsVisible] = useState(false)

    const closeBs = useCallback(() => {
        if (bsRef.current)
            bsRef.current.classList.remove('opened')
        bsScrimRef.current?.removeAttribute('style')
        setTimeout(() => {
            setIsVisible(false)
            setState(false)
        }, 300)
    }, [])

    useEffect(() => {
        if (state)
            setIsVisible(true)
        else
            closeBs()
    }, [state])

    useEffect(() => {
        if (isVisible && bsRef.current && bsScrimRef.current) {

            bsRef.current.style.bottom = `-${bsRef.current.getBoundingClientRect().height.toFixed()}px`
            bsScrimRef.current.style.opacity = '0.35'
            setTimeout(() => {
                bsRef.current?.classList.add('opened')

                scrollableRef.current = Array.from(bsRef.current?.children ?? [])
                    .find(child => child.classList.contains('scrollable')) as HTMLDivElement | null
            })
        }
    }, [isVisible])

    // --------------------------------------------------------------------------------

    const getBottomPosition = (el: HTMLElement) => {
        const height = window.innerHeight
        const { bottom } = el.getBoundingClientRect()
        return height - bottom
    }

    const start = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (e.touches)
            yPrev.current = e.touches[0].clientY
    }, [])

    const move = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        const scrollableOnTop = scrollableRef.current ? scrollableRef.current.scrollTop === 0 : true

        if (bsRef.current && bsScrimRef.current && e.touches) {

            const { height } = bsScrimRef.current.getBoundingClientRect()
            const { bottom } = bsRef.current.getBoundingClientRect()

            scrollableRef.current?.classList.toggle('no-scroll', bottom !== height)

            y.current = yPrev.current - e.touches[0].clientY
            yPrev.current = e.touches[0].clientY

            const { top } = bsRef.current.getBoundingClientRect()
            const titleIsTarget = top - e.touches[0].clientY > -36

            if (scrollableOnTop || titleIsTarget) {
                let elBottom = height - bottom + y.current >= 0
                    ? 0
                    : height - bottom + y.current

                bsRef.current.classList.remove('opened')
                bsRef.current.style.bottom = `${elBottom}px`
                bsRef.current.style.transition = 'none'
            }
        }
    }, [])

    const end = useCallback(() => {
        if (bsRef.current) {
            const bottom = getBottomPosition(bsRef.current)

            if (bottom > -56) {
                bsRef.current.classList.add('opened')
            } else if (bottom < 0) {
                bsRef.current.classList.remove('opened')
                closeBs()
            }

            bsRef.current.removeAttribute('style')
        }
    }, [])

    const touchEvents = {
        onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => start(e),
        onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => move(e),
        onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => end()
    } as const

    // --------------------------------------------------------------------------------

    return (
        isVisible && createPortal(
            <div className="modal-bottom-sheet">
                <div ref={bsScrimRef} onClick={closeBs} />
                <div ref={bsRef} {...touchEvents} className={className}>
                    <md-elevation />

                    {children}

                </div>
            </div>,
            document.body)
    )
}