import { Item, ItemComponent } from "../@types/album"
import { TemplateComponent } from "../@types/templates"

// --------------------------------------------------------------------------------

export const sortItems = (a: Item, b: Item) => {
    if (+a.params.sort > +b.params.sort) return 1
    if (+a.params.sort < +b.params.sort) return -1
    return 0
}

export const sortByLevel = (a: TemplateComponent, b: TemplateComponent) => {
    if (+a.level > +b.level) return -1
    if (+a.level < +b.level) return 1
    return 0
}