import { useAppDispatch, useAppSelector } from "../store"
import { CloseIcon } from "./ui/icons"
import { disableOnboardingMode, enableOnboardingMode, setStep } from "../store/reducers/onboardingSlice"
import { setActive } from "../store/reducers/toolbarSlice"

// --------------------------------------------------------------------------------

const steps = {
    main: [
        { title: 'Название проекта и его редактирование.', position: 'bottom left' },
        { title: 'Дополнительные действия: Параметры, Поделиться, Скачать в PDF, Помощь, Войти.', position: 'bottom right' },
        { title: 'Выделенный разворот в котором можно поменять фото, фон, шаблон. При удержании выделенного разворота можно менять их местами ', position: 'top right' },
        { title: 'Добавление нового разворота после выделенного.', position: 'top center32' },
        { title: 'Переход в корзину с предпросмотром для оформления заказа. Предварительную стоимость видно при скроле.', position: 'top right' },
        { title: 'Здесь находятся все ваши загруженные фото.', position: 'top left' },
        { title: 'Здесь находятся все шаблоны которые вы можете применить к развороту или странице.', position: 'top center80' },
        { title: 'Здесь находятся все  фоны которые вы можете применить к развороту или странице.', position: 'top right' },
    ],
    gallery: [
        { title: 'Быстрый просмотр фото свайпом влево и вправо. Применение фото по тапу в выделенном развороте или перетаскиванием. ', position: 'top center' },
        { title: 'Добавление новых фото с вашего устройства.', position: 'top left' },
        { title: 'Иконка ориентации фото горизонтальная или вертикальная.', position: 'top center20' },
        { title: 'Переход на страницу Галерея.', position: 'top right' },
    ],
    template: [
        { title: 'Быстрый просмотр шаблонов свайпом влево и вправо. Применение шаблона по тапу в выделенном развороте или перетаскиванием.', position: 'top center' },
        { title: 'Быстрый фильтр шаблонов, переключается и применяется по тапу.', position: 'top left' },
        { title: 'Переход на страницу Шаблон.', position: 'top right' },
    ],
    background: [
        { title: 'Быстрый просмотр фонов свайпом влево и вправо. Применение фона по тапу в выделенном развороте или перетаскиванием.', position: 'top center' },
        { title: 'Переход на страницу Фон.', position: 'top right' },
    ]
}

const Bubble = () => {
    const dispatch = useAppDispatch()
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const isLastStep = onboardingMode && step === steps[onboardingMode].length

    const handleNextClick = () => {
        if (onboardingMode === 'main' && isLastStep) {
            dispatch(disableOnboardingMode())
            dispatch(setActive('gallery'))
            setTimeout(() => {
                dispatch(enableOnboardingMode('gallery'))
            }, 500)
        }
        else if (isLastStep)
            dispatch(disableOnboardingMode())
        else
            dispatch(setStep(step + 1))
    }

    const handlePrevClick = () => dispatch(setStep(step - 1))

    return (
        onboardingMode &&
        <div className={`bubble ${steps[onboardingMode][step - 1].position}`}>
            <div>
                <span>{steps[onboardingMode][step - 1].title}</span>
                <md-icon-button onClick={() => dispatch(disableOnboardingMode())}>
                    <CloseIcon />
                </md-icon-button>
            </div>
            <div>
                <div>
                    {step} из {steps[onboardingMode].length}
                </div>
                <div>
                    {step !== 1 &&
                        <md-text-button onClick={handlePrevClick}>
                            Назад
                        </md-text-button>}

                    <md-filled-button onClick={handleNextClick}>
                        {isLastStep ? 'Готово' : 'Далее'}
                    </md-filled-button>
                </div>
            </div>
        </div>
    )
}

export const Onboarding: React.FC = () => {
    const { onboardingMode, elPosition } = useAppSelector(state => state.onboarding)

    if (!onboardingMode) return null

    return (
        <div className='onboarding'>
            {elPosition &&
                <div className='step' style={elPosition}>
                    <Bubble />
                </div>}
        </div>
    )
}