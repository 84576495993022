import { Form, Formik, FormikHelpers } from "formik"
import * as Yup from 'yup'
import { MdFormikField } from "../ui/MdFormikField"
import { FetchApiError } from "@russh/fetch-api"
import { ChevronRightIcon, MailIcon } from "../ui/icons"

// --------------------------------------------------------------------------------

interface RestorePasswordProps {
    next: () => void
    prev: () => void
    setEmail: React.Dispatch<React.SetStateAction<string>>
}

export const RestorePassword: React.FC<RestorePasswordProps> = ({ next, prev, setEmail }) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Неверный формат электронной почты, например example@mail.ru')
            .required('Введите email'),
    })

    const handleSubmit = async (
        { email }: { email: string },
        { setErrors }: FormikHelpers<{ email: string }>
    ) => {
        try {
            setEmail(email)
            prev()
        } catch (e) {
            const err = e as FetchApiError
            if (err.fields) setErrors(err.fields)
        }
    }

    return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <Form className="sign-up verification">
                <md-text-button
                    type="button"
                    class="right"
                    onClick={next}
                    trailing-icon={true}
                >
                    Назад
                    <ChevronRightIcon slotted />
                </md-text-button>
                <section>
                    <label>
                        Восстановление пароля
                    </label>
                    <MdFormikField
                        name="email"
                        label="Электронная почта"
                        icon={<MailIcon />}
                    />
                </section>
                <div className="btns">
                    <md-filled-button
                        type="submit"
                    >
                        Восстановить пароль
                    </md-filled-button>
                </div>
            </Form>
        </Formik>
    )
}