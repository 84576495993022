import { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store"
import { BASE_URL } from "../../utils/api"
import { GalleryImage } from "../../@types/gallery"
import { setElPosition } from "../../store/reducers/onboardingSlice"
import { AddCircleIcon, CheckCircleFillIcon, DeleteForeverIcon } from "./icons"
import { createPortal } from "react-dom"
import { showSnackbar } from "../../store/reducers/snackbarSlice"
import { removeFromGallery } from "../../store/reducers/gallerySlice"
import { MdFilledIconButton } from "@material/web/iconbutton/filled-icon-button"
import { removeImageByUrl } from "../../store/reducers/albumSlice"

// --------------------------------------------------------------------------------

export interface ImageTileDndEvents {
    readonly onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void
    readonly onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => void
    readonly onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => void
}

export interface ImageTileEditorReplaceEvent {
    readonly onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

type LazyLoading = { loading: "lazy" | "eager" } | undefined

interface ImageTileProps {
    el: GalleryImage
    events: ImageTileDndEvents | ImageTileEditorReplaceEvent | object
    lazy?: boolean
    index?: number
    hideUsed?: boolean
    selectMode?: boolean
    deleteList?: string[]
    setDeleteList?: React.Dispatch<React.SetStateAction<string[]>>
}

export const ImageTile: React.FC<ImageTileProps> = ({ el, events, lazy, index, hideUsed, selectMode, deleteList, setDeleteList }) => {
    const { imagesUsed } = useAppSelector(state => state.album)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const dispatch = useAppDispatch()

    const selectRef = useRef<HTMLDivElement>(null)
    const badgeRef = useRef<SVGSVGElement>(null)

    const [hoveredImageSrc, setHoveredImageSrc] = useState<{ src: string, position: DOMRect }>()

    const isMobile = 'ontouchstart' in window

    const lazyLoading: LazyLoading = lazy ? { loading: 'lazy' } : undefined
    const imageUsed = imagesUsed[el.url]
    const opacity = imageUsed && isMobile ? { style: { opacity: '0.5' } } : undefined
    const orientation = el.width > el.height ? 'landscape' : 'portrait'
    const isSelected = deleteList?.includes(el.url)
    const isDataUrl = el.url.startsWith('data:')


    const handleSelectClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!deleteList || !setDeleteList) return

        if (isSelected) {
            setDeleteList(deleteList.filter(img => img !== el.url))
        } else {
            setDeleteList([...deleteList, el.url])
        }
    }

    // Set onboarding el position for gallery step 2
    useEffect(() => {
        if (badgeRef.current && index && onboardingMode === 'gallery' && step === 3) {
            const { height, left, top, width } = badgeRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                left: `${left - 2}px`,
                top: `${top - 2}px`,
                width: `${width + 4}px`,
                height: `${height + 4}px`,
            }))
        }
    }, [onboardingMode, step])

    const onMouseEnter: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        if (isMobile) return

        const img = e.currentTarget.children[0] as HTMLImageElement
        setHoveredImageSrc({
            src: img.src,
            position: img.getBoundingClientRect()
        })

    }, [])

    const onMouseLeave = useCallback(() => setHoveredImageSrc(undefined), [])

    const handleRemove: MouseEventHandler<MdFilledIconButton> = useCallback((e) => {
        showSnackbar({
            text: 'Удалить фото?',
            actions: {
                submit: 'ДА',
                cancel: 'НЕТ',
                submitAction: () => {
                    dispatch(removeFromGallery([el.url]))
                    dispatch(removeImageByUrl([el.url]))

                    setTimeout(() => {
                        showSnackbar({
                            text: 'Фото удалены.',
                            position: 'top',
                            leadingIcon:
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#22543D" />
                                    <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" fill="#68D391" />
                                </svg>
                        })
                    }, 500)
                }
            }
        })
    }, [])

    if (imageUsed && hideUsed) return null

    const tileEvents = !selectMode ? events : undefined

    return (
        isDataUrl
            ?
            <div
                className="draggable image-tile loading"
                key={`gallery-img-${el.url.slice(-10)}`}
            >
                <img src={el.url} {...lazyLoading} {...opacity} />
                <div className="image-overlay" />
                <md-circular-progress indeterminate />
            </div>
            :
            <div
                data-img-src={el.url}
                data-img-width={el.width}
                data-img-height={el.height}
                className="draggable image-tile"
                key={`gallery-img-${el.id}`}
                {...tileEvents}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <img src={`${BASE_URL}/upload/3/256/${el.url}`} {...lazyLoading} {...opacity} />
                {isMobile
                    ? <div className="image-overlay" />
                    : <div className={`image-overlay-with-buttons ${imageUsed ? 'image-is-used' : ''}`}>
                        {imageUsed ? <CheckCircleFillIcon /> : <AddCircleIcon />}
                        <md-filled-icon-button onClick={handleRemove}>
                            <DeleteForeverIcon />
                        </md-filled-icon-button>
                    </div>}

                {isMobile && imageUsed && imageUsed > 1 &&
                    <div className="badge label-small">{imageUsed}</div>}

                {isMobile && !selectMode && orientation === 'landscape' &&
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" ref={badgeRef}>
                        <path d="M1.75 2.75C1.75 2.19772 2.19772 1.75 2.75 1.75H13.25C13.8023 1.75 14.25 2.19772 14.25 2.75V13.25C14.25 13.8023 13.8023 14.25 13.25 14.25H2.75C2.19772 14.25 1.75 13.8023 1.75 13.25V2.75Z" fill="black" />
                        <path d="M11.75 5.75C12.3023 5.75 12.75 6.19772 12.75 6.75V9.25C12.75 9.80228 12.3023 10.25 11.75 10.25H4.25C3.69772 10.25 3.25 9.80228 3.25 9.25L3.25 6.75C3.25 6.19771 3.69772 5.75 4.25 5.75L11.75 5.75Z" fill="white" />
                    </svg>}

                {isMobile && !selectMode && orientation === 'portrait' &&
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" ref={badgeRef}>
                        <path d="M1.75 2.75C1.75 2.19772 2.19772 1.75 2.75 1.75H13.25C13.8023 1.75 14.25 2.19772 14.25 2.75V13.25C14.25 13.8023 13.8023 14.25 13.25 14.25H2.75C2.19772 14.25 1.75 13.8023 1.75 13.25V2.75Z" fill="black" />
                        <path d="M5.75 4.25C5.75 3.69772 6.19772 3.25 6.75 3.25H9.25C9.80228 3.25 10.25 3.69772 10.25 4.25V11.75C10.25 12.3023 9.80228 12.75 9.25 12.75H6.75C6.19772 12.75 5.75 12.3023 5.75 11.75V4.25Z" fill="white" />
                    </svg>}

                {selectMode && !imageUsed &&
                    <div ref={selectRef} className="select" onClick={handleSelectClick}>
                        {isSelected
                            ?
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
                                <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" />
                            </svg>
                            :
                            <div />}
                    </div>}

                {hoveredImageSrc && createPortal(
                    <img
                        className="hovered-image-preview"
                        src={hoveredImageSrc.src}
                        style={{
                            top: hoveredImageSrc.position.top - 192,
                            left: hoveredImageSrc.position.left
                        }}
                    />,
                    document.body
                )}
            </div>
    )
}