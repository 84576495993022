import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    dragActive?: boolean
    dragComponent?: 'img' | 'bgr' | 'tmpl' | 'sheet'
    dropPosition: number[]
    imgSrc?: string
    imgWidth?: string
    imgHeight?: string
    backgroundGroupId?: string
    backgroundId?: string
    templateId?: string
    templateTargetId?: string
    draggedSheetId?: string
} = {
    dragActive: false,
    dropPosition: []
}

const dragAndDropSlice = createSlice({
    name: 'dnd',
    initialState,
    reducers: {

        startDrag: (state, action: PayloadAction<typeof initialState['dragComponent'] | { type: typeof initialState['dragComponent'], id: string }>) => {
            state.dragActive = true
            if (action.payload && typeof action.payload !== 'string') {
                state.dragComponent = action.payload.type
                state.draggedSheetId = action.payload.id
            } else {
                state.dragComponent = action.payload
            }
        },

        stopDrag: (state) => {
            state.dragActive = false
            state.dragComponent = undefined
            state.draggedSheetId = undefined
        },

        setDropPosition: (state, action: PayloadAction<typeof initialState>) => {
            state.dropPosition = action.payload.dropPosition
            state.imgSrc = action.payload.imgSrc
            state.imgWidth = action.payload.imgWidth
            state.imgHeight = action.payload.imgHeight
            state.templateId = action.payload.templateId
            state.templateTargetId = action.payload.templateTargetId
            state.backgroundId = action.payload.backgroundId
            state.backgroundGroupId = action.payload.backgroundGroupId
            state.dragActive = false
            state.dragComponent = undefined
            state.draggedSheetId = undefined
        },

        resetDropPosition: (state) => {
            state.dropPosition = []
        },

    }
})

export const {
    startDrag,
    stopDrag,
    setDropPosition,
    resetDropPosition
} = dragAndDropSlice.actions

export default dragAndDropSlice.reducer