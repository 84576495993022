// main style
import './@styles/index.less'
import './components/web-components'

// ----------------------------------------------------------------------
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './store'
import { Workbox } from 'workbox-window'
import { useMobile } from './hooks/useMobile'
import { useNavigate, useParams } from 'react-router-dom'
import { hideBottomSheet, setBottomSheetData } from './store/reducers/bottomSheetSlice'
import { hideImageEditor } from './store/reducers/imageEditorSlice'
import { register as registerSwiper } from 'swiper/element/bundle'
import { getAuthUser } from './store/reducers/authSlice'
import Fingerprint from '@fingerprintjs/fingerprintjs'
import api from './utils/api'
import './utils'

// components
import { MobileOnly } from './components/ui/MobileOnly'
import { BottomSheet } from './components/BottomSheet'
import { Scrim } from './components/ui/Scrim'
import { Main } from './components/Main'
import { Header } from './components/Header'
import { DropArea } from './components/DropArea'
import { ImageEditor } from './components/ImageEditor'
import { TextEditor } from './components/TextEditor'
import { Toolbar } from './components/Toolbar'
import { Dialog } from './components/ui/MdDialog'
import { setFolders } from './store/reducers/gallerySlice'
import { folders } from '../data'
import { Snackbar } from './components/ui/Snackbar'
import { Onboarding } from './components/Onboarding'
import { enableOnboardingMode } from './store/reducers/onboardingSlice'
import { setIsEditable } from './store/reducers/albumSlice'
import { NotPortrait } from './components/ui/NotPortrait'


// ----------------------------------------------------------------------

registerSwiper()

// if ('serviceWorker' in navigator) {
//     const wb = new Workbox('/sw.js')
//     wb.register()
// }

export const App: React.FC = () => {
    const dispatch = useAppDispatch()
    const { completed } = useAppSelector(state => state.onboarding)
    const { album, isEditable } = useAppSelector(state => state.album)
    const { language } = useAppSelector(state => state.settings)
    const { projectId, slug } = useParams()
    const nav = useNavigate()

    const [isPortrait, setIsPortrait] = useState(screen.orientation.type === 'portrait-primary')

    // console.log(projectId)

    useEffect(() => {
        if (slug) nav("", { replace: true })

        // setTimeout(() => dispatch(setBottomSheetData({ upload: true })), 1000)

        setTimeout(() =>
            !completed.main && dispatch(enableOnboardingMode('main')), 2000)

        dispatch(setFolders(folders))

        Fingerprint.load()
            .then(fp => fp.get())
            .then(result => api.setAuthHeader(result.visitorId))
            .then(() => {
                if (localStorage.getItem('accessToken')) dispatch(getAuthUser())
            })

        const resizeHandler = () => {
            if (window.visualViewport) {
                const { height, width } = window.visualViewport
                document.documentElement.style.setProperty('--visual-viewport-height', `${height}px`)
                document.documentElement.style.setProperty('--visual-viewport-width', `${width}px`)
            }
        }
        resizeHandler()
        window.visualViewport?.addEventListener('resize', resizeHandler)

        const handleOrientationChange = (e: Event) => {
            const screenOrientation = e.target as ScreenOrientation
            if (screenOrientation.type === 'portrait-primary')
                setIsPortrait(true)
            else
                setIsPortrait(false)
        }

        screen.orientation.addEventListener('change', handleOrientationChange)

        return () => {
            window.visualViewport?.removeEventListener('resize', resizeHandler)
            screen.orientation.removeEventListener('change', handleOrientationChange)
        }
    }, [])

    useEffect(() => {
        if (slug?.includes('edit'))
            dispatch(hideBottomSheet())
        if (!slug) {
            dispatch(hideBottomSheet())
            dispatch(hideImageEditor())
        }
    }, [slug])

    useEffect(() => {
        if (album && album.params.enabled === '1') {
            dispatch(setIsEditable(true))
        } else {
            dispatch(setIsEditable(false))
        }
    }, [album])

    if (!isPortrait && ('ontouchstart' in window)) return <NotPortrait />
    if (!album) return null

    return (
        isEditable
            ?
            <>
                <Main />
                <Header />
                <Toolbar />

                <DropArea />

                <Scrim />
                <ImageEditor />
                <BottomSheet />
                <TextEditor />

                <Dialog />
                <Snackbar />
                <Onboarding />
            </>
            :
            <>
                <Main />
            </>
    )
}