import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
    TypedUseSelectorHook,
    useDispatch,
    useSelector,
} from 'react-redux'

// RTK apis
import { api } from './api/api'
import bottomSheetReducer from './reducers/bottomSheetSlice'
import dragAndDropReducer from './reducers/dragAndDropSlice'
import albumReducer from './reducers/albumSlice'
import authReducer from './reducers/authSlice'
import imageEditorReducer from './reducers/imageEditorSlice'
import textEditorReducer from './reducers/textEditorSlice'
import dialogReducer from './reducers/dialogSlice'
import galleryReducer from './reducers/gallerySlice'
import snackbarReducer from './reducers/snackbarSlice'
import settingsReducer from './reducers/settingsSlice'
import toolbarReducer from './reducers/toolbarSlice'
import onboardingReducer from './reducers/onboardingSlice'

// --------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        auth: authReducer,
        bottomSheet: bottomSheetReducer,
        imageEditor: imageEditorReducer,
        textEditor: textEditorReducer,
        album: albumReducer,
        dnd: dragAndDropReducer,
        dialog: dialogReducer,
        gallery: galleryReducer,
        snackbar: snackbarReducer,
        settings: settingsReducer,
        toolbar: toolbarReducer,
        onboarding: onboardingReducer,

        [api.reducerPath]: api.reducer,
    },
    middleware: (gDM) => gDM({ serializableCheck: false }).concat([
        api.middleware,
    ])
})

setupListeners(store.dispatch)

export type RootStore = typeof store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector