import { MdOutlinedTextField } from "@material/web/textfield/outlined-text-field"
import { FC, useEffect, useRef, useState } from "react"
import { useField } from "formik"
import { WebComponent } from "../../@types/global"
import { ErrorIcon } from "./icons"

// --------------------------------------------------------------------------------

type MdFormikFieldProps = WebComponent<MdOutlinedTextField> & {
    name: string
    icon?: JSX.Element
}

export const MdFormikField: React.FC<MdFormikFieldProps> = ({ name, icon, ...props }) => {
    const [{ value }, { error }, { setValue }] = useField(name)
    const fieldRef = useRef<MdOutlinedTextField>(null)
    const field = fieldRef.current

    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (!field) return

        if (error) {
            field.error = true
            field.errorText = error
            setIsError(true)
        } else {
            field.error = false
            setIsError(false)
        }
    }, [error, field])

    const handleInputChange = () => {
        field &&
            setValue(field.value, field.errorText ? true : false)
    }

    return (
        <md-outlined-text-field ref={fieldRef} {...props} onKeyUp={handleInputChange}>
            {icon}
            {isError &&
                <ErrorIcon />}
        </md-outlined-text-field>
    )
}