import { useEffect, useRef, useState } from "react"
import { SwiperContainer } from "swiper/element"
import { SignUp } from "./auth/SignUp"
import { SignIn } from "./auth/SignIn"
import { CodeVerification } from "./auth/CodeVerification"
import { useAppSelector } from "../store"
import { RestorePassword } from "./auth/RestorePassword"
import { PasswordInfo } from "./auth/PasswordInfo"

// --------------------------------------------------------------------------------

export const Auth: React.FC = () => {
    const swiperRef = useRef<SwiperContainer>(null)
    const swiper = swiperRef.current?.swiper
    const { isVisible } = useAppSelector(state => state.bottomSheet)
    const [email, setEmail] = useState('')

    const nextSlide = () => {
        if (swiper) {
            swiper.enable()
            swiper.slideNext()
            swiper.disable()
        }
    }

    const prevSlide = () => {
        if (swiper) {
            swiper.enable()
            swiper.slidePrev()
            swiper.disable()
        }
    }

    const props = {
        next: nextSlide,
        prev: prevSlide,
    }

    useEffect(() => {
        if (isVisible && swiper) {
            swiper.slideTo(2, 0)
            swiper.disable()
        } else {
            swiper?.enable()
        }
    }, [isVisible, swiper])

    return (
        <div className="bottom-sheet-auth">
            <swiper-container
                ref={swiperRef}
                allow-touch-move={false}
                initial-slide={2}
            >
                <swiper-slide>
                    <PasswordInfo email={email} />
                </swiper-slide>
                <swiper-slide>
                    <RestorePassword {...props} setEmail={setEmail} />
                </swiper-slide>
                <swiper-slide>
                    <SignIn {...props} />
                </swiper-slide>
                <swiper-slide>
                    <SignUp {...props} />
                </swiper-slide>
                <swiper-slide>
                    <CodeVerification {...props} />
                </swiper-slide>
            </swiper-container>
        </div>
    )
}