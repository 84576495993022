import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// @types
import { ToolbarButtonProps } from '../../components/Toolbar'

// --------------------------------------------------------------------------------

const initialState: {
    active?: ToolbarButtonProps['type']
} = {
}

const toolbarSlice = createSlice({
    name: 'toolbar',
    initialState,
    reducers: {

        setActive: (state, action: PayloadAction<typeof initialState['active']>) => {
            state.active = action.payload
        },

        closeToolbar: (state) => {
            state.active = undefined
        }
    }
})

export const { setActive, closeToolbar } = toolbarSlice.actions

export default toolbarSlice.reducer