import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api, ApiRoutes } from "../../utils/api"
import { FetchApiError } from "@russh/fetch-api"
import { LoginDto, User, UserWithToken } from "../../@types/auth"

// --------------------------------------------------------------------------------

export const signUp = createAsyncThunk('auth/signup', async (payloads: { code: number }, thunkAPI) => {
    try {
        return await api.post(ApiRoutes.SIGNUP_VERIFY, payloads) as Promise<UserWithToken>
    } catch (e) {
        const error = e as FetchApiError
        return thunkAPI.rejectWithValue(error)
    }
})

export const logIn = createAsyncThunk('auth/login', async (payloads: LoginDto, thunkAPI) => {
    try {
        return await api.post(ApiRoutes.LOGIN, payloads) as Promise<UserWithToken>
    } catch (e) {
        const error = e as FetchApiError
        return thunkAPI.rejectWithValue(error)
    }
})

export const logOut = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    try {
        return await api.patch(ApiRoutes.LOGOUT) as Promise<{ message: string }>
    } catch (e) {
        const error = e as FetchApiError
        return thunkAPI.rejectWithValue(error)
    }
})

export const getAuthUser = createAsyncThunk('auth/user', async (_, thunkAPI) => {
    try {
        return await api.get(ApiRoutes.GET_AUTH_USER) as Promise<User>
    } catch (e) {
        const error = e as FetchApiError
        return thunkAPI.rejectWithValue(error)
    }
})

// export const updateUser = createAsyncThunk('user/updateUser', async (payloads: UpdateUserDTO, thunkAPI) => {
//     const { id, ...data } = payloads
//     const filteredData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== ''))
//     try {
//         return await api.patch(`${ApiRoutes.USERS}/${id}`, filteredData) as Promise<User>
//     } catch (e) {
//         const error = e as FetchApiError
//         return thunkAPI.rejectWithValue(error)
//     }
// })

// --------------------------------------------------------------------------------

const initialState: {
    user?: User,
    isAuthenticated: boolean,
    isLoading: boolean,
    language: string
} = {
    user: undefined,
    isAuthenticated: false,
    isLoading: false,
    language: window.navigator.language.substring(0, 2).toUpperCase()
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.user = initialState.user
            state.isAuthenticated = false
            state.isLoading = false
            localStorage.removeItem('accessToken')
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload
        },
    },
    extraReducers: builder => {
        builder

            // REGISTER ----------------------------------------------------------
            .addCase(signUp.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.user = action.payload.user
                state.isAuthenticated = true
                state.isLoading = false
                localStorage.setItem('accessToken', action.payload.accessToken)
            })
            .addCase(signUp.rejected, (state) => {
                state.user = initialState.user
                state.isAuthenticated = false
                state.isLoading = false
            })

            // LOGIN -------------------------------------------------------------
            .addCase(logIn.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(logIn.fulfilled, (state, action) => {
                state.user = action.payload.user
                state.isAuthenticated = true
                state.isLoading = false
                localStorage.setItem('accessToken', action.payload.accessToken)
            })
            .addCase(logIn.rejected, (state) => {
                state.user = initialState.user
                state.isAuthenticated = false
                state.isLoading = false
            })

            // LOGOUT ------------------------------------------------------------
            .addCase(logOut.fulfilled, (state) => {
                state.user = initialState.user
                state.isAuthenticated = false
                localStorage.removeItem('accessToken')
            })

            // GET AUTH USER -----------------------------------------------------
            .addCase(getAuthUser.fulfilled, (state, action) => {
                state.user = action.payload
                state.isAuthenticated = true
            })
            .addCase(getAuthUser.rejected, (state) => {
                state.user = initialState.user
                state.isAuthenticated = false
            })

        // UPDATE USER -------------------------------------------------------
        // .addCase(updateUser.fulfilled, (state, action) => {
        //     state.isAuthenticated = true
        //     state.data = action?.payload
        // })
        // .addCase(updateUser.rejected, (state) => {
        //     state.isAuthenticated = false
        //     state.data = initialState.data
        // })
    },
})

export const { setLanguage, resetUser } = authSlice.actions

export default authSlice.reducer