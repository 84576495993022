import { GalleryFolder, GalleryImage } from "./src/@types/gallery"
import { Templates } from "./src/@types/templates"

// --------------------------------------------------------------------------------

export const gallery: GalleryImage[] = [
    { id: 1, url: '1.jpg', width: 2048, height: 1536, },
    { id: 2, url: '2.jpg', width: 2048, height: 1536, },
    { id: 3, url: '3.jpg', width: 2048, height: 1536, },
    { id: 4, url: '4.jpg', width: 2048, height: 1536, },
    { id: 5, url: '5.jpg', width: 2048, height: 1536, },
    { id: 6, url: '6.jpg', width: 2048, height: 1536, },
    { id: 7, url: '7.jpg', width: 2048, height: 1536, },
    { id: 8, url: '8.jpg', width: 2048, height: 1536, },
    { id: 9, url: '9.jpg', width: 2048, height: 1536, },
    { id: 10, url: '10.jpg', width: 2048, height: 1536, },
    { id: 11, url: '11.jpg', width: 2048, height: 1536, },
    { id: 12, url: '12.jpg', width: 2048, height: 1536, },
    { id: 13, url: '13.jpg', width: 2048, height: 1536, },
    { id: 14, url: '14.jpg', width: 2048, height: 1536, },
    { id: 15, url: '15.jpg', width: 2048, height: 1536, },
    { id: 16, url: '16.jpg', width: 2048, height: 1536, },
    { id: 17, url: '17.jpg', width: 2048, height: 1536, },
    { id: 18, url: '18.jpg', width: 2048, height: 1536, },
    { id: 19, url: '19.jpg', width: 2048, height: 1536, },
    { id: 20, url: '20.jpg', width: 2048, height: 1536, },
    { id: 21, url: '21.jpg', width: 1536, height: 2048, },
    { id: 22, url: '22.jpg', width: 1536, height: 2048, },
    { id: 23, url: '23.png', width: 1536, height: 2048, },
    { id: 24, url: '24.jpg', width: 1536, height: 2048, },
    { id: 25, url: '25.jpg', width: 1536, height: 2048, },
    { id: 26, url: '26.png', width: 1536, height: 2048, },
    { id: 27, url: '27.jpg', width: 1536, height: 2048, },
    { id: 28, url: '28.jpg', width: 1536, height: 2048, },
    { id: 29, url: '29.jpg', width: 1536, height: 2048, },
    { id: 30, url: '30.png', width: 1536, height: 2048, },
]

export const folders: GalleryFolder[] = [
    {
        id: 1,
        name: 'Коты ландшафт',
        images: gallery.slice(0, 19)
    },
    {
        id: 2,
        name: 'Коты портрет',
        images: gallery.slice(20)
    }
]

export const templates: Templates = {
    "6370": {
        "params": {
            "id": "6370",
            "system_id": null,
            "name": "Название + фото",
            "target_id": "2",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "9",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "37628340460c36dc01e3d89.89398854.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29259",
                "system_id": null,
                "template_id": "6370",
                "component_id": "1",
                "x": "263.00",
                "y": "65.00",
                "width": "120.00",
                "height": "20.00",
                "editable": "1",
                "level": "1",
                "tag": "name1",
                "style": "{\"font_size\":\"35\",\"font_color_id\":\"140\",\"font_id\":\"9\",\"angle\":0,\"font_style\":\"normal\",\"align\":\"center\",\"font_limit_status\":false,\"font_limit_min_size\":\"\",\"font_limit_max_size\":\"\",\"font_limit_font_family\":null}",
                "value": "Отпуск 2021",
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29342",
                "system_id": null,
                "template_id": "6370",
                "component_id": "3",
                "x": "173.00",
                "y": "50.00",
                "width": "225.00",
                "height": "150.00",
                "editable": "1",
                "level": "2",
                "tag": "name1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "292631",
                "system_id": null,
                "template_id": "63721",
                "component_id": "3",
                "x": "156.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "3",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
        ],
        "coordinateComponents": [
            {
                "id": "29342",
                "system_id": null,
                "template_id": "6370",
                "component_id": "3",
                "x": "248.00",
                "y": "100.00",
                "width": "150.00",
                "height": "100.00",
                "editable": "1",
                "level": "2",
                "tag": "name1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29259",
                "system_id": null,
                "template_id": "6370",
                "component_id": "1",
                "x": "263.00",
                "y": "65.00",
                "width": "120.00",
                "height": "20.00",
                "editable": "1",
                "level": "1",
                "tag": "name1",
                "style": "{\"font_size\":\"35\",\"font_color_id\":\"140\",\"font_id\":\"9\",\"angle\":0,\"font_style\":\"normal\",\"align\":\"center\",\"font_limit_status\":false,\"font_limit_min_size\":\"\",\"font_limit_max_size\":\"\",\"font_limit_font_family\":null}",
                "value": "Отпуск 2021",
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "292631",
                "system_id": null,
                "template_id": "63721",
                "component_id": "3",
                "x": "146.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "3",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
        ]
    },
    "6372": {
        "params": {
            "id": "6372",
            "system_id": null,
            "name": "6 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "153333087160c36e91c55ef5.42134557.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29261",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "22.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29262",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "22.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29263",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "146.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "3",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29264",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "146.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "4",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29265",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "269.37",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29266",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "269.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "6",
                "tag": "foto6",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29261",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "22.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29262",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "22.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29263",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "146.00",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "3",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29264",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "146.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "4",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29266",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "269.00",
                "y": "106.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "6",
                "tag": "foto6",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29265",
                "system_id": null,
                "template_id": "6372",
                "component_id": "3",
                "x": "269.37",
                "y": "23.00",
                "width": "117.00",
                "height": "78.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6373": {
        "params": {
            "id": "6373",
            "system_id": null,
            "name": "5 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "154518925760c36f035f20a6.25692616.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29267",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "37.00",
                "y": "31.63",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29268",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "37.00",
                "y": "104.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29269",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "109.00",
                "y": "32.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29270",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "109.00",
                "y": "104.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29271",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "223.00",
                "y": "53.00",
                "width": "154.00",
                "height": "99.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29267",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "37.00",
                "y": "31.63",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29268",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "37.00",
                "y": "104.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29269",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "109.00",
                "y": "32.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29270",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "109.00",
                "y": "104.00",
                "width": "68.00",
                "height": "68.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29271",
                "system_id": null,
                "template_id": "6373",
                "component_id": "3",
                "x": "223.00",
                "y": "53.00",
                "width": "154.00",
                "height": "99.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6374": {
        "params": {
            "id": "6374",
            "system_id": null,
            "name": "3 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "158985613560c36f4875de49.50385870.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29272",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "8.00",
                "y": "8.00",
                "width": "267.00",
                "height": "190.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29273",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "278.00",
                "y": "8.00",
                "width": "120.00",
                "height": "93.00",
                "editable": "1",
                "level": "2",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29274",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "278.00",
                "y": "104.50",
                "width": "120.00",
                "height": "93.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29272",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "8.00",
                "y": "8.00",
                "width": "267.00",
                "height": "190.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29273",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "278.00",
                "y": "8.00",
                "width": "120.00",
                "height": "93.00",
                "editable": "1",
                "level": "2",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29274",
                "system_id": null,
                "template_id": "6374",
                "component_id": "3",
                "x": "278.00",
                "y": "104.50",
                "width": "120.00",
                "height": "93.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6375": {
        "params": {
            "id": "6375",
            "system_id": null,
            "name": "6 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "145751012860c36fad11b1b7.47732508.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29275",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "21.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29276",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "78.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29277",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "135.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29278",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "215.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "4",
                "tag": "604768fd5d6d4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29279",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "272.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29280",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "329.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "6",
                "tag": "foto6",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29275",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "21.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29276",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "78.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29277",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "135.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29278",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "215.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "4",
                "tag": "604768fd5d6d4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29279",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "272.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "5",
                "tag": "foto5",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29280",
                "system_id": null,
                "template_id": "6375",
                "component_id": "3",
                "x": "329.00",
                "y": "44.00",
                "width": "54.00",
                "height": "120.00",
                "editable": "1",
                "level": "6",
                "tag": "foto6",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6376": {
        "params": {
            "id": "6376",
            "system_id": null,
            "name": "2 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "29526855460c36fe5082994.16511282.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29281",
                "system_id": null,
                "template_id": "6376",
                "component_id": "3",
                "x": "49.00",
                "y": "28.00",
                "width": "114.00",
                "height": "150.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29282",
                "system_id": null,
                "template_id": "6376",
                "component_id": "3",
                "x": "218.00",
                "y": "0.00",
                "width": "165.00",
                "height": "206.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29281",
                "system_id": null,
                "template_id": "6376",
                "component_id": "3",
                "x": "49.00",
                "y": "28.00",
                "width": "114.00",
                "height": "150.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29282",
                "system_id": null,
                "template_id": "6376",
                "component_id": "3",
                "x": "218.00",
                "y": "0.00",
                "width": "165.00",
                "height": "206.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6377": {
        "params": {
            "id": "6377",
            "system_id": null,
            "name": "1 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "183783261960c37001e2a8a1.14609659.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29283",
                "system_id": null,
                "template_id": "6377",
                "component_id": "3",
                "x": "117.55",
                "y": "22.63",
                "width": "170.00",
                "height": "160.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29283",
                "system_id": null,
                "template_id": "6377",
                "component_id": "3",
                "x": "117.55",
                "y": "22.63",
                "width": "170.00",
                "height": "160.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6378": {
        "params": {
            "id": "6378",
            "system_id": null,
            "name": "4 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "142986036760c370736d91b1.97301187.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29285",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "14.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29286",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "74.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29287",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "134.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29288",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "221.00",
                "y": "0.00",
                "width": "163.00",
                "height": "206.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29285",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "14.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29286",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "74.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29287",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "78.00",
                "y": "134.00",
                "width": "57.00",
                "height": "57.00",
                "editable": "1",
                "level": "3",
                "tag": "foto3",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29288",
                "system_id": null,
                "template_id": "6378",
                "component_id": "3",
                "x": "221.00",
                "y": "0.00",
                "width": "163.00",
                "height": "206.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":0,\"offsetY\":0,\"zoom\":0,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6379": {
        "params": {
            "id": "6379",
            "system_id": null,
            "name": "4 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "19385099660c3709e37d475.00217019.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29289",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "18.00",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29290",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "77.50",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29291",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "137.00",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "3",
                "tag": "6048c1c498b92",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29292",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "221.37",
                "y": "29.00",
                "width": "159.00",
                "height": "151.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29289",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "18.00",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29290",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "77.50",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29291",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "137.00",
                "y": "38.00",
                "width": "55.00",
                "height": "130.00",
                "editable": "1",
                "level": "3",
                "tag": "6048c1c498b92",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29292",
                "system_id": null,
                "template_id": "6379",
                "component_id": "3",
                "x": "221.37",
                "y": "29.00",
                "width": "159.00",
                "height": "151.00",
                "editable": "1",
                "level": "4",
                "tag": "foto4",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    },
    "6380": {
        "params": {
            "id": "6380",
            "system_id": null,
            "name": "2 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "144495380660c370c67d0bb9.08389790.png",
            "display_device": null,
            "to_delete": "0"
        },
        "components": [
            {
                "id": "29293",
                "system_id": null,
                "template_id": "6380",
                "component_id": "3",
                "x": "17.00",
                "y": "17.00",
                "width": "256.00",
                "height": "169.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29294",
                "system_id": null,
                "template_id": "6380",
                "component_id": "3",
                "x": "281.00",
                "y": "16.63",
                "width": "108.00",
                "height": "169.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ],
        "coordinateComponents": [
            {
                "id": "29293",
                "system_id": null,
                "template_id": "6380",
                "component_id": "3",
                "x": "17.00",
                "y": "17.00",
                "width": "256.00",
                "height": "169.00",
                "editable": "1",
                "level": "1",
                "tag": "foto1",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            },
            {
                "id": "29294",
                "system_id": null,
                "template_id": "6380",
                "component_id": "3",
                "x": "281.00",
                "y": "16.63",
                "width": "108.00",
                "height": "169.00",
                "editable": "1",
                "level": "2",
                "tag": "foto2",
                "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                "value": null,
                "params": null,
                "view_in_pdf": "1",
                "type_value": "1",
                "view_type": null
            }
        ]
    }
}