export const FilterIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const ImagesAndTextIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} className="fill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 2C2.89543 2 2 2.89543 2 4V8C2 9.10457 
                2.89543 10 4 10H20C21.1046 10 22 9.10457 22 8V4C22 2.89543 21.1046 2 20 2H4ZM20 4H4V8H20V4Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 12C2.89543 12 2 12.8954 2 14V20C2 
                21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20V14C11 12.8954 10.1046 12 9 12H4ZM9 14H4V20H9V14Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14H21C21.5523 14 22 13.5523 22 13C22 12.4477 21.5523 12 21 12H14Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M13 17C13 16.4477 13.4477 16 14 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H14C13.4477 18 13 17.5523 13 17Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M14 20C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H14Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const ImagesIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 
                21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 15L16 10L5 21" stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
}


export const TextIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 7V4H20V7M9 20H15M12 4V20" stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
}


export const BookSpreadIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 7C12 5.93913 11.5786 4.92172 10.8284 4.17157C10.0783 3.42143 9.06087 3 8 
    3H2V18H9C9.79565 18 10.5587 18.3161 11.1213 18.8787C11.6839 19.4413 12 20.2044 12 21M12 
    7V21M12 7C12 5.93913 12.4214 4.92172 13.1716 4.17157C13.9217 3.42143 14.9391 3 16 
    3H22V18H15C14.2044 18 13.4413 18.3161 12.8787 18.8787C12.3161 19.4413 12 20.2044 12 21"
                stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
}


export const SheetIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 
    4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 
    22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9M13 2L20 9M13 2V9H20"
                stroke="var(--md-sys-color-on-surface-variant)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
}


export const NoTemplatesFound: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.6663 8.3335C12.064 8.3335 8.33301 12.0645 8.33301 16.6668V33.3335C8.33301 37.9359 12.064 41.6668 16.6663 41.6668H83.333C87.9354 41.6668 91.6663 37.9359 91.6663 33.3335V16.6668C91.6663 12.0645 87.9354 8.3335 83.333 8.3335H16.6663ZM83.333 16.6668H16.6663V33.3335H83.333V16.6668Z" fill="#CBD5E0" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.6663 50.0002C12.064 50.0002 8.33301 53.7311 8.33301 58.3335V83.3335C8.33301 87.9359 12.064 91.6668 16.6663 91.6668H37.4997C42.102 91.6668 45.833 87.9359 45.833 83.3335V58.3335C45.833 53.7311 42.102 50.0002 37.4997 50.0002H16.6663ZM37.4997 58.3335H16.6663V83.3335H37.4997V58.3335Z" fill="#CBD5E0" />
            <path d="M58.333 50.0002C56.0318 50.0002 54.1663 51.8656 54.1663 54.1668C54.1663 56.468 56.0318 58.3335 58.333 58.3335H87.4997C89.8009 58.3335 91.6663 56.468 91.6663 54.1668C91.6663 51.8656 89.8009 50.0002 87.4997 50.0002H58.333Z" fill="#CBD5E0" />
            <path d="M54.1663 70.8335C54.1663 68.5323 56.0318 66.6668 58.333 66.6668H87.4997C89.8009 66.6668 91.6663 68.5323 91.6663 70.8335C91.6663 73.1347 89.8009 75.0002 87.4997 75.0002H58.333C56.0318 75.0002 54.1663 73.1347 54.1663 70.8335Z" fill="#CBD5E0" />
            <path d="M58.333 83.3335C56.0318 83.3335 54.1663 85.199 54.1663 87.5002C54.1663 89.8013 56.0318 91.6668 58.333 91.6668H87.4997C89.8009 91.6668 91.6663 89.8013 91.6663 87.5002C91.6663 85.199 89.8009 83.3335 87.4997 83.3335H58.333Z" fill="#CBD5E0" />
        </svg>)
}


export const BadQlt: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#E53E3E" />
            <path d="M11 7.5C11 6.94772 11.4477 6.5 12 6.5C12.5523 6.5 13 6.94772 13 7.5V12.5C13 13.0523 12.5523 13.5 12 13.5C11.4477 13.5 11 13.0523 11 12.5V7.5Z" fill="white" />
            <path d="M13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 15.5 12 15.5C12.5523 15.5 13 15.9477 13 16.5Z" fill="white" />
        </svg>)
}


export const MidQlt: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5083 4.1747C14.0489 1.2751 9.95129 1.2751 8.49184 4.1747L2.43257 16.2155C1.10852 18.8415 2.97872 21.9997 5.94066 21.9997H18.0595C21.0229 21.9997 22.8893 18.8403 21.5677 16.2143L15.5083 4.1747Z" fill="#ED8936" />
            <path d="M12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z" fill="white" />
        </svg>)
}


export const GoodQlt: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#38A169" />
            <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" fill="white" />
        </svg>)
}


export const SettingsIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_889_31759)">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 
                    14.2091 8 12 8ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 
                    12 14C10.8954 14 10 13.1046 10 12Z"
                    fill="var(--md-sys-color-on-surface-variant)"
                />
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M12 0C11.2044 0 10.4413 0.316071 9.87868 0.87868C9.31607 1.44129 9 2.20435 9 3V3.1675C8.99921 3.29374 
                    .96167 3.41704 8.89195 3.52232C8.84498 3.59323 8.78479 3.6539 8.71507 3.70122C8.64693 3.71549 8.58027 3.73687 
                    8.51624 3.76514C8.39742 3.81758 8.26561 3.83322 8.13782 3.81005C8.01142 3.78713 7.89468 3.72728 7.80231 
                    3.6381L7.7475 3.58329C7.46888 3.30436 7.13802 3.08308 6.77382 2.93211C6.40963 2.78113 6.01925 2.70343 
                    5.625 2.70343C5.23075 2.70343 4.84037 2.78113 4.47618 2.93211C4.11216 3.08301 3.78143 3.30415 3.50289 
                    3.58289C3.22415 3.86143 3.00301 4.19216 2.85211 4.55618C2.70113 4.92037 2.62343 5.31075 2.62343 5.705C2.62343 
                    6.09925 2.70113 6.48963 2.85211 6.85382C3.00308 7.21802 3.22436 7.54888 3.50329 7.8275L3.5581 7.88231C3.64728 
                    7.97468 3.70713 8.09142 3.73005 8.21782C3.75322 8.34561 3.73758 8.47742 3.68514 8.59624C3.67663 8.61551 3.66874 
                    8.63505 3.66146 8.65482C3.61644 8.77723 3.53564 8.88327 3.42955 8.95914C3.32591 9.03327 3.20283 9.07526 3.07564 
                    9.08H3C2.20435 9.08 1.44129 9.39607 0.87868 9.95868C0.316071 10.5213 0 11.2844 0 12.08C0 12.8756 0.316071 13.6387 
                    0.87868 14.2013C1.44129 14.7639 2.20435 15.08 3 15.08H3.1675C3.29374 15.0808 3.41704 15.1183 3.52232 15.1881C3.62801 
                    15.258 3.71092 15.3574 3.76086 15.4739L3.76514 15.4838C3.81758 15.6026 3.83322 15.7344 3.81005 15.8622C3.78713 
                    15.9886 3.72728 16.1053 3.63811 16.1977L3.58329 16.2525C3.30436 16.5311 3.08308 16.862 2.93211 17.2262C2.78113 
                    17.5904 2.70343 17.9808 2.70343 18.375C2.70343 18.7692 2.78113 19.1596 2.93211 19.5238C3.08308 19.888 3.30436 
                    20.2189 3.58329 20.4975C3.86174 20.7761 4.19233 20.9971 4.55618 21.1479C4.92037 21.2989 5.31075 21.3766 5.705 
                    21.3766C6.09925 21.3766 6.48963 21.2989 6.85382 21.1479C7.21784 20.997 7.54857 20.7759 7.82712 20.4971L7.8823 
                    20.4419C7.97467 20.3527 8.09142 20.2929 8.21782 20.27C8.34561 20.2468 8.47742 20.2624 8.59624 20.3149C8.61551 
                    20.3234 8.63505 20.3313 8.65482 20.3385C8.77723 20.3836 8.88326 20.4644 8.95914 20.5704C9.03327 20.6741 9.07526 
                    20.7972 9.08 20.9244V21C9.08 21.7957 9.39607 22.5587 9.95868 23.1213C10.5213 23.6839 11.2843 24 12.08 24C12.8757 
                    24 13.6387 23.6839 14.2013 23.1213C14.7639 22.5587 15.08 21.7957 15.08 21V20.8325C15.0808 20.7063 15.1183 20.583 
                    15.1881 20.4777C15.258 20.372 15.3574 20.2891 15.4739 20.2391L15.4838 20.2349C15.6026 20.1824 15.7344 20.1668 
                    15.8622 20.19C15.9886 20.2129 16.1053 20.2727 16.1977 20.3619L16.2525 20.4167C16.531 20.6955 16.8622 20.917 
                    17.2262 21.0679C17.5904 21.2189 17.9808 21.2966 18.375 21.2966C18.7692 21.2966 19.1596 21.2189 19.5238 21.0679C19.8878 
                    20.917 20.2186 20.6959 20.4971 20.4171C20.7759 20.1386 20.997 19.8078 21.1479 19.4438C21.2989 19.0796 21.3766 18.6892 
                    21.3766 18.295C21.3766 17.9008 21.2989 17.5104 21.1479 17.1462C20.997 16.7822 20.7758 16.4514 20.4971 16.1729L20.4419 
                    16.1177C20.3527 16.0253 20.2929 15.9086 20.27 15.7822C20.2468 15.6544 20.2624 15.5226 20.3149 15.4038L20.3191 
                    15.3939C20.3691 15.2774 20.452 15.178 20.5577 15.1081C20.663 15.0383 20.7863 15.0008 20.9125 15H21C21.7957 15 
                    22.5587 14.6839 23.1213 14.1213C23.6839 13.5587 24 12.7957 24 12C24 11.2043 23.6839 10.4413 23.1213 9.87868C22.5587 
                    9.31607 21.7957 9 21 9H20.8325C20.7063 8.99921 20.583 8.96167 20.4777 8.89195C20.4068 8.84498 20.3461 8.78479 20.2988 
                    8.71507C20.2845 8.64692 20.2631 8.58027 20.2349 8.51624C20.1824 8.39742 20.1668 8.26561 20.19 8.13782C20.2129 
                    8.01142 20.2727 7.89468 20.3619 7.80231L20.4167 7.7475C20.6955 7.46896 20.917 7.13784 21.0679 6.77382C21.2189 
                    6.40963 21.2966 6.01925 21.2966 5.625C21.2966 5.23075 21.2189 4.84037 21.0679 4.47618C20.9171 4.11233 20.6961 
                    3.78174 20.4175 3.50329C20.1389 3.22436 19.808 3.00308 19.4438 2.85211C19.0796 2.70113 18.6892 2.62343 18.295 
                    2.62343C17.9008 2.62343 17.5104 2.70113 17.1462 2.85211C16.782 3.00308 16.4511 3.22436 16.1725 3.50329L16.1177 
                    3.55811C16.0253 3.64728 15.9086 3.70713 15.7822 3.73005C15.6544 3.75322 15.5226 3.73758 15.4038 3.68514L15.3939 
                    3.68086C15.2774 3.63092 15.178 3.54801 15.1081 3.44232C15.0383 3.33704 15.0008 3.21374 15 3.0875V3C15 2.20435 
                    14.6839 1.44129 14.1213 0.87868C13.5587 0.316071 12.7956 0 12 0ZM11.2929 2.29289C11.4804 2.10536 11.7348 2 12 
                    2C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3V3.09L13 3.09399C13.0021 3.6108 
                    13.1552 4.11572 13.4406 4.54661C13.7249 4.97601 14.1282 5.3132 14.6011 5.51701C15.0843 5.72908 15.6198 5.79211 
                    16.139 5.69796C16.66 5.60349 17.1407 5.35512 17.5193 4.98486L17.5271 4.9771L17.5871 4.9171C17.68 4.82413 17.7907 
                    4.74998 17.9121 4.69965C18.0335 4.64933 18.1636 4.62343 18.295 4.62343C18.4264 4.62343 18.5565 4.64933 18.6779 
                    4.69965C18.7993 4.74998 18.9096 4.82374 19.0025 4.91671L19.0033 4.9175C19.0963 5.01037 19.17 5.12066 19.2203 
                    5.24206C19.2707 5.36346 19.2966 5.49359 19.2966 5.625C19.2966 5.75641 19.2707 5.88654 19.2203 6.00794C19.17 
                    6.12934 19.0963 6.23963 19.0033 6.3325L18.9429 6.3929L18.9351 6.40073C18.5649 6.77925 18.3165 7.26 18.222 
                    7.781C18.14 8.23332 18.1773 8.69798 18.3284 9.12961C18.3403 9.22042 18.3646 9.30938 18.4009 9.39393C18.6045 
                    9.86896 18.9425 10.2741 19.3734 10.5594C19.8043 10.8448 20.3092 10.9979 20.826 11L20.83 11H21C21.2652 11 
                    21.5196 11.1054 21.7071 11.2929C21.8946 11.4804 22 11.7348 22 12C22 12.2652 21.8946 12.5196 21.7071 12.7071C21.5196 
                    12.8946 21.2652 13 21 13L20.91 13L20.906 13C20.3892 13.0021 19.8843 13.1552 19.4534 13.4406C19.024 13.725 18.6867 
                    14.1283 18.4829 14.6012C18.2709 15.0843 18.2079 15.6198 18.302 16.139C18.3965 16.66 18.6449 17.1407 19.0151 
                    17.5193L19.0229 17.5271L19.0829 17.5871C19.1759 17.68 19.25 17.7907 19.3003 17.9121C19.3507 18.0335 19.3766 
                    18.1636 19.3766 18.295C19.3766 18.4264 19.3507 18.5565 19.3003 18.6779C19.25 18.7993 19.1763 18.9096 19.0833 
                    19.0025L19.0825 19.0033C18.9896 19.0963 18.8793 19.17 18.7579 19.2203C18.6365 19.2707 18.5064 19.2966 18.375 
                    19.2966C18.2436 19.2966 18.1135 19.2707 17.9921 19.2203C17.8707 19.17 17.7604 19.0963 17.6675 19.0033L17.6071 
                    18.9429L17.5993 18.9351C17.2207 18.5649 16.74 18.3165 16.219 18.222C15.6998 18.1279 15.1643 18.1909 14.6812 
                    18.4029C14.2083 18.6067 13.805 18.944 13.5206 19.3734C13.2352 19.8043 13.0821 20.3092 13.08 20.826L13.08 
                    20.83V21C13.08 21.2652 12.9746 21.5196 12.7871 21.7071C12.5996 21.8946 12.3452 22 12.08 22C11.8148 22 
                    11.5604 21.8946 11.3729 21.7071C11.1854 21.5196 11.08 21.2652 11.08 21V20.91L11.0797 20.8866C11.0673 
                    20.355 10.8952 19.8395 10.5859 19.407C10.2827 18.983 9.86132 18.6581 9.37477 18.4725C8.89796 18.2689 
                    8.37163 18.2095 7.861 18.302C7.34 18.3965 6.85925 18.6449 6.48073 19.0151L6.47288 19.0229L6.41288 
                    19.0829C6.32001 19.1759 6.20934 19.25 6.08794 19.3003C5.96654 19.3507 5.83641 19.3766 5.705 19.3766C5.57359 
                    19.3766 5.44346 19.3507 5.32206 19.3003C5.20066 19.25 5.09037 19.1763 4.9975 19.0833L4.99671 19.0825C4.90374 
                    18.9896 4.82998 18.8793 4.77965 18.7579C4.72933 18.6365 4.70343 18.5064 4.70343 18.375C4.70343 18.2436 4.72933 
                    18.1135 4.77965 17.9921C4.82998 17.8707 4.90374 17.7604 4.99671 17.6675L5.0571 17.6071L5.06486 17.5993C5.43512 
                    17.2207 5.68349 16.74 5.77796 16.219C5.87211 15.6998 5.80908 15.1643 5.59701 14.6811C5.3932 14.2082 5.05601 
                    13.8049 4.62661 13.5206C4.19572 13.2352 3.6908 13.0821 3.17399 13.08L3.17 13.08H3C2.73478 13.08 2.48043 12.9746 
                    2.29289 12.7871C2.10536 12.5996 2 12.3452 2 12.08C2 11.8148 2.10536 11.5604 2.29289 11.3729C2.48043 11.1854 
                    2.73478 11.08 3 11.08H3.09L3.11338 11.0797C3.64498 11.0673 4.16054 10.8952 4.59305 10.5859C5.01698 10.2827 
                    5.34189 9.86133 5.52745 9.37478C5.73113 8.89797 5.79055 8.37163 5.69796 7.861C5.60349 7.34 5.35512 6.85925 
                    4.98486 6.48073L4.97711 6.47289L4.91711 6.41289C4.82413 6.32002 4.74998 6.20934 4.69965 6.08794C4.64933 
                    5.96654 4.62343 5.83642 4.62343 5.705C4.62343 5.57358 4.64933 5.44346 4.69965 5.32206C4.74998 5.20066 
                    4.82374 5.09037 4.91671 4.9975L4.9175 4.99671C5.01037 4.90374 5.12066 4.82998 5.24206 4.77965C5.36346 
                    4.72933 5.49358 4.70343 5.625 4.70343C5.75642 4.70343 5.88654 4.72933 6.00794 4.77965C6.12934 4.82998 
                    6.23963 4.90374 6.3325 4.99671L6.39289 5.05711L6.40073 5.06486C6.77925 5.43512 7.26 5.68349 7.781 
                    5.77796C8.23333 5.85998 8.69798 5.82272 9.12961 5.67157C9.22042 5.65969 9.30938 5.63538 9.39393 5.59914C9.86895 
                    5.39555 10.2741 5.0575 10.5594 4.62661C10.8448 4.19572 10.9979 3.6908 11 3.17399L11 3.17V3C11 2.73478 11.1054 2.48043 11.2929 2.29289Z"
                    fill="var(--md-sys-color-on-surface-variant)"
                />
            </g>
            <defs>
                <clipPath id="clip0_889_31759">
                    <rect width="24" height="24" fill="none" />
                </clipPath>
            </defs>
        </svg>)
}


export const ShareIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M15.1581 7.81485C15.883 8.54668 16.8885 9 18 9C20.2091 9 22 7.20914 22 5C22 
                2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 5.37701 14.0522 5.74185 14.1497 
                6.08767L8.84193 9.18514C8.11703 8.45332 7.11146 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.11167 16 8.11742 
                15.5465 8.84235 14.8144L14.1509 17.9079C14.0526 18.255 14 18.6214 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 
                19C22 16.7909 20.2091 15 18 15C16.8902 15 15.886 15.452 15.1613 16.1819L9.8505 13.0871C9.9479 12.7415 10 12.3768 10 12C10 11.623 
                9.94784 11.2581 9.85034 10.9123L15.1581 7.81485ZM18 3C16.8954 3 16 3.89543 16 5C16 5.34726 16.0885 5.67385 16.2442 5.95844C16.2544 
                5.97391 16.2642 5.98975 16.2737 6.00596C16.2831 6.02205 16.292 6.0383 16.3004 6.05468C16.6533 6.6222 17.2825 7 18 7C19.1046 7 20 
                6.10457 20 5C20 3.89543 19.1046 3 18 3ZM7.69977 10.9455C7.70813 10.9618 7.71698 10.978 7.72632 10.994C7.73573 11.0101 7.74553 
                11.0259 7.75569 11.0413C7.91145 11.326 8 11.6526 8 12C8 12.3473 7.91148 12.6739 7.75576 12.9585C7.74546 12.9742 7.73553 12.9902 
                7.726 13.0065C7.71675 13.0224 7.70799 13.0384 7.69971 13.0546C7.34681 13.6221 6.71753 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 
                4.89543 10 6 10C6.71757 10 7.34688 10.3779 7.69977 10.9455ZM16 19C16 18.6749 16.0776 18.3678 16.2153 18.0964C16.24 18.064 16.2629 
                18.0296 16.284 17.9935C16.3042 17.9588 16.3221 17.9234 16.3377 17.8875C16.6966 17.3523 17.3072 17 18 17C19.1046 17 20 17.8954 20 
                19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const DownloadIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V12.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 
                9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L11.2922 15.7064C11.2946 15.7088 11.297 15.7112 11.2995 15.7136C11.3938 15.8063 
                11.502 15.8764 11.6172 15.9241C11.7343 15.9727 11.8625 15.9996 11.997 16L12 16L12.003 16C12.2746 15.9992 12.5208 15.8901 12.7005 
                15.7136C12.703 15.7112 12.7054 15.7088 12.7078 15.7064L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 
                8.90237 16.6834 8.90237 16.2929 9.29289L13 12.5858V3Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M3 14C3.55228 14 4 14.4477 4 15V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 
                19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V19C22 
                19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 
                2 19.7957 2 19V15C2 14.4477 2.44772 14 3 14Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const HelpIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2582 8.02434C11.7927 7.94449 11.314 8.03197 10.9068 8.27129C10.4996 8.5106 10.1902 8.88631 10.0335 9.33187C9.85018 9.85286 9.27926 
                10.1266 8.75827 9.94336C8.23728 9.76008 7.96351 9.18917 8.14678 8.66818C8.46025 7.77707 9.07898 7.02565 9.89339 6.54702C10.7078 6.06839 
                11.6653 5.89343 12.5963 6.05313C13.5274 6.21283 14.3719 6.69688 14.9802 7.41955C15.5884 8.14207 15.9214 9.0565 15.9201 10.0009C15.9197 
                11.5313 14.7851 12.5419 13.9748 13.0821C13.5392 13.3725 13.1107 13.586 12.795 13.7263C12.6358 13.7971 12.5016 13.8508 12.405 13.8876C12.3566 
                13.9061 12.3174 13.9204 12.2888 13.9305L12.2541 13.9427L12.243 13.9465L12.2391 13.9478L12.2376 13.9483C12.2376 13.9483 12.2363 13.9487 11.9201 
                13L12.2363 13.9487C11.7124 14.1234 11.1461 13.8402 10.9714 13.3162C10.7969 12.7927 11.0796 12.2267 11.6028 12.0517L11.6188 12.0461C11.6342 
                12.0406 11.6594 12.0315 11.693 12.0187C11.7605 11.993 11.8607 11.9529 11.9827 11.8987C12.2296 11.789 12.551 11.6276 12.8654 11.418C13.555 
                10.9582 13.9201 10.4692 13.9201 10L13.9201 9.99853C13.9208 9.52621 13.7543 9.06889 13.4502 8.70755C13.146 8.34622 12.7238 8.10419 12.2582 8.02434Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18H12.01C12.5623 18 13.01 17.5523 13.01 17C13.01 16.4477 12.5623 16 12.01 16H12Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 
                12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const SignInIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 3C14 2.44772 14.4477 2 15 2H19C19.7957 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V19C22 19.7957 21.6839 20.5587 
                21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H19C19.2652 20 19.5196 19.8946 
                19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H15C14.4477 4 
                14 3.55228 14 3Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L15.7064 11.2922C15.7088 11.2946 15.7112 11.297 15.7136 11.2995C15.8901 
                11.4792 15.9992 11.7254 16 11.997L16 12L16 12.003C15.9996 12.1375 15.9727 12.2657 15.9241 12.3828C15.8764 12.498 15.8063 12.6062 15.7136 
                12.7005C15.7112 12.703 15.7088 12.7054 15.7064 12.7078L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071C8.90237 17.3166 
                8.90237 16.6834 9.29289 16.2929L12.5858 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H12.5858L9.29289 7.70711C8.90237 7.31658 
                8.90237 6.68342 9.29289 6.29289Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const HideAddressBarIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 -960 960 960">
            <path
                d="M480-200 240-440l56-56 184 183 184-183 56 56-240 240Zm0-240L240-680l56-56 184 183 184-183 56 56-240 240Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const FullscreenIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path
                d="M120-120v-200h80v120h120v80H120Zm520 0v-80h120v-120h80v200H640ZM120-640v-200h200v80H200v120h-80Zm640 0v-120H640v-80h200v200h-80Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const FullscreenExitIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path
                d="M240-120v-120H120v-80h200v200h-80Zm400 0v-200h200v80H720v120h-80ZM120-640v-80h120v-120h80v200H120Zm520 0v-200h80v120h120v80H640Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const FolderListChecked: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd" clipRule="evenodd"
                d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM12 17C14.7614 17 17 14.7614 
                17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                fill="var(--md-sys-color-primary)"
            />
        </svg>)
}


export const FolderListUnchecked: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 
                4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}

export const ChevronDownIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 
                7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 
                16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill="var(--md-sys-color-on-surface)"
            />
        </svg>)
}

export const ExpandMoreIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}

export const ExpandLessIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}

export const AlignLeftIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M3 9C2.44772 9 2 9.44772 2 10C2 10.5523 2.44772 11 3 11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M2 14C2 13.4477 2.44772 13 3 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H17C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
        </svg>)
}

export const AlignCenterIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11H18C18.5523 11 19 10.5523 19 10C19 9.44772 18.5523 9 18 9H6Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M2 14C2 13.4477 2.44772 13 3 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6Z"
                fill="var(--md-sys-color-on-surface-variant)" />
        </svg>)
}

export const AlignRightIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H21C21.5523 11 22 10.5523 22 10C22 9.44772 21.5523 9 21 9H7Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M2 14C2 13.4477 2.44772 13 3 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M7 17C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H7Z"
                fill="var(--md-sys-color-on-surface-variant)" />
        </svg>)
}

export const AlignJustifyIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M3 9C2.44772 9 2 9.44772 2 10C2 10.5523 2.44772 11 3 11H21C21.5523 11 22 10.5523 22 10C22 9.44772 21.5523 9 21 9H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M2 14C2 13.4477 2.44772 13 3 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H3Z"
                fill="var(--md-sys-color-on-surface-variant)" />
        </svg>)
}

export const BoldIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21H15C16.3261 21 
                17.5979 20.4732 18.5355 19.5355C19.4732 18.5979 20 17.3261 20 16C20 14.6739 19.4732 13.4021 18.5355 12.4645C18.206 
                12.1349 17.8352 11.8561 17.4352 11.6331C17.4691 11.6011 17.5025 11.5686 17.5355 11.5355C18.4732 10.5979 19 9.32608 
                19 8C19 6.67392 18.4732 5.40215 17.5355 4.46447C16.5979 3.52678 15.3261 3 14 3H6ZM14 11C14.7956 11 15.5587 10.6839 
                16.1213 10.1213C16.6839 9.55871 17 8.79565 17 8C17 7.20435 16.6839 6.44129 16.1213 5.87868C15.5587 5.31607 14.7956 5 
                14 5H7V11H14ZM7 13V19H15C15.7956 19 16.5587 18.6839 17.1213 18.1213C17.6839 17.5587 18 16.7956 18 16C18 15.2044 17.6839 
                14.4413 17.1213 13.8787C16.5587 13.3161 15.7956 13 15 13H7Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const ItalicIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0233 3.00003H19C19.5523 3.00003 20 3.44774 20 4.00003C20 4.55231 19.5523 5.00003 19 5.00003H15.693L10.443 
                19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H9.02304C9.00762 21.0004 8.99216 21.0004 8.97666 21H5C4.44772 
                21 4 20.5523 4 20C4 19.4477 4.44772 19 5 19H8.307L13.557 5.00003H10C9.44772 5.00003 9 4.55231 9 4.00003C9 3.44774 9.44772 
                3.00003 10 3.00003H14.977C14.9924 2.99967 15.0078 2.99966 15.0233 3.00003Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const CheckIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 
            18.7071L3.29289 13.7071C2.90237 13.3166 2.90237 12.6834 3.29289 12.2929C3.68342 11.9024 4.31658 11.9024 4.70711 12.2929L9 
            16.5858L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z"
                fill="var(--md-sys-color-primary)"
            />
        </svg>)
}


export const WarningIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5083 4.1747C14.0489 1.2751 9.95129 1.2751 8.49184 4.1747L2.43257 16.2155C1.10852 18.8415 2.97872 21.9997 
                5.94066 21.9997H18.0595C21.0229 21.9997 22.8893 18.8403 21.5677 16.2143L15.5083 4.1747Z"
                fill="#9C4221"
            />
            <path
                d="M12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 
                11.4477 16 12 16ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 
                11 13V9C11 8.44772 11.4477 8 12 8Z"
                fill="#FBD38D"
            />
        </svg>)
}


export const AllIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 
                3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 
                12ZM12.7071 7.29289L16.7064 11.2922C16.7088 11.2946 16.7112 11.297 16.7136 11.2995C16.8901 11.4792 16.9992 11.7254 
                17 11.997C17 11.998 17 11.999 17 12C17 12.001 17 12.002 17 12.003C16.9996 12.1375 16.9727 12.2657 16.9241 
                12.3828C16.8764 12.498 16.8063 12.6062 16.7136 12.7005C16.7112 12.703 16.7088 12.7054 16.7064 12.7078L12.7071 
                16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L13.5858 
                13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H13.5858L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 
                11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const UploadLogo: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="251" height="251" viewBox="0 0 251 251" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_975_19298)">
                <rect width="251" height="251" rx="32" fill="url(#paint0_linear_975_19298)" />
                <rect width="251" height="251" rx="32" fill="url(#paint1_linear_975_19298)" />
                <rect x="91.1836" y="233.242" width="122.559" height="122.559" rx="32" transform="rotate(-45 91.1836 233.242)" fill="url(#paint2_linear_975_19298)" />
                <rect x="91.1836" y="233.242" width="122.559" height="122.559" rx="32" transform="rotate(-45 91.1836 233.242)" fill="url(#paint3_linear_975_19298)" />
                <rect x="91.1836" y="233.242" width="122.559" height="122.559" rx="32" transform="rotate(-45 91.1836 233.242)" fill="url(#paint4_linear_975_19298)" />
                <rect x="91.1836" y="233.242" width="122.559" height="122.559" rx="32" transform="rotate(-45 91.1836 233.242)" fill="url(#paint5_linear_975_19298)" />
                <circle cx="178.2" cy="80.6436" r="18.3838" fill="url(#paint6_linear_975_19298)" />
                <circle cx="178.2" cy="80.6436" r="18.3838" fill="url(#paint7_linear_975_19298)" />
                <circle cx="178.2" cy="80.6436" r="18.3838" fill="url(#paint8_linear_975_19298)" />
                <rect x="-68.0455" y="265.675" width="207.25" height="207.25" rx="32" transform="rotate(-45 -68.0455 265.675)" fill="url(#paint9_linear_975_19298)" />
                <rect x="-68.0455" y="265.675" width="207.25" height="207.25" rx="32" transform="rotate(-45 -68.0455 265.675)" fill="url(#paint10_linear_975_19298)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_975_19298" x1="-81.1338" y1="-89.4678" x2="251" y2="251" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FEEBC8" />
                    <stop offset="1" stopColor="#FFFAF0" />
                </linearGradient>
                <linearGradient id="paint1_linear_975_19298" x1="-81.1338" y1="-89.4678" x2="251" y2="251" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EDF2F7" />
                    <stop offset="1" stopColor="#F7FAFC" />
                </linearGradient>
                <linearGradient id="paint2_linear_975_19298" x1="152.463" y1="233.242" x2="152.463" y2="355.801" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2251CC" />
                    <stop offset="1" stopColor="#A2B7EF" />
                </linearGradient>
                <linearGradient id="paint3_linear_975_19298" x1="152.463" y1="233.242" x2="152.463" y2="355.801" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6AD55" />
                    <stop offset="1" stopColor="#FEEBC8" />
                </linearGradient>
                <linearGradient id="paint4_linear_975_19298" x1="152.463" y1="233.242" x2="152.463" y2="355.801" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#718096" />
                    <stop offset="1" stopColor="#EDF2F7" />
                </linearGradient>
                <linearGradient id="paint5_linear_975_19298" x1="152.463" y1="233.242" x2="152.463" y2="355.801" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A0AEC0" />
                    <stop offset="1" stopColor="#EDF2F7" />
                </linearGradient>
                <linearGradient id="paint6_linear_975_19298" x1="159.816" y1="80.3984" x2="196.584" y2="80.3984" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2251CC" />
                    <stop offset="1" stopColor="#A2B7EF" />
                </linearGradient>
                <linearGradient id="paint7_linear_975_19298" x1="159.816" y1="80.3984" x2="196.584" y2="80.3984" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6AD55" />
                    <stop offset="1" stopColor="#FEEBC8" />
                </linearGradient>
                <linearGradient id="paint8_linear_975_19298" x1="159.816" y1="80.3984" x2="196.584" y2="80.3984" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A0AEC0" />
                    <stop offset="1" stopColor="#EDF2F7" />
                </linearGradient>
                <linearGradient id="paint9_linear_975_19298" x1="35.5794" y1="265.675" x2="35.5794" y2="472.924" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6AD55" />
                    <stop offset="1" stopColor="#FEEBC8" />
                </linearGradient>
                <linearGradient id="paint10_linear_975_19298" x1="35.5794" y1="265.675" x2="35.5794" y2="472.924" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A0AEC0" />
                    <stop offset="1" stopColor="#EDF2F7" />
                </linearGradient>
                <clipPath id="clip0_975_19298">
                    <rect width="251" height="251" rx="32" fill="white" />
                </clipPath>
            </defs>
        </svg>)
}


export const GalleryIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3311 11.0025C17.7118 11.0025 18.8311 9.88318 18.8311 8.50247C18.8311 7.12176 17.7118 6.00247 16.3311 
                6.00247C14.9504 6.00247 13.8311 7.12176 13.8311 8.50247C13.8311 9.88318 14.9504 11.0025 16.3311 11.0025Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.83334 5C2.83334 3.34315 4.17649 2 5.83334 2H19.8333C21.4902 2 22.8333 
                3.34315 22.8333 5V19C22.8333 20.6569 21.4902 22 19.8333 22H5.83334C4.17649 22 2.83334 20.6569 2.83334 19V5ZM19.8333 4C20.3856 
                4 20.8333 4.44772 20.8333 5V17.6751L17.12 14.157C16.5669 13.633 15.7088 13.6069 15.1249 14.0963L13.7256 15.2691L10.3754 
                11.7591C9.71404 11.0662 8.58151 11.1623 8.04631 11.9567L4.83334 16.7259V5C4.83334 4.44772 5.28106 4 5.83334 4H19.8333Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const TemplateIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 6C6.94772 6 6.5 6.44772 6.5 7C6.5 7.55228 6.94772 8 7.5 8H8.50179C9.05407 8 9.50179 7.55228 
                9.50179 7C9.50179 6.44772 9.05407 6 8.50179 6H7.5Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M6.5 11C6.5 10.4477 6.94772 10 7.5 10H8.50179C9.05407 10 9.50179 10.4477 9.50179 11C9.50179 11.5523 
                9.05407 12 8.50179 12H7.5C6.94772 12 6.5 11.5523 6.5 11Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5 22C22.0523 22 22.5 21.5523 22.5 21V3C22.5 2.44772 22.0523 2 21.5 2H3.5C2.94772 2 2.5 2.44772 2.5 
                3V21C2.5 21.5523 2.94772 22 3.5 22H21.5ZM4.5 4V20H11.5V4H4.5ZM20.5 4H13.5V20L13.5 12H20.5L20.5 4ZM15.5 16C15.5 
                15.4477 15.9477 15 16.5 15H17.5018C18.0541 15 18.5018 15.4477 18.5018 16C18.5018 16.5523 18.0541 17 17.5018 
                17H16.5C15.9477 17 15.5 16.5523 15.5 16Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const BgIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.44988 2.29118C9.05935 1.90066 8.42619 1.90066 8.03566 2.29118C7.64514 2.68171 7.64514 3.31487 8.03566 
                3.7054L10.8276 6.49731L3.75247 13.5724C2.97142 14.3535 2.97142 15.6198 3.75247 16.4009L8.76585 21.4142C9.5469 
                22.1953 10.8132 22.1953 11.5943 21.4142L19.369 13.6395C19.7264 13.2821 19.7568 12.7214 19.4599 12.3295C19.4235 
                12.2746 19.3811 12.2224 19.3327 12.174L9.44988 2.29118ZM17.2552 12.9249L12.2418 7.91153L7.96797 12.1854L15.8758 
                14.3043L17.2552 12.9249ZM5.16668 14.9866L6.33497 13.8183L14.2428 15.9373L10.1801 20L5.16668 14.9866Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path d="M19.3057 22C20.33 22 21.1667 21.1516 21.1667 20.1133C21.1667 18.8594 19.3057 16.8133 19.3057 
                16.8133C19.3057 16.8133 17.4448 18.8594 17.4448 20.1133C17.4448 21.1516 18.2815 22 19.3057 22Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
            <path
                d="M6.33497 13.8183L5.16668 14.9866L10.1801 20L14.2428 15.9373L6.33497 13.8183Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const TextInfoIcon: React.FC<{ x: string, y: string, width: string, height: string }> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={+props.x + +props.width - 12}
            y={+props.y - +props.height}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#C53030"
            />
            <path
                d="M11 7.5C11 6.94772 11.4477 6.5 12 6.5C12.5523 6.5 13 6.94772 13 7.5V12.5C13 13.0523 12.5523 13.5 12 
                13.5C11.4477 13.5 11 13.0523 11 12.5V7.5Z"
                fill="white"
            />
            <path d="M13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 
                15.5 12 15.5C12.5523 15.5 13 15.9477 13 16.5Z"
                fill="white"
            />
        </svg>)
}

export const ImageInfoIcon: React.FC<{ x: string, y: string, width: string, height: string }> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={+props.x + 4}
            y={+props.y + 4}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#C53030"
            />
            <path
                d="M11 7.5C11 6.94772 11.4477 6.5 12 6.5C12.5523 6.5 13 6.94772 13 7.5V12.5C13 13.0523 12.5523 13.5 12 
                13.5C11.4477 13.5 11 13.0523 11 12.5V7.5Z"
                fill="white"
            />
            <path d="M13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 
                15.5 12 15.5C12.5523 15.5 13 15.9477 13 16.5Z"
                fill="white"
            />
        </svg>)
}

export const ImageWarningIcon: React.FC<{ x: string, y: string, width: string, height: string }> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={+props.x + 4}
            y={+props.y + 4}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5083 4.1747C14.0489 1.2751 9.95129 1.2751 8.49184 4.1747L2.43257 16.2155C1.10852 18.8415 2.97872 
                21.9997 5.94066 21.9997H18.0595C21.0229 21.9997 22.8893 18.8403 21.5677 16.2143L15.5083 4.1747Z"
                fill="#ED8936"
            />
            <path
                d="M12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 
                16.4477 11.4477 16 12 16ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 
                11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
                fill="white"
            />
        </svg>)
}

export const CloseIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5 6L6.5 18M6.5 6L18.5 18"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const RotateIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.88672 3C4.88672 2.44772 4.439 2 3.88672 2C3.33443 2 2.88672 2.44772 2.88672 3V9C2.88672 9.55228 3.33443 
                10 3.88672 10H9.88672C10.439 10 10.8867 9.55228 10.8867 9C10.8867 8.44772 10.439 8 9.88672 8H6.2547C7.51965 
                6.18652 9.62127 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.86393 19 6.20952 16.9377 5.31899 
                14.0953C5.17164 13.625 4.75689 13.2696 4.26403 13.2696C3.64344 13.2696 3.16247 13.8215 3.32892 14.4194C4.38569 
                18.2149 7.86761 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C9.10723 3 6.53321 4.36479 4.88672 6.48559V3Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}


export const ChevronLeftIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path
                d="M15 18L9 12L15 6"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const ChevronRightIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 6.5L15 12.5L9 18.5"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const ErrorIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" slot="trailing-icon">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z"
                fill="#E53E3E"
            />
        </svg>)
}


export const MailIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" slot="leading-icon">
            <path
                d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22 6L12 13L2 6"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const LockIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" slot="leading-icon">
            <path
                d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>)
}


export const PhoneIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" slot="leading-icon">
            <path
                d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 18H12.01"
                stroke="var(--md-sys-color-outline-variant, #A0AEC0)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const MinusCircleIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 
                12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#FED7D7"
                stroke="#C53030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 12H16"
                stroke="#C53030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const AddIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                stroke="var(--md-sys-color-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 8.5V16.5"
                stroke="var(--md-sys-color-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 12.5H16"
                stroke="var(--md-sys-color-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const AddCircleIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="25" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M440-440v120q0 17 11.5 28.5T480-280q17 0 28.5-11.5T520-320v-120h120q17 0 
                28.5-11.5T680-480q0-17-11.5-28.5T640-520H520v-120q0-17-11.5-28.5T480-680q-17 
                0-28.5 11.5T440-640v120H320q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440h120Zm40 
                360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 
                0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"
                fill="var(--md-sys-color-surface)" />
        </svg>)
}


export const MenuIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 12H21.5M3.5 6H21.5M3.5 18H21.5"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const UploadIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 
                3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M17 8L12 3M12 3L7 8M12 3V15"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const ApertureIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.31 8L20.05 17.94M9.69 8H21.17M7.38 12L13.12 2.06M9.69 16L3.95 6.06M14.31 16H2.83M16.62 12L10.88 21.94M22 
                12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const ApertureFillIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 4.06189V19.9381C17.4463 19.446 20.5 16.0796 20.5 12C20.5 7.92038 17.4463 4.55399 13.5 4.06189ZM2.5 
                12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12Z"
                fill="var(--md-sys-color-on-surface-variant)"
            />
        </svg>)
}

export const EyeOffIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_754_15885)">
                <path
                    d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06M9.9 
                    4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19M14.12 14.12C13.8454 
                    14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 
                    10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 
                    12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4859 9.58525 10.1546 9.88 9.88M1 1L23 23"
                    stroke="var(--md-sys-color-on-surface-variant)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_754_15885">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>)
}

export const CheckCircleFillIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                fill="var(--md-sys-color-on-surface-variant)" />
            <path
                d="M16.7062 10.2071C17.0967 9.81657 17.0967 9.18341 16.7062 8.79289C16.3156 8.40237 15.6825 8.40237 15.2919 8.7929L10.4389 
                13.646L8.70709 11.9143C8.31655 11.5238 7.68339 11.5238 7.29287 11.9143C6.90236 12.3048 6.90238 12.938 7.29291 13.3285L9.73186 
                15.7673C10.1224 16.1578 10.7555 16.1578 11.1461 15.7673L16.7062 10.2071Z"
                fill="var(--md-sys-color-surface-variant)"
            />
        </svg>
    )
}


export const CheckCircleIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="path-1-inside-1_754_15888"
                fill="white"
            >
                <path
                    d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 
                13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 
                15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 
                22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                />
            </mask>
            <path
                d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 
                13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 
                15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 
                22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="black"
            />
            <path
                d="M16.7062 8.29289L18.1204 6.87866L18.1204 6.87866L16.7062 8.29289ZM16.7062 9.7071L15.2919 8.2929L15.2919 8.2929L16.7062 
                9.7071ZM15.2919 8.2929L13.8777 6.8787L13.8777 6.8787L15.2919 8.2929ZM10.4389 13.146L9.02475 14.5603C9.80581 15.3413 11.0721 
                15.3413 11.8532 14.5602L10.4389 13.146ZM8.70709 11.4143L7.29291 12.8285L8.70709 11.4143ZM7.29287 11.4143L8.70713 
                12.8285H8.70713L7.29287 11.4143ZM7.29291 12.8285L8.70709 11.4143L8.70709 11.4143L7.29291 12.8285ZM9.73186 15.2673L11.146 
                13.8531L11.146 13.8531L9.73186 15.2673ZM11.1461 15.2673L12.5603 16.6815L12.5603 16.6815L11.1461 15.2673ZM15.292 
                9.70711C14.9014 9.31659 14.9014 8.68343 15.2919 8.2929L18.1204 11.1213C19.292 9.94972 19.2919 8.05022 18.1204 6.87866L15.292 
                9.70711ZM16.7062 9.7071C16.3156 10.0976 15.6825 10.0976 15.292 9.70712L18.1204 6.87866C16.9488 5.7071 15.0493 5.70712 13.8777 
                6.8787L16.7062 9.7071ZM11.8532 14.5602L16.7062 9.7071L13.8777 6.8787L9.0247 11.7318L11.8532 14.5602ZM7.29291 12.8285L9.02475 
                14.5603L11.8531 11.7318L10.1213 10L7.29291 12.8285ZM8.70713 12.8285C8.31661 13.219 7.68345 13.219 7.29291 12.8285L10.1213 
                10C8.94966 8.82847 7.05016 8.82852 5.87862 10.0001L8.70713 12.8285ZM8.70709 11.4143C9.09762 11.8048 9.09764 12.4379 8.70713 
                12.8285L5.87862 10.0001C4.70708 11.1717 4.70713 13.0712 5.87874 14.2428L8.70709 11.4143ZM11.146 13.8531L8.70709 11.4143L5.87874 
                14.2428L8.31769 16.6816L11.146 13.8531ZM9.73184 13.8531C10.1224 13.4626 10.7555 13.4626 11.146 13.8531L8.31769 16.6816C9.48928 
                17.8531 11.3887 17.8531 12.5603 16.6815L9.73184 13.8531ZM15.2919 8.2929L9.73184 13.8531L12.5603 16.6815L18.1204 11.1213L15.2919 
                8.2929ZM18 12C18 15.3137 15.3137 18 12 18V22C17.5228 22 22 17.5228 22 12H18ZM12 6C15.3137 6 18 8.68629 18 12H22C22 6.47715 17.5228 
                2 12 2V6ZM6 12C6 8.68629 8.68629 6 12 6V2C6.47715 2 2 6.47715 2 12H6ZM12 18C8.68629 18 6 15.3137 6 12H2C2 17.5228 6.47715 22 
                12 22V18ZM20 12C20 16.4183 16.4183 20 12 20V24C18.6274 24 24 18.6274 24 12H20ZM12 4C16.4183 4 20 7.58172 20 12H24C24 5.37258 
                18.6274 0 12 0V4ZM4 12C4 7.58172 7.58172 4 12 4V0C5.37258 0 0 5.37258 0 12H4ZM12 20C7.58172 20 4 16.4183 4 12H0C0 18.6274 
                5.37258 24 12 24V20Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                mask="url(#path-1-inside-1_754_15888)"
            />
        </svg>)
}


export const EyeIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}


export const DeleteIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 6H5.5M5.5 6H21.5M5.5 6V20C5.5 20.5304 5.71071 21.0391 6.08579 21.4142C6.46086 21.7893 6.96957 
                22 7.5 22H17.5C18.0304 22 18.5391 21.7893 18.9142 21.4142C19.2893 21.0391 19.5 20.5304 19.5 20V6M8.5 
                6V4C8.5 3.46957 8.71071 2.96086 9.08579 2.58579C9.46086 2.21071 9.96957 2 10.5 2H14.5C15.0304 2 15.5391 
                2.21071 15.9142 2.58579C16.2893 2.96086 16.5 3.46957 16.5 4V6M10.5 11V17M14.5 11V17"
                stroke="#E53E3E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const DeleteForeverIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="25" height="24" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 
                11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 
                33-23.5 56.5T680-120H280Zm200-284 76 76q11 11 28 11t28-11q11-11 11-28t-11-28l-76-76 76-76q11-11 
                11-28t-11-28q-11-11-28-11t-28 11l-76 76-76-76q-11-11-28-11t-28 11q-11 11-11 28t11 28l76 76-76 76q-11 
                11-11 28t11 28q11 11 28 11t28-11l76-76Z"
                fill="white" />
        </svg>)
}

export const MoreVerticalIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                stroke="var(--md-sys-color-on-surface-variant)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const LoginIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 9.25C13.3766 9.25 11.25 11.3766 11.25 14C11.25 15.5532 11.9955 16.9323 13.1482 17.799C11.5406 18.4481 10.215 19.6313 
                9.35099 21.1243C9.14352 21.4828 9.26595 21.9417 9.62445 22.1491C9.98296 22.3566 10.4418 22.2342 10.6493 21.8757C11.7354 
                19.9989 13.6958 18.75 16.0001 18.75C18.3045 18.75 20.2649 19.9989 21.351 21.8757C21.5585 22.2342 22.0173 22.3566 22.3758 
                22.1491C22.7343 21.9417 22.8567 21.4828 22.6493 21.1243C21.7852 19.6313 20.4595 18.448 18.8519 17.799C20.0045 16.9323 
                20.75 15.5532 20.75 14C20.75 11.3766 18.6234 9.25 16 9.25ZM12.75 14C12.75 12.2051 14.2051 10.75 16 10.75C17.7949 10.75 
                19.25 12.2051 19.25 14C19.25 15.7949 17.7949 17.25 16 17.25C14.2051 17.25 12.75 15.7949 12.75 14Z"
                fill="var(--md-sys-color-outline)"
            />
            <rect x="0.75" y="0.75" width="30.5" height="30.5" rx="15.25" stroke="var(--md-sys-color-outline-variant)" stroke-width="1.5" />
        </svg>)
}

export const EditIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 20H21"
                stroke="var(--md-sys-color-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 
                3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 
                4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 
                6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                stroke="var(--md-sys-color-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const CartIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
                stroke="var(--md-sys-color-on-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
                stroke="var(--md-sys-color-on-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 
                16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
                stroke="var(--md-sys-color-on-primary)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>)
}

export const ErrorInfoIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_889_33681)">
                <path
                    d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 
                    5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
                    fill="#FFE3E3"
                    stroke="#F86A6A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 
                    11 17C11 16.4477 11.4477 16 12 16ZM12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 
                    14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                    fill="#CF1124"
                />
            </g>
            <defs>
                <clipPath id="clip0_889_33681">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>)
}

export const ScreenRotation: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 -960 960 960">
            <path
                d="M496-182 182-496q-23-23-23-54t23-54l174-174q23-23 54-23t54 23l314 314q23 23 23 54t-23 54L604-182q-23 23-54 
                23t-54-23Zm54-58 170-170-310-310-170 170 310 310Zm-70-240Zm79-393 77 77q11 11 11 28t-11 28q-11 11-28 
                11t-28-11L410-910q-12-12-6.5-28t22.5-19q14-2 27-2.5t27-.5q99 0 186.5 37.5t153 103q65.5 65.5 103 153T960-480q0 
                17-11.5 28.5T920-440q-17 0-28.5-11.5T880-480q0-71-24-136t-66.5-117Q747-785 688-821.5T559-873ZM401-87l-77-77q-11-11-11-28t11-28q11-11 
                28-11t28 11L550-50q12 12 6.5 28.5T534-3q-14 2-27 2.5T480 0q-99 0-186.5-37.5t-153-103Q75-206 37.5-293.5T0-480q0-17 
                11.5-28.5T40-520q17 0 28.5 11.5T80-480q0 71 24 136t66.5 117Q213-175 272-138.5T401-87Z"
                fill="var(--md-sys-color-outline-variant)"
            />
        </svg>
    )
}

export const DensityIcon: React.FC<{ slotted?: boolean }> = ({ slotted }) => {
    return (
        <svg slot={slotted ? 'icon' : undefined} width="24" height="24" viewBox="0 -960 960 960">
            <path
                d="M120-120v-80h720v80H120Zm0-320v-80h720v80H120Zm0-320v-80h720v80H120Z"
                fill="var(--md-sys-color-outline-variant)" />
        </svg>
    )
}