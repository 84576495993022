import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "../ui/icons"

// --------------------------------------------------------------------------------

export const PasswordInfo: React.FC<{ email: string }> = ({ email }) => {
    const navigate = useNavigate()

    return (
        <form action="#">
            <md-text-button
                type="button"
                class="right"
                onClick={() => navigate(-1)}
                trailing-icon={true}
            >
                Назад
                <ChevronRightIcon slotted />
            </md-text-button>
            <section>
                <label>
                    Восстановление пароля
                </label>
                <div className="password-info">
                    Инструкция по восстановлению<br /> пароля отправлена на <span>{email}</span>
                </div>
            </section>
        </form>
    )
}