import { MdDialog } from "@material/web/dialog/dialog"
import { useCallback, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../store"
import { closeDialog } from "../../store/reducers/dialogSlice"

// --------------------------------------------------------------------------------

export const Dialog: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        opened,
        headline,
        content,
        actions
    } = useAppSelector(state => state.dialog)

    const dialogRef = useRef<MdDialog>(null)

    const closeHandler = useCallback(() => dispatch(closeDialog()), [])

    useEffect(() => {
        if (dialogRef.current && opened)
            dialogRef.current.show()
    }, [opened])

    useEffect(() => {
        if (dialogRef.current)
            dialogRef.current.addEventListener('close', closeHandler, { passive: true })

        return () =>
            dialogRef.current?.removeEventListener('close', closeHandler)

    }, [dialogRef])

    const handleClose = () => {
        dialogRef.current?.close()
        actions?.cancelAction && actions.cancelAction()
    }
    const handleSubmit = () => {
        dialogRef.current?.close()
        actions?.submitAction()
    }

    return (
        <md-dialog ref={dialogRef}>
            {headline &&
                <div slot="headline">
                    {headline}
                </div>}

            {content &&
                <div slot="content">
                    {content}
                </div>}

            {actions &&
                <div slot="actions">
                    {actions.cancel &&
                        <md-text-button onClick={handleClose}>{actions.cancel}</md-text-button>}

                    <md-text-button onClick={handleSubmit}>{actions.submit ?? 'Ok'}</md-text-button>
                </div>}
        </md-dialog>
    )
}