import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TemplateComponent } from '../../@types/album'
// @types


// --------------------------------------------------------------------------------


const layoutsFilter = localStorage.getItem('layoutsFilter') ?? '0'
const language = navigator.language.substring(0, 2)

const initialState: {
    layoutsFilter: string
    language: string
} = {
    layoutsFilter,
    language
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {

        setLayoutsFilter: (state, action: PayloadAction<string>) => {
            state.layoutsFilter = action.payload
            localStorage.setItem('layoutsFilter', `${action.payload}`)
        },
    }
})

export const { setLayoutsFilter } = settingsSlice.actions

export default settingsSlice.reducer