import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../store"
import { ChangeEventHandler, FormEventHandler, useEffect, useRef, useState } from "react"
import { removeText, setText } from "../store/reducers/albumSlice"
import { disableTextEditMode } from "../store/reducers/textEditorSlice"
import FocusLock from "react-focus-lock"
import { createPortal } from "react-dom"
import { ModalBottomSheet } from "./ui/ModalBottomSheet"
import { AlignCenterIcon, AlignLeftIcon, AlignRightIcon, BoldIcon, CheckCircleFillIcon, ChevronDownIcon, ItalicIcon } from "./ui/icons"
import { IS_APPLE } from "../utils/environment"

// --------------------------------------------------------------------------------

const FONT_FAMILY_OPTIONS: string[] = [
    'Arial',
    'Times New Roman',
]

const FONT_SIZE_OPTIONS: string[] = [
    '10pt',
    '11pt',
    '12pt',
    '13pt',
    '14pt',
    '15pt',
    '16pt',
    '17pt',
    '18pt',
    '19pt',
    '20pt',
    '30pt',
    '40pt',
    '50pt',
    '60pt',
]

// Current style
// {
//     "font_size":"36",
//     "font_color_id":"140",
//     "font_id":"13",
//     "angle":"0",
//     "font_style":"bold_italic",
//     "align":"right",
//     "font_limit_status":"false",
//     "font_limit_min_size":"",
//     "font_limit_max_size":"",
//     "font_limit_font_family":"",
//     "lineHeight":"1",
//     "notNew":"true"
// }

export const TextEditor = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { album } = useAppSelector(state => state.album)
    const { isVisible, itemId, templateComponent, ratio } = useAppSelector(state => state.textEditor)

    const toolbarRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLTextAreaElement>(null)

    const [style, setStyle] = useState<React.CSSProperties>({
        fontFamily: FONT_FAMILY_OPTIONS[0],
        color: 'black',
        fontSize: '30pt'
    })

    const [showFonts, setShowFonts] = useState(false)
    const [showFontSizes, setShowFontSizes] = useState(false)
    const [showFontColors, setShowFontColors] = useState(false)

    const [focusLockDisabled, setFocusLockDisabled] = useState(true)

    const itemComponent = album && templateComponent && itemId
        ? album.items[itemId].components.find(c => c.template_component_id === templateComponent.id)
        : undefined

    // Prevent adding new line
    const handleInput: FormEventHandler<HTMLTextAreaElement> = (e) => {
        if (!inputRef.current) return
        const value = inputRef.current.value
        inputRef.current.value = value.replace(/\n/g, '')
    }

    // Handle save
    const handleSave = () => {
        dispatch(disableTextEditMode())
        navigate(-1)

        if (inputRef.current?.value && itemId && templateComponent)
            dispatch(setText({
                itemId,
                component: {
                    id: "",
                    system_id: "",
                    project_item_id: itemId,
                    template_component_id: templateComponent.id,
                    value: inputRef.current?.value,
                    style: JSON.stringify(style),
                    has_error: "",
                }
            }))
        else if (itemId && itemComponent)
            dispatch(removeText({
                itemId,
                templateCompId: itemComponent.template_component_id
            }))
    }

    // Fixes iOS buggly viewport with the keyboard opened
    useEffect(() => {
        if (!IS_APPLE || !toolbarRef.current) return
        const toolbar = toolbarRef.current

        const resizeHandler = () =>
            document.documentElement.scrollTo({ top: 0 })

        if (isVisible) {
            toolbar.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false })
            window.visualViewport?.addEventListener('resize', resizeHandler)
        } else {
            toolbar.removeEventListener('touchmove', (e) => e.preventDefault())
            window.visualViewport?.removeEventListener('resize', resizeHandler)
        }
    }, [isVisible])

    useEffect(() => {
        if (itemComponent)
            try {
                const styleObj = JSON.parse(itemComponent.style)
                setStyle(styleObj)
            } catch {

            }
    }, [itemComponent])

    const handleFocus = () => {
        if (IS_APPLE) {
            toolbarRef.current?.classList.add('hidden')
            setTimeout(() => toolbarRef.current?.classList.remove('hidden'), 600)
        }
    }

    useEffect(() => {
        setFocusLockDisabled(false)
    }, [isVisible])

    return (
        isVisible
            ? <>
                <FocusLock className="text-editor" disabled={focusLockDisabled}>
                    <textarea
                        ref={inputRef}
                        style={style}
                        onInput={handleInput}
                        defaultValue={itemComponent?.value ?? ''}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FocusLock>

                {createPortal(
                    <md-text-button
                        onClick={handleSave}
                        style={{
                            gridRow: '1 / 2',
                            gridColumn: '3 / 4'
                        }}
                    >
                        Сохранить
                    </md-text-button>,
                    document.querySelector('header') ?? document.body
                )}

                <div className={`text-editor-toolbar ${IS_APPLE ? 'hidden' : ''}`} ref={toolbarRef}>
                    <md-elevation />

                    <div className='top'>

                        <div onClick={() => setShowFonts(true)}>
                            {style.fontFamily}
                            <ChevronDownIcon />
                        </div>

                        <div onClick={() => setShowFontSizes(true)}>
                            {style.fontSize}
                            <ChevronDownIcon />
                        </div>

                    </div>

                    <div className='align'>

                        <div className={style?.textAlign === undefined ? 'active' : undefined}>
                            <md-icon-button
                                onClick={() => setStyle({ ...style, textAlign: undefined })}
                            >
                                <AlignLeftIcon />
                            </md-icon-button>
                        </div>

                        <div className={style?.textAlign === 'center' ? 'active' : undefined}>
                            <md-icon-button
                                onClick={() => setStyle({ ...style, textAlign: 'center' })}
                            >
                                <AlignCenterIcon />
                            </md-icon-button>
                        </div>

                        <div className={style?.textAlign === 'right' ? 'active' : undefined}>
                            <md-icon-button
                                onClick={() => setStyle({ ...style, textAlign: 'right' })}
                            >
                                <AlignRightIcon />
                            </md-icon-button>
                        </div>

                    </div>

                    <div className='style'>

                        <div className={style?.fontWeight === 'bold' ? 'active' : undefined}>
                            <md-icon-button
                                onClick={() => setStyle({ ...style, fontWeight: style?.fontWeight ? undefined : 'bold' })}
                            >
                                <BoldIcon />
                            </md-icon-button>
                        </div>

                        <div className={style?.fontStyle === 'italic' ? 'active' : undefined}>
                            <md-icon-button
                                onClick={() => setStyle({ ...style, fontStyle: style?.fontStyle ? undefined : 'italic' })}
                            >
                                <ItalicIcon />
                            </md-icon-button>
                        </div>

                    </div>

                    <div className='color' onClick={() => setShowFontColors(true)}>
                        <div style={{ backgroundColor: style.color }} />
                        <ChevronDownIcon />
                    </div>

                    <ModalBottomSheet
                        state={showFonts}
                        setState={setShowFonts}
                        className='text-editor-fonts'
                    >
                        <span>Выберите шрифт</span>
                        <div className='scrollable'>

                            {FONT_FAMILY_OPTIONS.map(option =>
                                <div
                                    key={`fonts-bs-${option}`}
                                    style={{ fontFamily: option }}
                                    onClick={() => {
                                        setShowFonts(false)
                                        setStyle({ ...style, fontFamily: option })
                                        inputRef.current?.focus()
                                    }}
                                >
                                    {option}
                                    {style.fontFamily === option &&
                                        <CheckCircleFillIcon />}
                                </div>)}

                        </div>
                    </ModalBottomSheet>

                    <ModalBottomSheet
                        state={showFontSizes}
                        setState={setShowFontSizes}
                        className='text-editor-fonts'
                    >
                        <span>Выберите размер</span>
                        <div className='scrollable'>

                            {FONT_SIZE_OPTIONS.map(option =>
                                <div
                                    key={`fonts-bs-${option}`}
                                    onClick={() => {
                                        setShowFontSizes(false)
                                        setStyle({ ...style, fontSize: option })
                                    }}
                                >
                                    {option}
                                    {style.fontSize === option &&
                                        <CheckCircleFillIcon />}
                                </div>)}

                        </div>
                    </ModalBottomSheet>

                    <ModalBottomSheet
                        state={showFontColors}
                        setState={setShowFontColors}
                        className='text-editor-colors'
                    >
                        <span>Выберите цвет</span>
                        <div className='scrollable'>
                            {album && Object.entries(album.textColors).map(([id, row]) =>

                                <div key={`background-row-${id}`}>
                                    {row.map(el =>

                                        <div
                                            key={`background-color-box-${el.id}`}
                                            className="color-box"
                                            style={{ backgroundColor: `rgb(${el.rgb})` }}
                                            onClick={() => {
                                                setShowFontColors(false)
                                                setStyle({ ...style, color: `rgb(${el.rgb})` })
                                                inputRef.current?.focus()
                                            }}
                                        />)}

                                </div>)}
                        </div>
                    </ModalBottomSheet>

                </div>

            </>
            : null
    )
}