import { useEffect, useRef, useState } from "react"
import { CloseIcon } from "./icons"

// --------------------------------------------------------------------------------

interface List {
    title: string
    icon: JSX.Element
    action?: () => void
    activated?: boolean
}

interface Active {
    title: string
    icon: JSX.Element
}

export interface BottomSheetMainAction {
    title: string
    icon: JSX.Element
    action: () => void
    class?: string
}

export interface BottomSheetActionsProps {
    active: Active
    list: List[]
    mainAction?: BottomSheetMainAction
}

export const BottomSheetActions: React.FC<BottomSheetActionsProps> = ({ active, list, mainAction }) => {
    const scrimRef = useRef<HTMLDivElement>(null)
    const [show, setShow] = useState(false)
    const [scrim, setScrim] = useState(false)

    // useEffect(() => {
    //     if (show) {
    //         setScrim(true)
    //         setTimeout(() => {
    //             if (scrimRef.current)
    //                 scrimRef.current.style.opacity = '0.35'
    //         }, 100)
    //     } else {
    //         if (scrimRef.current)
    //             scrimRef.current.style.opacity = '0'
    //         setTimeout(() => setScrim(false), 500)
    //     }
    // }, [show])

    const scrimStyle: React.CSSProperties = {
        position: "fixed",
        inset: 0,
        background: "var(--md-sys-color-scrim)",
        opacity: 0,
        transition: "opacity .28s ease-in-out",
        zIndex: -1
    }

    return (
        <div className="bottom-sheet-actions">

            <div
                className={`active ${mainAction?.class ?? ''}`}
                onClick={() => mainAction ? mainAction.action() : setShow(!show)}
            >
                {mainAction
                    ? <><md-elevation />{mainAction.icon}{mainAction.title}</>
                    : show
                        ? <>
                            <CloseIcon />
                            Закрыть
                        </>
                        : <>
                            <md-elevation />
                            <div className="action-button-icon">
                                {active.icon}
                                {list.map(el => { if (el.activated) return true }).includes(true) &&
                                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none">
                                        <circle cx="5.5" cy="5" r="4.5" />
                                    </svg>}
                            </div>
                            {active.title}
                        </>}
            </div>

            <div className={`all-actions ${show ? 'visible' : ''}`}>
                <md-elevation />
                {list.map(btn =>
                    <div
                        key={`bs-action-${btn.title}`}
                        onClick={() => {
                            if (btn.action) btn.action()
                            setTimeout(() => setShow(false))
                        }}
                    >
                        <div className="action-button-icon">
                            {btn.icon}
                            {btn.activated && <svg width="11" height="10" viewBox="0 0 11 10" fill="none">
                                <circle cx="5.5" cy="5" r="4.5" />
                            </svg>}
                        </div>
                        {btn.title}
                    </div>
                )}
            </div>

            {scrim &&
                <div ref={scrimRef} style={scrimStyle} />}
        </div>
    )
}