import { useAppDispatch, useAppSelector } from "../store"
import { useNavigate } from "react-router-dom"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"
import { useEffect, useRef } from "react"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { SwiperContainer } from "swiper/element"

// --------------------------------------------------------------------------------

export const MiniBackground: React.FC = () => {
    const { dragActive } = useAppSelector(state => state.dnd)
    const { album } = useAppSelector(state => state.album)
    const swiperRef = useRef<SwiperContainer>(null)
    const dndEvents = useDragAndDrop()
    const dispatch = useAppDispatch()
    const nav = useNavigate()

    const handleOpenGallery = () => {
        dispatch(setBottomSheetData({ background: true }))
        nav(`/background`)
    }

    useEffect(() => {
        if (!swiperRef.current) return
        if (dragActive)
            swiperRef.current.swiper.allowTouchMove = false
        else
            swiperRef.current.swiper.allowTouchMove = true
    }, [swiperRef.current, dragActive])

    return (
        <swiper-container
            class="bg"
            ref={swiperRef}
            slides-per-view={1}
        >
            {album && Object.entries(album.bgColors).map(([id, row]) =>
                <swiper-slide key={`toolbar-panel-background-row-${id}`}>
                    {row.map(el =>
                        <div
                            key={`color-box-${el.id}`}
                            className="color-box"
                            style={{ backgroundColor: `rgb(${el.rgb})` }}
                            data-background-group-id={el.group_id}
                            data-background-id={el.id}
                            {...dndEvents}
                        />)}
                </swiper-slide>
            )}
        </swiper-container>
    )
}