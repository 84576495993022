import { createBrowserRouter } from "react-router-dom"
// components
import { App } from "../App"

// --------------------------------------------------------------------------------

export const router = createBrowserRouter([
    {
        path: "/constructor/project/view/:projectId",
        element: <App />,
        children: [
            { path: ":slug", element: null }
        ],
    },
])