import { useCallback, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { setDropPosition, startDrag, stopDrag } from "../store/reducers/dragAndDropSlice"
import { SwiperSlide } from "swiper/element"
import { addImageToSheet, setBackground, setTemplate } from "../store/reducers/albumSlice"

// --------------------------------------------------------------------------------

const longPressThreshold = 600

export const useDragAndDrop = () => {
    const dispatch = useAppDispatch()
    const { isVisible } = useAppSelector(state => state.bottomSheet)
    const isLongPressActive = useRef(false)
    const isPressed = useRef(false)
    const timer = useRef<number>()

    const isMobile = 'ontouchstart' in window
    const dragIsActive = useRef(false)

    const el = useRef<HTMLDivElement | null>(null)
    const slide = useRef<SwiperSlide | null>(null)
    let x = 0,
        y = 0,
        xPrev = 0,
        yPrev = 0,
        xStart = 0,
        yStart = 0

    // Create draggable element from event target
    const registerDraggableElement = (e: TouchEvent | PointerEvent) => {

        if (!dragIsActive.current) {
            dragIsActive.current = true

            let target = e.target as HTMLDivElement
            const dragTarget = target.closest(".draggable") as HTMLDivElement | null ?? target

            const dataSet = dragTarget.dataset
            if (dataSet.imgSrc)
                dispatch(startDrag('img'))
            else if (dataSet.backgroundId)
                dispatch(startDrag('bgr'))
            else if (dataSet.layoutId)
                dispatch(startDrag('tmpl'))
            else if (dataSet.sheetIndex && dataSet.sheetId)
                dispatch(startDrag({ type: 'sheet', id: dataSet.sheetId }))

            const { top, left, height, width } = dragTarget.getBoundingClientRect()

            el.current = dragTarget.cloneNode(true) as HTMLDivElement
            el.current.classList.add('drag')
            el.current.style.position = 'fixed'
            el.current.style.top = top + "px"
            el.current.style.left = left + "px"

            el.current.style.width = width + 'px'
            el.current.style.height = height + 'px'

            if (!!dataSet.sheetId) {
                slide.current = dragTarget.parentElement as SwiperSlide
                slide.current.style.opacity = '0'
            }

            if (!dataSet.imgSrc)
                Array.from(el.current.children).forEach((child, index) => {
                    if (index > 1) child.remove()
                })

            document.body.appendChild(el.current)
            dragTarget.classList.add('drag-target')
        }
    }

    // Cancel dnd
    const cancelDnD = () => {
        isLongPressActive.current = false
        isPressed.current = false
        timer.current && clearTimeout(timer.current)

        dispatch(stopDrag())
        !isVisible && document.body.removeAttribute('class')
        el.current?.remove()
    }

    // Before long press callbacks ----------------------------------------------------
    const dndStart = useCallback((e: React.TouchEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>) => {
        if (isPressed.current) return

        isPressed.current = true

        if (isMobile)
            timer.current = setTimeout(() => {
                isLongPressActive.current = true
                start(e)
            }, longPressThreshold)
        else
            start(e)

    }, [])

    const dndMove = useCallback((e: React.TouchEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>) => {
        if (isPressed.current && !isLongPressActive.current && isMobile) cancelDnD()
    }, [])

    const dndCancel = useCallback((e: React.TouchEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>) => {
        if (!isPressed.current || isLongPressActive.current || !isMobile) return

        cancelDnD()

        let target = e.target as HTMLDivElement
        const dragTarget = target.closest(".draggable") as HTMLDivElement | null ?? target
        if (dragTarget.dataset.imgSrc && dragTarget.dataset.imgWidth && dragTarget.dataset.imgHeight)
            dispatch(addImageToSheet({
                url: dragTarget.dataset.imgSrc,
                width: +dragTarget.dataset.imgWidth,
                height: +dragTarget.dataset.imgHeight,
            }))
    }, [])

    // After long press callbacks -----------------------------------------------------
    const start = useCallback((e: React.TouchEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>) => {
        let touch
        if ('touches' in e)
            touch = e.touches[0]
        else
            touch = e

        xPrev = xStart = touch.clientX
        yPrev = yStart = touch.clientY

        // Create draggable element for mobile
        if (isMobile)
            registerDraggableElement(e.nativeEvent)

        if (isMobile) {
            window.addEventListener('touchmove', move, { passive: true })
            window.addEventListener('touchend', end, { passive: true })
        } else {
            window.addEventListener('pointermove', move, { passive: true })
            window.addEventListener('pointerup', end, { passive: true })
        }
    }, [])

    const move = useCallback((e: TouchEvent | PointerEvent) => {

        // Create draggable element for desktop
        if (!isMobile)
            registerDraggableElement(e)

        // Move created element
        if (!el.current) return

        let touch
        if ('touches' in e)
            touch = e.touches[0]
        else
            touch = e

        x = xPrev - touch.clientX
        y = yPrev - touch.clientY
        xPrev = touch.clientX
        yPrev = touch.clientY

        el.current.style.top = (el.current.offsetTop - y) + "px"
        el.current.style.left = (el.current.offsetLeft - x) + "px"

        // Show drop area
        document.querySelectorAll('.hovered').forEach(el => el.classList.remove('hovered'))
        document.elementsFromPoint(touch.clientX, touch.clientY).forEach(el => {

            if (el.tagName === 'rect') {
                el.classList.add('hovered')
                el.nextElementSibling?.firstElementChild?.classList.add('hovered')
            }

            if (el.classList.contains('apply-all') || el.classList.contains('drop-area'))
                el.classList.add('hovered')
        })
    }, []) as EventListener

    const end = useCallback((e: TouchEvent | PointerEvent) => {
        window.removeEventListener('touchmove', move)
        window.removeEventListener('touchend', end)
        window.removeEventListener('pointermove', move)
        window.removeEventListener('pointerup', end)

        dragIsActive.current = false
        isLongPressActive.current = false
        isPressed.current = false
        timer.current && clearTimeout(timer.current)

        document.querySelector('.drag-target')?.classList.remove('drag-target')
        document.querySelectorAll('.hovered').forEach(el => el.classList.remove('hovered'))

        let touch
        if ('touches' in e)
            touch = e.touches[0]
        else
            touch = e

        const xDeviation = Math.abs(touch.clientX - xStart)
        const yDeviation = Math.abs(touch.clientY - yStart)

        // Add image on click
        if (!isMobile && xDeviation < 2 && yDeviation < 2) {
            dispatch(stopDrag())
            !isVisible && document.body.removeAttribute('class')
            el.current?.remove()

            let target = e.target as HTMLDivElement

            // Stop propagation when clicking overlapping button
            if (target.tagName.includes('BUTTON')) return

            const dragTarget = target.closest(".draggable") as HTMLDivElement | null ?? target
            if (dragTarget.dataset.imgSrc && dragTarget.dataset.imgWidth && dragTarget.dataset.imgHeight)
                dispatch(addImageToSheet({
                    url: dragTarget.dataset.imgSrc,
                    width: +dragTarget.dataset.imgWidth,
                    height: +dragTarget.dataset.imgHeight,
                }))

            if (dragTarget.dataset.backgroundGroupId && dragTarget.dataset.backgroundId)
                dispatch(setBackground({
                    backgroundGroupId: dragTarget.dataset.backgroundGroupId,
                    backgroundId: dragTarget.dataset.backgroundId
                }))

            if (dragTarget.dataset.layoutId)
                dispatch(setTemplate({
                    templateId: +dragTarget.dataset.layoutId
                }))

            // Drop element
        } else if (el.current) {

            dispatch(setDropPosition({
                dropPosition: [
                    el.current.getBoundingClientRect().top + el.current.getBoundingClientRect().height / 2,
                    el.current.getBoundingClientRect().left + el.current.getBoundingClientRect().width / 2
                ],
                imgSrc: el.current.dataset.imgSrc,
                imgWidth: el.current.dataset.imgWidth,
                imgHeight: el.current.dataset.imgHeight,
                backgroundGroupId: el.current.dataset.backgroundGroupId,
                backgroundId: el.current.dataset.backgroundId,
                templateId: el.current.dataset.layoutId,
                templateTargetId: el.current.dataset.targetId,
            }))
        }

        slide.current?.style.removeProperty('opacity')
        slide.current = null
        el.current?.remove()
        el.current = null
    }, []) as EventListener

    useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])

    return isMobile
        ? {
            onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => dndStart(e),
            onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => dndCancel(e),
            onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => dndMove(e)
        } : {
            onPointerDown: (e: React.PointerEvent<HTMLDivElement>) => dndStart(e),
            onPointerUp: (e: React.PointerEvent<HTMLDivElement>) => dndCancel(e),
            onPointerMove: (e: React.PointerEvent<HTMLDivElement>) => dndMove(e)
        } as const
}