import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { store } from '..'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    opened: boolean
    text: string
    leadingIcon?: string | JSX.Element
    position?: 'top' | 'bottom'
    timeout?: number,
    actions?: {
        submit?: string
        submitAction: () => void
        cancel?: string
        cancelAction?: () => void
    }
} = {
    opened: false,
    text: '',
    position: 'bottom'
}

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {

        showSnackbarReducer: (state, action: PayloadAction<Omit<typeof initialState, 'opened'>>) => {
            state.opened = true
            state.text = action.payload.text
            state.leadingIcon = action.payload.leadingIcon
            state.actions = action.payload.actions
            state.position = action.payload.position
            state.timeout = action.payload.timeout
        },

        closeSnackbarReducer: (state) => {
            state.opened = false
            state.text = ''
            state.actions = state.leadingIcon = undefined
        }
    }
})

const { showSnackbarReducer, closeSnackbarReducer } = snackbarSlice.actions

const showSnackbar = (action: Omit<typeof initialState, 'opened'>) => store.dispatch(showSnackbarReducer(action))
const closeSnackbar = () => store.dispatch(closeSnackbarReducer())

export { showSnackbar, closeSnackbar }
export default snackbarSlice.reducer