import { Form, Formik, FormikHelpers } from "formik"
import { MdFormikField } from "../ui/MdFormikField"
import * as Yup from 'yup'
import { useAppDispatch } from "../../store"
import { useNavigate } from "react-router-dom"
import { logIn } from "../../store/reducers/authSlice"
import { FetchApiError } from "@russh/fetch-api"
import { CloseIcon, LockIcon, MailIcon } from "../ui/icons"

// --------------------------------------------------------------------------------

interface SignInProps {
    next: () => void
    prev: () => void
}

export const SignIn: React.FC<SignInProps> = ({ next, prev }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Не корректный email')
            .required('Введите электронную почту'),
        password: Yup.string()
            .required('Введите пароль')
    })

    const initialValues = {
        email: '',
        password: ''
    }

    const handleSubmit = async (values: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
        try {
            await dispatch(logIn(values)).unwrap()
            navigate(-1)
        } catch (e) {
            const err = e as FetchApiError
            if (err.fields) setErrors(err.fields)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <Form className="sign-in">
                <section>
                    <label>
                        Войти
                    </label>
                    <MdFormikField
                        name="email"
                        label="Электронная почта"
                        icon={<MailIcon />}
                    />
                    <MdFormikField
                        name="password"
                        type="password"
                        label="Пароль"
                        icon={<LockIcon />}
                    />
                </section>

                <div className="btns">

                    <md-filled-button type="submit">
                        Войти
                    </md-filled-button>

                    <md-text-button
                        type="button"
                        onClick={prev}
                    >
                        Восстановить пароль
                    </md-text-button>

                    <div className="sign-up">
                        <span>Нет аккаунта?</span>
                        <md-text-button
                            type="button"
                            onClick={next}
                        >
                            Зарегистрироваться
                        </md-text-button>
                    </div>
                </div>
                <md-icon-button
                    class="close"
                    onClick={() => navigate(-1)}
                >
                    <CloseIcon />
                </md-icon-button>
            </Form>
        </Formik>
    )
}