import { useEffect, useRef, useState } from "react"
import { templates } from "../../data"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { BookSpreadIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, ChevronLeftIcon, CloseIcon, FilterIcon, ImagesAndTextIcon, ImagesIcon, NoTemplatesFound, SheetIcon, TextIcon } from "./ui/icons"
import { BASE_URL } from "../utils/api"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../store"
import { setLayoutsFilter } from "../store/reducers/settingsSlice"
import { Template } from "../@types/templates"

// --------------------------------------------------------------------------------

const filters = [
    {
        value: '5',
        title: 'Изображения и текст',
        icon: <ImagesAndTextIcon />
    },
    {
        value: '7',
        title: 'Изображения',
        icon: <ImagesIcon />
    },
    {
        value: '9',
        title: 'Текст',
        icon: <TextIcon />
    }
]

const sheetFilters = [
    {
        value: 0,
        title: 'Все',
        icon: <FilterIcon />
    },
    {
        value: 1,
        title: 'Развороты',
        icon: <BookSpreadIcon />
    },
    {
        value: 2,
        title: 'Страницы',
        icon: <SheetIcon />
    }
]

export const Layouts: React.FC = () => {
    const { isVisible } = useAppSelector(state => state.bottomSheet)
    const { layoutsFilter } = useAppSelector(state => state.settings)
    const { album, activeItemId } = useAppSelector(state => state.album)
    const dndEvents = useDragAndDrop()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const ref = useRef<HTMLDivElement>(null)

    const [filter, setFilter] = useState(layoutsFilter)
    const [sheetFilter, setSheetFilter] = useState<number>(0)
    const [show, setShow] = useState(false)
    const [activeTemplates, setActiveTemplates] = useState<Template[]>([])

    const handleSubmit = () => {
        dispatch(setLayoutsFilter(filter))
        setShow(false)
    }

    const handleReset = () => {
        dispatch(setLayoutsFilter('0'))
        setFilter('0')
        setShow(false)
    }

    // Filter templates by type
    useEffect(() => {
        if (!album || !activeItemId) return

        const activeItemTargetId = album.items[activeItemId].params.target_id
        setActiveTemplates(Object.values(templates).filter(tmpl => tmpl.params.target_id === activeItemTargetId))

    }, [album, activeItemId])

    useEffect(() => {
        let scrollTo = 200 - 94
        if (window.visualViewport)
            scrollTo = Math.round(window.visualViewport.height / 2 - 94)

        if (ref.current && isVisible) {
            ref.current.scrollTo(0, scrollTo)
            ref.current.classList.remove('no-scroll')
        }
    }, [isVisible])

    const filteredTemplates = Object.values(activeTemplates)
        .filter(el => layoutsFilter === '0' || el.params.group_id === layoutsFilter)

    return (
        <>
            <div className="bottom-sheet-layouts" ref={ref}>
                <div className="container">
                    {filteredTemplates.length > 0
                        ?
                        filteredTemplates.map(el =>
                            <div
                                key={`bs-layout-${el.params.id}`}
                                className="draggable layout-element"
                                data-layout-id={el.params.id}
                                data-target-id={el.params.target_id}
                                {...dndEvents}
                            >
                                <img src={`${BASE_URL}/templates/${el.params.icon}`} />
                                <div className="overlay" />
                            </div>
                        )
                        :
                        <div className={`no-templates-found`}>
                            <NoTemplatesFound />
                            Нет шаблонов.
                            <md-text-button onClick={handleReset}>
                                Сбросить фильтр
                            </md-text-button>
                        </div>}
                </div>
            </div>

            <div className="title">
                <md-icon-button
                    onClick={() => navigate(-1)}>
                    <ChevronLeftIcon />
                </md-icon-button>
                Шаблоны
            </div>

            <div className={`bottom-sheet-layouts-filter ${show ? 'open' : ''}`}>
                <md-elevation />
                <div
                    className={`active`}
                    onClick={() => !show && setShow(true)}
                >

                    {show
                        ?
                        <>
                            <div className="btn" onClick={handleSubmit}>
                                <CheckIcon />
                                Применить
                            </div>
                            <div className="btn" onClick={handleReset}>
                                <CloseIcon />
                                Сбросить
                            </div>
                        </>
                        :
                        <div className="btn">
                            <div className="action-button-icon">
                                <FilterIcon />
                                {layoutsFilter !== '0' &&
                                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none">
                                        <circle cx="5.5" cy="5" r="4.5" />
                                    </svg>}
                            </div>
                            Фильтр
                        </div>}

                </div>

                <div className={`all-actions`}>
                    <div className={`type`}>
                        {sheetFilters.map(f =>
                            <div
                                key={`sheet-filter-${f.value}`}
                                className={sheetFilter === f.value ? `selected` : undefined}
                                onClick={() => setSheetFilter(f.value)}
                            >
                                {f.icon}
                                {f.title}
                            </div>)}
                    </div>
                    {filters.map(filtrBtn =>

                        <div
                            key={`bs-filter-${filtrBtn.value}`}
                            onClick={() => setFilter(filtrBtn.value)}
                        >
                            <md-ripple />
                            <div>
                                {filtrBtn.icon}
                                {filtrBtn.title}
                            </div>
                            {filter === filtrBtn.value &&
                                <CheckCircleFillIcon />}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Layouts