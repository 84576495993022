import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { sortItems } from '../../utils/sort'
// @types
import { Album, Item, ItemComponent, TemplateComponent } from '../../@types/album'
import { album } from '../../../mainData'
import { templates } from '../../../data'
import { GalleryImage } from '../../@types/gallery'

// --------------------------------------------------------------------------------

const localData = localStorage.getItem('album')
const localAlbum = localData ? JSON.parse(localData) as Album : undefined

const initialState: {
    album?: Album
    activeItemId: string
    imagesUsed: { [img: string]: number }
    previewMode: boolean
    imageErrors: string[]
    textErrors: string[]
    sheetIsFull: boolean
    imgToAdd?: Omit<GalleryImage, "id">
    isEditable?: boolean
} = {
    album: localAlbum ?? album,
    activeItemId: '',
    imagesUsed: {},
    previewMode: false,
    imageErrors: [],
    textErrors: [],
    sheetIsFull: false
}

const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {

        setAlbum: (state, action: PayloadAction<typeof initialState['album']>) => {
            state.album = action.payload
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        resetAlbum: (state) => {
            state.album = album
            localStorage.removeItem('album')
        },

        setIsEditable: (state, action: PayloadAction<boolean>) => {
            state.isEditable = action.payload
            state.previewMode = !action.payload
        },

        setImagesUsed: (state) => {
            if (state.album) {
                state.imagesUsed = {}

                Object.values(state.album.items).forEach(item => {
                    item.components.forEach(component => {
                        const imageUsed = !state.imagesUsed[component.value]
                            ? { [component.value]: 1 }
                            : { [component.value]: state.imagesUsed[component.value] + 1 }

                        state.imagesUsed = { ...state.imagesUsed, ...imageUsed }
                    })
                })
            }
        },

        setActiveItemId: (state, action: PayloadAction<string>) => {
            state.activeItemId = action.payload
        },

        removeActiveItem: (state) => {
            if (state.album && Object.values(state.album.items).length > 1) {
                delete state.album.items[state.activeItemId]

                Object.values(state.album.items).sort(sortItems).forEach((item, index) => {
                    item.params.sort = `${index + 1}`
                })

                localStorage.setItem('album', JSON.stringify(state.album))
            }
        },

        addAlbumItem: (state, action: PayloadAction<Item>) => {
            if (state.album) {

                Object.values(state.album.items).sort(sortItems).forEach(item => {
                    if (+item.params.sort >= +action.payload.params.sort)
                        item.params.sort = `${+item.params.sort + 1}`
                })

                state.album.items = { ...state.album.items, [action.payload.params.id]: action.payload }

                localStorage.setItem('album', JSON.stringify(state.album))
            }
        },

        setItemSortOrder: (state, action: PayloadAction<{ id: string, sort: string }[]>) => {
            action.payload.map(payload => {
                if (state.album) state.album.items[payload.id].params.sort = payload.sort
            })
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setAlbumTitle: (state, action: PayloadAction<string>) => {
            if (state.album) state.album.params.name = action.payload
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setTemplate: (state, action: PayloadAction<{ itemId?: string, templateId: keyof typeof templates }>) => {
            const itemId = action.payload.itemId ?? state.activeItemId
            if (state.album) {
                const item = state.album.items[itemId]
                item.template = templates[action.payload.templateId]
                item.components = []
            }

            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setAllTemplates: (state, action: PayloadAction<{ templateId: keyof typeof templates, templateTargetId?: string }>) => {
            if (state.album)
                Object.values(state.album.items).forEach(item => {
                    if (item.params.target_id === action.payload.templateTargetId) {
                        item.template = templates[action.payload.templateId]
                        item.components = []
                    }
                })

            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setBackground: (state, action: PayloadAction<{ itemId?: string, backgroundGroupId: string, backgroundId: string }>) => {
            const itemId = action.payload.itemId ?? state.activeItemId
            if (state.album)
                state.album.items[itemId].color =
                    state.album.bgColors[action.payload.backgroundGroupId].filter(el => el.id === action.payload.backgroundId)[0]
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setAllBackgrounds: (state, action: PayloadAction<{ backgroundGroupId: string, backgroundId: string }>) => {
            if (state.album)
                Object.values(state.album.items).forEach(item => {
                    if (state.album)
                        item.color = state.album.bgColors[action.payload.backgroundGroupId].filter(el => el.id === action.payload.backgroundId)[0]
                })

            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setImage: (state, action: PayloadAction<{ itemId: string, component: ItemComponent }>) => {
            const currentComponents = state.album?.items[action.payload.itemId].components
            const filteredComponents = currentComponents?.filter(c => c.template_component_id !== action.payload.component.template_component_id)
            if (state.album)
                state.album.items[action.payload.itemId].components = filteredComponents
                    ? [...filteredComponents, action.payload.component]
                    : [action.payload.component]
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setStyles: (state, action: PayloadAction<{ itemId: string, componentId: string, style: string }>) => {
            if (state.album) {
                const componentIndex = state.album.items[action.payload.itemId].components.findIndex(c => c.template_component_id === action.payload.componentId)
                if (componentIndex >= 0)
                    state.album.items[action.payload.itemId].components[componentIndex].style = action.payload.style
            }
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setText: (state, action: PayloadAction<{ itemId: string, component: ItemComponent }>) => {
            const currentComponents = state.album?.items[action.payload.itemId].components
            const filteredComponents = currentComponents?.filter(c => c.template_component_id !== action.payload.component.template_component_id)
            if (state.album)
                state.album.items[action.payload.itemId].components = filteredComponents
                    ? [...filteredComponents, action.payload.component]
                    : [action.payload.component]
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        removeText: (state, action: PayloadAction<{ itemId: string, templateCompId: string }>) => {
            if (state.album) {
                const item = state.album?.items[action.payload.itemId]
                item.components = item.components.filter(comp => comp.template_component_id !== action.payload.templateCompId)
            }
            localStorage.setItem('album', JSON.stringify(state.album))
        },

        addImageToSheet: (state, action: PayloadAction<Omit<GalleryImage, 'id'>>) => {
            if (state.album) {
                const item = state.album.items[state.activeItemId]
                const occupiedTemplateComponents = item.components.map(ic => ic.template_component_id)
                const emptyComponent = item.template.components.find(tc => !occupiedTemplateComponents.includes(tc.id) && tc.component_id !== '1')

                if (emptyComponent) {
                    item.components.push({
                        id: "",
                        system_id: "",
                        project_item_id: item.params.id,
                        template_component_id: emptyComponent.id,
                        value: action.payload.url,
                        style: JSON.stringify({
                            imgWidth: action.payload.width,
                            imgHeight: action.payload.height,
                        }),
                        has_error: "",
                    })
                } else {
                    state.sheetIsFull = true
                    state.imgToAdd = action.payload
                }
            }

            localStorage.setItem('album', JSON.stringify(state.album))
        },

        setSheetIsFull: (state, action: PayloadAction<boolean>) => {
            state.sheetIsFull = action.payload
        },

        autofillImages: (state, action: PayloadAction<GalleryImage[]>) => {
            let imgIndex = 0
            if (state.album)
                Object.values(state.album.items)
                    .sort((a, b) => {
                        if (+a.params.sort > +b.params.sort) return 1
                        if (+a.params.sort < +b.params.sort) return -1
                        return 0
                    })
                    .forEach(item => {
                        let c: ItemComponent[] = []
                        item.template.components.forEach(component => {
                            if (imgIndex === action.payload.length || component.component_id === '1') return
                            c.push({
                                id: "",
                                system_id: "",
                                project_item_id: item.params.id,
                                template_component_id: component.id,
                                value: action.payload[imgIndex].url,
                                style: JSON.stringify({
                                    imgWidth: action.payload[imgIndex].width,
                                    imgHeight: action.payload[imgIndex].height,
                                }),
                                has_error: "",
                            })
                            imgIndex++
                        })
                        item.components = c
                    })

            localStorage.setItem('album', JSON.stringify(state.album))
        },

        removeImageByUrl: (state, action: PayloadAction<string[]>) => {
            if (state.album)
                Object.values(state.album.items).forEach(item => {
                    let i = 0;
                    while (i < item.components.length) {
                        if (action.payload.includes(item.components[i].value)) {
                            item.components.splice(i, 1)
                        } else {
                            ++i
                        }
                    }
                })
        },

        enablePreviewMode: (state) => {
            state.previewMode = true
        },

        disablePriviewMode: (state) => {
            state.previewMode = false
        },

        addImageError: (state, action: PayloadAction<string>) => {
            state.imageErrors.push(action.payload)
        },

        removeImageError: (state, action: PayloadAction<string>) => {
            state.imageErrors = state.imageErrors.filter(err => err !== action.payload)
        },

        addTextError: (state, action: PayloadAction<string>) => {
            state.textErrors.push(action.payload)
        },

        removeTextError: (state, action: PayloadAction<string>) => {
            state.textErrors = state.textErrors.filter(err => err !== action.payload)
        },
    }
})

export const {
    setAlbum,
    resetAlbum,
    setImagesUsed,
    setActiveItemId,
    removeActiveItem,
    addAlbumItem,
    setItemSortOrder,
    setAlbumTitle,
    setTemplate,
    setAllTemplates,
    setBackground,
    setAllBackgrounds,
    setImage,
    setText,
    removeText,
    setStyles,
    addImageToSheet,
    autofillImages,
    removeImageByUrl,
    enablePreviewMode,
    disablePriviewMode,
    addImageError,
    addTextError,
    removeImageError,
    removeTextError,
    setSheetIsFull,
    setIsEditable
} = albumSlice.actions

export default albumSlice.reducer