import FetchApi from "@russh/fetch-api"

// --------------------------------------------------------------------------------

// export const BASE_URL: string = 'http://192.168.190.9:5555'
export const BASE_URL: string = 'https://mobile-constructor.wow2print.com'

export enum ApiRoutes {
    ITEMS = '',
    SIGNUP = '/auth/signup',
    SIGNUP_VERIFY = '/auth/signup/verify',
    LOGIN = '/auth/login',
    LOGOUT = '/auth/logout',
    REFRESH_TOKEN = '/auth/refresh',
    GET_AUTH_USER = '/auth/user',
    USERS = '/users',
}

export const api = new FetchApi(BASE_URL, ApiRoutes.REFRESH_TOKEN, { convertToFormData: true })
export default api