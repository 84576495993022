import { useCallback, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { MdTabs } from "@material/web/tabs/tabs"
import { setActive } from "../store/reducers/toolbarSlice"
import { btns } from "./Toolbar"
import { ImageTile } from "./ui/ImageTile"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { deselectFolder, selectFolder, setHideUsed } from "../store/reducers/gallerySlice"
import { Template } from "../@types/templates"
import { BASE_URL } from "../utils/api"
import { templates } from "../../data"
import { ChevronLeftIcon, ExpandLessIcon, ExpandMoreIcon } from "./ui/icons"
import { autofillImages } from "../store/reducers/albumSlice"
import { MdCheckbox } from "@material/web/checkbox/checkbox"
import { MdOutlinedSelect } from "@material/web/select/outlined-select"
import { setLayoutsFilter } from "../store/reducers/settingsSlice"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"

// --------------------------------------------------------------------------------

const filters = [
    {
        value: '0',
        title: 'Все'
    },
    {
        value: '5',
        title: 'Изображения и текст'
    },
    {
        value: '7',
        title: 'Изображения'
    },
    {
        value: '9',
        title: 'Текст'
    }
]

export const DesktopToolbar: React.FC = () => {
    const dispatch = useAppDispatch()
    const { active } = useAppSelector(state => state.toolbar)
    const { layoutsFilter } = useAppSelector(state => state.settings)
    const { gallery, folders, selectedFolder, hideUsed } = useAppSelector(state => state.gallery)
    const { album, activeItemId } = useAppSelector(state => state.album)
    const dndEvents = useDragAndDrop()

    const tabsRef = useRef<MdTabs>(null)
    const selectedCheckboxRef = useRef<MdCheckbox>(null)
    const templatesFilterSelect = useRef<MdOutlinedSelect>(null)

    const [activeTemplates, setActiveTemplates] = useState<Template[]>([])
    const [isExpanded, setIsExpanded] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(true)

    // Register MdTabs listener
    useEffect(() => {
        if (!active)
            dispatch(setActive('gallery'))

        if (tabsRef.current)
            tabsRef.current.addEventListener('change', (e: Event) => {
                switch ((e.target as MdTabs).activeTabIndex) {
                    case 0:
                        dispatch(setActive('gallery'))
                        break

                    case 1:
                        dispatch(setActive('template'))
                        break

                    case 2:
                        dispatch(setActive('background'))
                        break
                }

            })
    }, [])

    useEffect(() => {
        if (selectedCheckboxRef.current) {
            const handler = () => {
                if (selectedCheckboxRef.current?.checked)
                    dispatch(setHideUsed(true))
                else
                    dispatch(setHideUsed(false))
            }

            selectedCheckboxRef.current.addEventListener("change", handler, { passive: true })
        }
    }, [selectedCheckboxRef.current])

    // Filter templates by type
    useEffect(() => {
        if (!album || !activeItemId) return

        const activeItemTargetId = album.items[activeItemId]?.params.target_id
        setActiveTemplates(Object.values(templates).filter(tmpl => tmpl.params.target_id === activeItemTargetId))

    }, [album, activeItemId])

    const selectHandler = useCallback(() =>
        dispatch(setLayoutsFilter(templatesFilterSelect.current?.value || '0')), [])

    useEffect(() => {

        const handler = (e: Event) => {
            const target = e.target as MdOutlinedSelect
            dispatch(setLayoutsFilter(target.value))
        }


        if (templatesFilterSelect.current && active === 'template') {
            setTimeout(() => templatesFilterSelect.current?.select(layoutsFilter))
            templatesFilterSelect.current.addEventListener('change', handler, { passive: true })
        } else
            templatesFilterSelect.current?.removeEventListener('change', handler)

    }, [active])

    useEffect(() => {
        document.querySelector('.main-container')?.classList.toggle('expanded', isCollapsed)
    }, [isCollapsed])

    const filteredTemplates = Object.values(activeTemplates)
        .filter(el => layoutsFilter === '0' || el.params.group_id === layoutsFilter)

    return (
        <div className={`desktop-toolbar ${isExpanded ? 'expanded' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="desktop-toolbar-header">
                <md-tabs ref={tabsRef}>
                    {btns.map(btn =>
                        <md-primary-tab inline-icon key={`toolbar_tab_${btn.type}`}>{btn.icon}{btn.name}</md-primary-tab>)}
                </md-tabs>
                <md-text-button
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    trailing-icon
                >
                    {isCollapsed
                        ? <><ExpandLessIcon slotted />Показать</>
                        : <><ExpandMoreIcon slotted />Спрятать</>}
                </md-text-button>
            </div>

            {active === 'gallery' &&
                <div className="desktop-toolbar-gallery">
                    <div>
                        {selectedFolder
                            ?
                            <div>
                                <md-filled-tonal-button
                                    onClick={() => dispatch(deselectFolder())}
                                >
                                    <ChevronLeftIcon slotted />
                                    {selectedFolder.name}
                                </md-filled-tonal-button>
                                <md-filled-tonal-button
                                    onClick={() => dispatch(autofillImages(selectedFolder.images))}
                                >
                                    Автозаполнение
                                </md-filled-tonal-button>
                                <md-filled-tonal-button>Очистить</md-filled-tonal-button>
                                <label>
                                    <md-checkbox touch-target="wrapper" ref={selectedCheckboxRef} />
                                    Скрывать выбранные
                                </label>
                            </div>
                            :
                            <div>
                                <md-filled-tonal-button>Очистить</md-filled-tonal-button>
                            </div>}

                        <md-filled-tonal-button
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? 'Свернуть' : 'Развернуть'}
                        </md-filled-tonal-button>
                    </div>
                    <div>
                        <div
                            className="add-photo"
                            onClick={() => dispatch(setBottomSheetData({ upload: true }))}
                        >
                            Добавить фотографию
                        </div>
                        {gallery && gallery.map((image, index) =>
                            <ImageTile
                                el={image}
                                events={dndEvents}
                                hideUsed={hideUsed}
                                index={index === 1 ? index : undefined}
                                key={`toolbar-panel-gallery-img-${image.url.slice(-10)}`}
                                lazy
                            />)}

                        {folders && !selectedFolder && folders.map(folder =>
                            <div
                                className="desktop-gallery-folder"
                                onClick={() => dispatch(selectFolder(folder))}
                                key={`toolbar-panel-gallery-folder-${folder.id}`}
                            >
                                <img src={`${BASE_URL}/upload/3/256/${folder.images[0].url}`} />
                                <div className="image-overlay "><span>{folder.name}</span></div>
                            </div>)}

                        {selectedFolder && selectedFolder.images.map((image, index) =>
                            <ImageTile
                                el={image}
                                events={dndEvents}
                                hideUsed={hideUsed}
                                index={index === 1 ? index : undefined}
                                key={`toolbar-panel-gallery-img-${image.url.slice(-10) + index}`}
                                lazy
                            />)}
                    </div>
                </div>}

            {active === 'template' &&
                <div className="desktop-toolbar-template">
                    <div>
                        <md-outlined-select ref={templatesFilterSelect}>
                            {filters.map(f =>
                                <md-select-option
                                    key={`templates-filter-${f.value}`}
                                    value={`${f.value}`}
                                >
                                    <div slot="headline">{f.title}</div>
                                </md-select-option>)}
                        </md-outlined-select>

                        <md-filled-tonal-button
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? 'Свернуть' : 'Развернуть'}
                        </md-filled-tonal-button>
                    </div>
                    <div>
                        {filteredTemplates.length > 0
                            ?
                            filteredTemplates.map(el =>
                                <div
                                    className={`layout-element draggable ${album?.items[activeItemId].template.params.id === el.params.id ? 'current' : ''}`}
                                    data-layout-id={el.params.id}
                                    data-target-id={el.params.target_id}
                                    style={{ aspectRatio: `${album?.product.width ?? 1}/${album?.product.height ?? 1}` }}
                                    key={`toolbar-panel-layout-${el.params.id}`}
                                    {...dndEvents}
                                >
                                    <img src={`${BASE_URL}/templates/${el.params.icon}`} />
                                    <div className="template-name">{el.params.name}</div>
                                </div>)
                            :
                            <div className='nothing-found'>Нет шаблонов</div>}
                    </div>
                </div>}


            {active === 'background' &&
                <div className="desktop-toolbar-background">
                    <div>
                        <div />
                        <md-filled-tonal-button
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? 'Свернуть' : 'Развернуть'}
                        </md-filled-tonal-button>
                    </div>
                    <div>
                        {album && Object.entries(album.bgColors).map(([id, row]) =>
                            <div key={`toolbar-panel-background-row-${id}`}>
                                {row.map(el =>
                                    <div
                                        key={`color-box-${el.id}`}
                                        className={`color-box ${album?.items[activeItemId]?.color?.id === el.id ? 'current' : ''}`}
                                        style={{ backgroundColor: `rgb(${el.rgb})` }}
                                        data-background-group-id={el.group_id}
                                        data-background-id={el.id}
                                        {...dndEvents}
                                    />)}
                            </div>
                        )}
                    </div>
                </div>}

        </div>
    )
} 