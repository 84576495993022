import { useAppDispatch, useAppSelector } from "../store"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../utils/api"
import Cropper from 'cropperjs'
import { addImageError, addTextError, removeImageError, removeTextError, setImage } from "../store/reducers/albumSlice"
import { enableTextEditMode, setTextEditor } from "../store/reducers/textEditorSlice"
import { resetDropPosition } from "../store/reducers/dragAndDropSlice"
// @types
import { ItemComponent, ItemComponentStyle, TemplateComponent } from "../@types/album"
import { FolderListChecked, FolderListUnchecked, ImageInfoIcon, ImageWarningIcon, TextIcon, TextInfoIcon, WarningIcon } from "./ui/icons"
import { ModalBottomSheet } from "./ui/ModalBottomSheet"
import { showSnackbar } from "../store/reducers/snackbarSlice"
import { setImageEditor } from "../store/reducers/imageEditorSlice"

// --------------------------------------------------------------------------------

interface Intersecting {
    template: TemplateComponent,
    component?: ItemComponent
}

interface SheetComponentProps {
    itemId: string
    slideIndex: number
    templateComponent: TemplateComponent
    itemComponent?: ItemComponent
    navPanel?: boolean
}

export const SheetComponent: React.FC<SheetComponentProps> = ({ itemId, templateComponent, itemComponent, navPanel }) => {
    const { dropPosition, imgSrc, imgWidth, imgHeight, dragComponent } = useAppSelector(state => state.dnd)
    const { textEditMode, itemId: editingItemId } = useAppSelector(state => state.textEditor)
    const { album, previewMode } = useAppSelector(state => state.album)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const rectRef = useRef<SVGRectElement>(null)
    const imageRef = useRef<SVGImageElement>(null)
    const textSpanRef = useRef<HTMLSpanElement>(null)
    const intersectingArr = useRef<Intersecting[]>([])

    const [style, setStyle] = useState<ItemComponentStyle | null>()
    const [preview, setPreview] = useState<string>()
    const [text, setText] = useState('')
    const [ratio, setRatio] = useState(1)
    const [isLongText, setIsLongText] = useState(false)
    const [dotDensity, setDotDensity] = useState<number>()

    const [modalIsVisible, setModalIsVisible] = useState(false)

    const isMobile = 'ontouchstart' in window

    const isTextField = templateComponent.component_id === '1'
    const { x, y, width, height } = templateComponent

    // Edit component
    const editComponent = ({ template, component }: Intersecting) => {
        if (template.component_id === '1') {

            dispatch(enableTextEditMode({
                itemId,
                templateComponent: template
            }))
            navigate(`edit_${template.id}`)
        } else if (template.component_id === '3' && component) {
            dispatch(setImageEditor({
                itemId,
                templateComponent: template
            }))
            navigate(`edit_${template.id}`)
        } else if (template.component_id === '3') {
            showSnackbar({
                text: 'Нет изображения.',
                leadingIcon: <WarningIcon />,
                position: 'top'
            })
        }
    }

    // Handle click on component
    const handleClick = (e: React.MouseEvent) => {
        if (textEditMode || previewMode || !album || navPanel) return
        e.stopPropagation()

        const itemComponents = album.items[itemId].components

        intersectingArr.current = album.items[itemId].template.components
            .filter(tmpl => {
                const component = itemComponents.find(comp => comp.template_component_id === tmpl.id)
                if (tmpl.component_id === '3' && !component) return false

                const compTop = +tmpl.y
                const compBottom = +tmpl.y + +tmpl.height
                const compLeft = +tmpl.x
                const compRight = +tmpl.x + +tmpl.width

                const top = +y
                const bottom = +y + +height
                const left = +x
                const right = +x + +width

                if (tmpl === templateComponent) return true

                else if (
                    compBottom > top
                    && compRight > left
                    && compTop < bottom
                    && compLeft < right
                ) return true

                else return false
            })
            .map(tmpl => ({
                template: tmpl,
                component: itemComponents.find(comp => comp.template_component_id === tmpl.id)
            }))

        if (intersectingArr.current.length === 1 && intersectingArr.current[0]) {
            editComponent(intersectingArr.current[0])
            intersectingArr.current = []
        } else if (intersectingArr.current.length > 1) {
            setModalIsVisible(true)
        }
    }

    // Set component aspect ratio
    useEffect(() => {
        if (rectRef.current) {
            const realRatio = rectRef.current.getBoundingClientRect().width / +templateComponent.width
            setRatio(Number(realRatio.toFixed(2)))
        }
    }, [])

    // Set text editor rect position
    useEffect(() => {
        if (textEditMode && isTextField && editingItemId === itemId)
            setTimeout(() =>
                dispatch(setTextEditor({
                    position: rectRef.current?.getBoundingClientRect()
                })))
    }, [textEditMode])

    // Handle drop image on component
    useEffect(() => {
        const activeRef = preview ? imageRef.current : rectRef.current
        if (navPanel || isTextField || !activeRef || dropPosition.length === 0) return

        const { top, bottom, left, right } = activeRef.getBoundingClientRect()
        const [y, x] = dropPosition

        const miniPanelTop = document.getElementById('mini-panel')?.getBoundingClientRect().top ?? window.innerHeight - 110
        if (y > miniPanelTop) return

        if (top < y && y < bottom && left < x && x < right && imgSrc) {

            // Filter top lvl component
            const elements = document.elementsFromPoint(x, y)
                .map(el => {
                    if (el.tagName === 'image' || el.tagName === 'rect')
                        return el as SVGImageElement | SVGRectElement

                })
                .filter(el => !!el)

            if (elements[0]?.dataset.templateId !== templateComponent.id) return

            // Set image
            dispatch(setImage({
                itemId,
                component: {
                    id: "",
                    system_id: "",
                    project_item_id: itemId,
                    template_component_id: templateComponent.id,
                    value: imgSrc,
                    style: JSON.stringify({ imgWidth, imgHeight }),
                    has_error: "",
                }
            }))
            dispatch(resetDropPosition())
        }

    }, [dropPosition])

    // Set filter
    useEffect(() => {
        setDotDensity(undefined)
        setPreview(undefined)
        setText('')
        setIsLongText(false)

        if (itemComponent && isTextField)
            setText(itemComponent.value)

        else if (itemComponent) try {
            const styles = JSON.parse(itemComponent.style) as ItemComponentStyle
            setStyle(styles)
        } catch {
            setStyle(null)
        }
    }, [itemComponent])

    // Check text size
    useEffect(() => {
        if (textSpanRef.current && rectRef.current) {
            const { width: spanWidth, height: spanHeight } = textSpanRef.current.getBoundingClientRect()
            const { width: rectWidth, height: rectHeight } = rectRef.current.getBoundingClientRect()

            if (spanWidth > rectWidth || spanHeight > rectHeight)
                setIsLongText(true)
            else
                setIsLongText(false)
        }
    }, [text, itemComponent])

    // Add text error
    useEffect(() => {
        if (isLongText)
            dispatch(addTextError(itemId + templateComponent.id))
        else
            dispatch(removeTextError(itemId + templateComponent.id))
    }, [isLongText])

    // Set preview
    useEffect(() => {
        if (!itemComponent || !rectRef.current || style === undefined || isTextField) return

        const { width, height } = rectRef.current.getBoundingClientRect()
        const compMaxSize = width >= height ? { width } : { height }

        const imgScale = style?.cropperBox && style?.cropperCanvas
            ? style.cropperBox.width / style.cropperCanvas.width
            : 1

        const imgSize = compMaxSize.width ? width / imgScale : height / imgScale

        let size: 256 | 512 | 1024 | 2048 = 256

        switch (true) {
            case imgSize >= 1024: size = 2048; break;
            case imgSize >= 512: size = 1024; break;
            case imgSize >= 256: size = 512; break;
            case imgSize < 256: size = 256; break;
        }

        // Offscreen rendering
        const imageUrl = `${BASE_URL}/upload/3/${size}/${itemComponent.value}`
        // const canvas = document.createElement('canvas')
        // const offscreen = canvas.transferControlToOffscreen()

        // const worker = new Worker("imagePreview.js");
        // worker.postMessage({ canvas: offscreen, url: imageUrl, width, height }, [offscreen])
        // worker.onmessage = (e) => {
        //     const dataUrl = e.data
        //     dataUrl && setPreview(dataUrl)
        // }

        fetch(imageUrl)
            .then(res => res.blob())
            .then(blob => createImageBitmap(blob))
            .then(bitmap => {

                // Rotate
                if (style?.angle) {
                    const bWidth = +style.angle % 180 === 0 ? bitmap.width : bitmap.height
                    const bHeight = +style.angle % 180 === 0 ? bitmap.height : bitmap.width

                    const canvasForRotating = new OffscreenCanvas(bWidth, bHeight)
                    const context = canvasForRotating.getContext('2d')

                    const widthCenter = bWidth / 2
                    const heightCenter = bHeight / 2

                    context?.translate(widthCenter, heightCenter)
                    context?.rotate(+style.angle * Math.PI / 180)
                    context?.translate(-widthCenter, -heightCenter)
                    context?.drawImage(bitmap, 0, 0)

                    // canvasForRotating.convertToBlob().then(blob =>
                    //     window.open(URL.createObjectURL(blob), '_blank'))

                    return canvasForRotating.transferToImageBitmap()
                } else
                    return bitmap
            })
            .then(bitmap => {

                const offscreen = new OffscreenCanvas(width, height)

                let bitmapWidth = 0,
                    bitmapHeight = 0,
                    offsetX = 0,
                    offsetY = 0

                if (style?.cropperBox && style?.cropperCanvas) {
                    const boxRatio = width / style.cropperBox.width

                    bitmapWidth = style.cropperCanvas.width * boxRatio
                    bitmapHeight = style.cropperCanvas.height * boxRatio
                    offsetY = style.cropperCanvas.top * boxRatio
                    offsetX = style.cropperCanvas.left * boxRatio

                } else {
                    const canvasAspectRatio = +(width / height).toFixed(3)
                    const bitmapAspectRatio = +(bitmap.width / bitmap.height).toFixed(3)

                    if (bitmapAspectRatio >= canvasAspectRatio) {
                        bitmapWidth = height * bitmapAspectRatio
                        bitmapHeight = height
                        offsetX = -(bitmapWidth - width) / 2
                    } else {
                        bitmapWidth = width
                        bitmapHeight = width / bitmapAspectRatio
                        offsetY = -(bitmapHeight - height) / 2
                    }
                }
                console.log(style)

                const context = offscreen.getContext("2d")
                context?.drawImage(
                    bitmap,                     // image
                    offsetX,                    // dx
                    offsetY,                    // dy
                    Math.round(bitmapWidth),    // dWidth
                    Math.round(bitmapHeight)    // dHeight
                )

                return offscreen.convertToBlob()
            })
            .then(resultBlob => {
                const url = resultBlob && URL.createObjectURL(resultBlob)
                url && setPreview(url)
            })

        // const img = new Image()
        // img.src = `${BASE_URL}/upload/3/${size}/${itemComponent.value}`
        // img.crossOrigin = "anonymous"

        // img.onload = () => {
        //     const canvas = document.createElement('canvas')
        //     canvas.width = img.naturalWidth
        //     canvas.height = img.naturalHeight

        //     const ctx = canvas.getContext("2d")
        //     ctx && ctx.drawImage(img, 0, 0)

        //     const container = document.createElement('div')
        //     container.style.width = width + 'px'
        //     container.style.height = height + 'px'
        //     container.appendChild(canvas)
        //     document.body.appendChild(container)

        //     new Cropper(canvas, {
        //         viewMode: !style?.cropperCanvas ? 3 : undefined,
        //         aspectRatio: width / height,
        //         minContainerHeight: 0,
        //         minContainerWidth: 0,
        //         autoCropArea: 1,
        //         ready(e) {
        //             const cropper = e.currentTarget.cropper

        //             if (style?.cropperBox && style?.cropperCanvas) {
        //                 const boxRatio = width / style.cropperBox.width

        //                 cropper.rotateTo(+style.angle)
        //                 cropper.setCropBoxData({ width, height, top: 0, left: 0 })
        //                 cropper.setCanvasData({
        //                     width: style.cropperCanvas.width * boxRatio,
        //                     height: style.cropperCanvas.height * boxRatio,
        //                     top: style.cropperCanvas.top * boxRatio,
        //                     left: style.cropperCanvas.left * boxRatio,
        //                 })
        //             }

        //             if (style?.imgWidth) {
        //                 const { width: imageDataWidth } = cropper.getImageData()
        //                 const zoom = imageDataWidth / width
        //                 const widthInInches = +templateComponent.width / 25.4
        //                 setDotDensity(+style.imgWidth / zoom / widthInInches)
        //             }

        //             cropper.getCroppedCanvas({
        //                 width,
        //                 height
        //             }).toBlob(blob => {
        //                 const url = blob && URL.createObjectURL(blob)
        //                 url && setPreview(url)
        //                 setTimeout(() => document.body.removeChild(container))
        //             })
        //         }
        //     })
        // }

    }, [style])

    // Add image error
    useEffect(() => {
        if (dotDensity && dotDensity < 240)
            dispatch(addImageError(itemId + templateComponent.id))
        else
            dispatch(removeImageError(itemId + templateComponent.id))
    }, [dotDensity])

    // Set text style
    let cssObj: React.CSSProperties | undefined
    let fontSize: string | number | undefined
    if (isTextField && itemComponent) {
        try {
            cssObj = JSON.parse(itemComponent.style)
            fontSize = cssObj?.fontSize
            if (typeof fontSize === 'string') {
                fontSize = Number(fontSize.replace('pt', ''))

                if (typeof fontSize === 'number')
                    fontSize = Number((fontSize * 0.35).toFixed(2))

            } else {
                fontSize = 4.2
            }
            if (cssObj)
                delete cssObj['fontSize']
        } catch (e) {
            console.log(e)
        }
    }

    const bwFilter = style?.colorFilter === 'bw-filter'

    const position = { x, y, height, width }
    const dropAreaPosition = {
        x: (+x + 4 * ratio).toFixed(),
        y: (+y + 4 * ratio).toFixed(),
        width: (+width - 8 * ratio).toFixed(),
        height: (+height - 8 * ratio).toFixed(),
        rx: (4 * ratio).toFixed()
    }

    const templateComponentData = JSON.stringify(templateComponent)

    return (
        <>
            {bwFilter &&
                <defs>
                    <filter id={`${templateComponent.id}-item-filter`}>
                        <feColorMatrix // https://www.w3.org/TR/filter-effects/#grayscaleEquivalent
                            in="SourceGraphic"
                            type="matrix"
                            values="0.2126 0.7152 0.0722 0 0
                                    0.2126 0.7152 0.0722 0 0
                                    0.2126 0.7152 0.0722 0 0
                                    0 0 0 1 0"/>
                    </filter>
                </defs>}

            {!preview && !isTextField &&
                <>
                    <rect
                        ref={rectRef}
                        className={itemComponent ? 'skeleton' : 'placeholder'}
                        data-template-id={templateComponent.id}
                        {...position}
                        onClick={handleClick}
                    />
                    <foreignObject
                        onClick={handleClick}
                        {...position}
                    >
                        <div>
                            <svg viewBox="0 0 80 80" className="placeholder">
                                <path
                                    d="M56.3333 19H23.6667C21.0893 19 19 21.0893 19 23.6667V56.3333C19 58.9107 21.0893 61 
                                    23.6667 61H56.3333C58.9107 61 61 58.9107 61 56.3333V23.6667C61 21.0893 58.9107 19 56.3333 19Z"
                                />
                                <path
                                    d="M31.8333 35.3333C33.7663 35.3333 35.3333 33.7663 35.3333 31.8333C35.3333 29.9003 33.7663 28.3333 
                                    31.8333 28.3333C29.9003 28.3333 28.3333 29.9003 28.3333 31.8333C28.3333 33.7663 29.9003 35.3333 31.8333 35.3333Z"
                                />
                                <path d="M61 47L49.3333 35.3333L23.6667 61" />
                            </svg>
                        </div>
                    </foreignObject>
                </>}

            {preview &&
                <image
                    ref={imageRef}
                    href={preview}
                    style={bwFilter ? { filter: `url(#${templateComponent.id}-item-filter)` } : undefined}
                    onClick={handleClick}
                    data-template-id={templateComponent.id}
                    {...position}
                />}

            {preview && !isMobile && !navPanel && !dragComponent &&
                <foreignObject
                    onClick={handleClick}
                    data-template-id={templateComponent.id}
                    {...position}
                >
                    <div className="image-preview-hover">РЕДАКТИРОВАТЬ</div>
                </foreignObject >}

            {dotDensity && dotDensity > 180 && dotDensity < 240 &&
                <ImageWarningIcon {...position} />}

            {dotDensity && dotDensity <= 180 &&
                <ImageInfoIcon {...position} />}

            {dragComponent === 'img' && !isTextField && !navPanel &&
                <>
                    <rect
                        className="bg"
                        {...dropAreaPosition}
                    />
                    <foreignObject
                        {...dropAreaPosition}
                    >
                        <div className="selector" />
                    </foreignObject >
                </>}

            {isTextField && itemComponent?.value && !textEditMode &&
                <foreignObject
                    fontSize={fontSize}
                    {...position}
                >
                    <div>
                        <span style={{ ...cssObj }} ref={textSpanRef}>{text}</span>
                    </div>
                </foreignObject >}

            {isTextField &&
                <rect
                    ref={rectRef}
                    className="placeholder"
                    style={itemComponent?.value ? { fill: 'transparent' } : {}}
                    {...position}
                    onClick={handleClick}
                />}

            {isLongText && !textEditMode &&
                <TextInfoIcon {...position} />}


            <ModalBottomSheet
                state={modalIsVisible}
                setState={setModalIsVisible}
                className='album-sheet-modal'
            >
                <span>Что редактируем?</span>
                <div>

                    {intersectingArr.current.map((el, index) =>
                        <div
                            key={`clicked-element-${el.template.id + itemId + index}`}
                            onClick={() => {
                                editComponent(el)
                                setModalIsVisible(false)
                                intersectingArr.current = []
                            }}
                        >
                            {el.template.component_id === '1'
                                ?
                                <>
                                    <TextIcon />
                                    <div>Текст</div>
                                </>
                                :
                                <>
                                    <div
                                        style={{
                                            backgroundImage: el.component ? `url(${BASE_URL}/upload/3/256/${el.component?.value})` : ''
                                        }}
                                        className='image-el' />
                                    <div>Изображение</div>
                                </>
                            }

                        </div>)}

                </div>

            </ModalBottomSheet>
        </>
    )
}

