import { Form, Formik, FormikHelpers } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import { MdFormikField } from "../ui/MdFormikField"
import { FetchApiError } from "@russh/fetch-api"
import { signUp } from "../../store/reducers/authSlice"
import { useAppDispatch } from "../../store"
import { ChevronLeftIcon } from "../ui/icons"

YupPassword(Yup)

// --------------------------------------------------------------------------------

interface CodeVerificationProps {
    next: () => void
    prev: () => void
}

export const CodeVerification: React.FC<CodeVerificationProps> = ({ next, prev }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const validationSchemaCode = Yup.object().shape({
        code: Yup.string()
            .required('Введите код подтверждения')
            .length(6, 'Код должен состоять из 6 цифр'),
    })

    const handleSubmitCode = async (
        { code }: { code: string },
        { setErrors }: FormikHelpers<{ code: string }>
    ) => {
        try {
            await dispatch(signUp({ code: +code })).unwrap()
            navigate(-1)
        } catch (e) {
            const err = e as FetchApiError
            if (err.fields) setErrors(err.fields)
        }
    }

    return (
        <Formik
            initialValues={{ code: '' }}
            onSubmit={handleSubmitCode}
            validationSchema={validationSchemaCode}
        >
            <Form className="sign-up verification">
                <md-text-button
                    type="button"
                    class="left"
                    onClick={prev}
                >
                    Назад
                    <ChevronLeftIcon slotted />
                </md-text-button>
                <section>
                    <MdFormikField
                        name="code"
                        type="number"
                        label="Код подтверждения"
                    />
                </section>
                <div className="btns">
                    <md-filled-button
                        type="submit"
                    >
                        Создать аккаунт
                    </md-filled-button>
                </div>
            </Form>
        </Formik>
    )
}