import { MdCircularProgress } from "@material/web/progress/circular-progress"
import { store } from "../store"
import { setLoadedImgs } from "../store/reducers/gallerySlice"
import { readImageFile } from "./imageReader"
import { BASE_URL } from "./api"
import { showSnackbar } from "../store/reducers/snackbarSlice"

// --------------------------------------------------------------------------------

interface uploadWithProgressProps {
    url: string
    method?: 'POST' | 'PATCH' | 'PUT'
    data: XMLHttpRequestBodyInit
    uploadIndicator?: MdCircularProgress
    downloadIndicator?: MdCircularProgress
}

export const uploadWithProgress = async ({ url, method = 'POST', data, uploadIndicator, downloadIndicator }: uploadWithProgressProps) => {
    const xhr = new XMLHttpRequest()
    return new Promise((resolve, reject) => {
        let container = document.createElement('div')
        container.classList.add('progress-container')

        let progressIndicator = document.createElement('md-circular-progress')
        let progressValue = document.createElement('div')

        container.append(progressIndicator, progressValue)
        document.body.append(container)

        xhr.upload.addEventListener('loadstart', event => {
            if (event.lengthComputable) {
                container.classList.add('visible')
            }
        })

        // upload 
        // uploadIndicator &&
        xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                // progressIndicator.value = event.loaded / event.total
                // progressValue.innerText = `${(event.loaded / event.total * 100).toFixed()}%`
                console.log(`${(event.loaded / event.total * 100).toFixed()}% ${new Date(Date.now()).toLocaleTimeString('ru')}`)
            }
        })

        // download
        downloadIndicator &&
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    downloadIndicator.value = event.loaded / event.total
                }
            })

        // xhr.addEventListener("loadend", () => {
        //     resolve(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200)
        // })

        xhr.open(method, url, true)
        // xhr.setRequestHeader("Content-Type", "application/octet-stream")
        xhr.responseType = 'json'
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const status = xhr.status
                if (status === 0 || (status >= 200 && status < 400)) {
                    resolve(xhr.response)
                } else {
                    reject(xhr.response)
                }
            }
        }
        xhr.send(data)
    })
}

export const uploadImages = () => {
    const dispatch = store.dispatch
    let input = document.createElement('input')
    input.type = 'file'
    input.multiple = true

    input.onchange = e => {
        const inputTarget = e.target as HTMLInputElement
        if (!inputTarget.files) return
        console.log(inputTarget.files)

        let formData = new FormData()
        Array.from(inputTarget.files).forEach(file => {
            formData.append('uploadImage', file)
        })

        uploadWithProgress({ url: `${BASE_URL}/upload`, data: formData })

        const resultPromises = Array.from(inputTarget.files).map(file => readImageFile(file))

        Promise.all(resultPromises)
            .then(urlList => dispatch(setLoadedImgs((urlList))))
            .catch(e => showSnackbar({ text: e }))
            .finally(() => {
                input.remove()
            })
    }

    input.click()
}