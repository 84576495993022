import { useEffect, useState } from "react"
import { useAppSelector } from "../../store"
import { useNavigate } from "react-router-dom"

// --------------------------------------------------------------------------------

export const Scrim: React.FC = () => {
    const navigate = useNavigate()
    const { isVisible: bsIsVisible } = useAppSelector(state => state.bottomSheet)
    const { isVisible: editorIsVisible } = useAppSelector(state => state.imageEditor)
    const [show, setShow] = useState(false)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        if (bsIsVisible || editorIsVisible) {
            setShow(true)
            setTimeout(() => setOpacity(0.32), 50)
        } else {
            setOpacity(0)
            setTimeout(() => setShow(false), 500)
        }
    }, [bsIsVisible, editorIsVisible])

    const scrim: React.CSSProperties = {
        position: "fixed",
        inset: 0,
        background: "var(--md-sys-color-scrim)",
        opacity,
        transition: "opacity .5s ease-in-out",
        zIndex: 100
    }

    return (
        show ? <div style={scrim} onClick={() => navigate(-1)} /> : null
    )
}