import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TemplateComponent } from '../../@types/album'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    itemId?: string
    templateComponent?: TemplateComponent
    isVisible: boolean
} = {
    isVisible: false
}

const imageEditorSlice = createSlice({
    name: 'imageEditor',
    initialState,
    reducers: {

        setImageEditor: (state, action: PayloadAction<Partial<typeof initialState>>) => {
            state.templateComponent = action.payload.templateComponent
            state.itemId = action.payload.itemId
            state.isVisible = true
        },

        hideImageEditor: (state) => {
            state.isVisible = false
        }
    }
})

export const { setImageEditor, hideImageEditor } = imageEditorSlice.actions

export default imageEditorSlice.reducer