import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { setAllBackgrounds, setAllTemplates } from "../store/reducers/albumSlice"
import { createPortal } from "react-dom"

// --------------------------------------------------------------------------------

export const DropArea: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null)
    const {
        dragActive,
        dragComponent,
        dropPosition,
        backgroundId,
        templateId,
        templateTargetId,
        backgroundGroupId
    } = useAppSelector(state => state.dnd)
    const dispatch = useAppDispatch()

    const [show, setShow] = useState(false)

    useEffect(() => {
        if (!ref.current || dropPosition.length === 0) return
        const { top, bottom, left, right } = ref.current.getBoundingClientRect()
        const [y, x] = dropPosition

        if (top < y && y < bottom && left < x && x < right)
            switch (true) {

                case !!backgroundId:
                    backgroundId && backgroundGroupId &&
                        dispatch(setAllBackgrounds({ backgroundGroupId, backgroundId }))
                    break

                case !!templateId:
                    templateId &&
                        dispatch(setAllTemplates({ templateId, templateTargetId }))
                    break
            }

    }, [dropPosition])

    useEffect(() => {

        if (dragComponent === 'bgr' || dragComponent === 'tmpl') {
            setShow(true)
            setTimeout(() => {
                ref.current?.classList.add('active')
            }, 100)
        } else {
            ref.current?.classList.remove('active')
            setTimeout(() => setShow(false), 280)
        }

    }, [dragActive])

    return (
        show && createPortal(
            <div className={`apply-all drop-area`} ref={ref} />,
            document.body)
    )
}