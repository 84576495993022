import { useEffect, useRef, useState } from "react"
import { MiniGallery } from "./MiniGallery"
import { MiniLayouts } from "./MiniLayouts"
import { MiniBackground } from "./MiniBackground"
import { SwiperContainer } from "swiper/element"
import { useAppDispatch, useAppSelector } from "../store"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"
import { AllIcon, BgIcon, GalleryIcon, TemplateIcon } from "./ui/icons"
import { closeToolbar, setActive } from "../store/reducers/toolbarSlice"
import { enableOnboardingMode, setElPosition } from "../store/reducers/onboardingSlice"
import { MdIconButton } from "@material/web/iconbutton/icon-button"

// --------------------------------------------------------------------------------

export interface ToolbarButtonProps {
    name: 'Галерея' | 'Шаблон' | 'Фон'
    type: 'gallery' | 'template' | 'background'
    icon: JSX.Element
}

export const btns: Pick<ToolbarButtonProps, 'name' | 'type' | 'icon'>[] = [
    {
        name: 'Галерея',
        type: 'gallery',
        icon: <GalleryIcon />
    },
    {
        name: 'Шаблон',
        type: 'template',
        icon: <TemplateIcon />
    },
    {
        name: 'Фон',
        type: 'background',
        icon: <BgIcon />
    },
]

const ToolbarButton: React.FC<ToolbarButtonProps> = ({ name, type, icon }) => {
    const dispatch = useAppDispatch()
    const { active } = useAppSelector(state => state.toolbar)
    const { onboardingMode, step, completed } = useAppSelector(state => state.onboarding)
    const btnRef = useRef<HTMLDivElement>(null)

    // set onboarding el position for main steps 6, 7 and 8
    useEffect(() => {

        if (btnRef.current && onboardingMode === 'main')
            if (
                (step === 6 && type === 'gallery') ||
                (step === 7 && type === 'template') ||
                (step === 8 && type === 'background')
            ) {
                const { height, left, top, width } = btnRef.current.getBoundingClientRect()
                dispatch(setElPosition({
                    left: `${left - 10}px`,
                    top: `${top - 10}px`,
                    width: `${width + 20}px`,
                    height: `${height + 20}px`,
                }))
            }
    }, [onboardingMode, step])

    const handleClick = () => {
        if (type !== active)
            dispatch(setActive(type))
        else
            dispatch(closeToolbar())

        if (!completed[type]) {
            setTimeout(() =>
                dispatch(enableOnboardingMode(type)), 500)
        }
    }

    return (
        <div className={`toolbar-btn${type === active ? ' active' : ''}`} ref={btnRef}>
            <md-icon-button onClick={handleClick}>
                {icon}
            </md-icon-button>
            <span>{name.toUpperCase()}</span>
        </div>
    )
}

export const Toolbar: React.FC = () => {
    const dispatch = useAppDispatch()
    const toolbarRef = useRef<HTMLDivElement>(null)
    const swiperRef = useRef<SwiperContainer>(null)
    const { active } = useAppSelector(state => state.toolbar)
    const { previewMode } = useAppSelector(state => state.album)
    const { selectedFolder } = useAppSelector(state => state.gallery)
    const { textEditMode } = useAppSelector(state => state.textEditor)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const [selectedFolderName, setSelectedFolderName] = useState<string>()

    const panelRef = useRef<HTMLDivElement>(null)
    const showAllBtnRef = useRef<MdIconButton>(null)

    const isMobile = 'ontouchstart' in window

    const handleClick = () =>
        active && dispatch(setBottomSheetData({ [active]: true }))

    useEffect(() => {
        const swiper = swiperRef.current?.swiper
        if (!swiper) return

        switch (active) {
            case 'gallery':
                swiper.slideTo(0)
                break
            case 'template':
                swiper.slideTo(1)
                break
            case 'background':
                swiper.slideTo(2)
                break
        }
    }, [active, swiperRef.current])

    useEffect(() => {
        if (selectedFolder)
            setSelectedFolderName(selectedFolder.name)
        else
            setTimeout(() => setSelectedFolderName(undefined), 300)
    }, [selectedFolder])

    // Handle touch outside tollbar
    useEffect(() => {
        const handleTouchOutside = (e: TouchEvent) => {
            if (onboardingMode || !isMobile) return
            const target = e.target as Element

            if (!target.closest('.mobile-toolbar'))
                dispatch(closeToolbar())
        }

        document.body.addEventListener('touchstart', handleTouchOutside, { passive: true })
        return () => document.body.removeEventListener('touchstart', handleTouchOutside)
    }, [onboardingMode])

    useEffect(() => {

        // Set onboarding el position for gallery, template and bg in step 1
        if (panelRef.current)
            if (
                (step === 1 && onboardingMode === 'gallery') ||
                (step === 1 && onboardingMode === 'template') ||
                (step === 1 && onboardingMode === 'background')
            ) {
                const { height, left, top, width } = panelRef.current.getBoundingClientRect()
                dispatch(setElPosition({
                    left: `${left + 1}px`,
                    top: `${top}px`,
                    width: `${width - 2}px`,
                    height: `${height}px`,
                }))
            }

        // Set onboarding el position for gallery, template and bg in last steps
        if (showAllBtnRef.current)
            if (
                (step === 4 && onboardingMode === 'gallery') ||
                (step === 3 && onboardingMode === 'template') ||
                (step === 2 && onboardingMode === 'background')
            ) {
                const { height, left, top, width } = showAllBtnRef.current.getBoundingClientRect()
                dispatch(setElPosition({
                    left: `${left + 1}px`,
                    top: `${top + 1}px`,
                    width: `${width - 2}px`,
                    height: `${height - 2}px`,
                }))
            }
    }, [onboardingMode, step])

    if (textEditMode || !isMobile) return null

    return (
        <>
            <div
                ref={toolbarRef}
                className={
                    `mobile-toolbar ${active ? 'active' : ''} ${selectedFolder && active === 'gallery' ? 'folder-name' : ''}${previewMode ? 'preview' : ''}`
                }
            >
                <md-elevation />

                {btns.map(btn =>
                    <ToolbarButton
                        key={`toolbar_btn_${btn.type}`}
                        {...btn}
                    />)}

                <div className={`toolbar-panel`} ref={panelRef}>
                    <swiper-container
                        ref={swiperRef}
                        direction="vertical"
                        allow-touch-move={false}
                    >
                        <swiper-slide>
                            <MiniGallery />
                        </swiper-slide>
                        <swiper-slide>
                            <MiniLayouts />
                        </swiper-slide>
                        <swiper-slide>
                            <MiniBackground />
                        </swiper-slide>
                    </swiper-container>

                    <md-icon-button onClick={handleClick} ref={showAllBtnRef}>
                        <AllIcon />
                    </md-icon-button>
                </div>

                {selectedFolderName && active === 'gallery' &&
                    <div className="toolbar-folder-name">{selectedFolderName.toUpperCase()}</div>}
            </div>
        </>
    )
}