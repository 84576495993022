import { useAppDispatch, useAppSelector } from "../store"
import { templates } from "../../data"
import { useEffect, useRef, useState } from "react"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { SwiperContainer } from "swiper/element"
import { BASE_URL } from "../utils/api"
import { setLayoutsFilter } from "../store/reducers/settingsSlice"
import { FilterIcon, ImagesAndTextIcon, ImagesIcon, TextIcon } from "./ui/icons"
import { createPortal } from "react-dom"
import { setElPosition } from "../store/reducers/onboardingSlice"
import { Template } from "../@types/templates"

// --------------------------------------------------------------------------------

const filters = [
    {
        value: '0',
        title: 'Все шаблоны',
        icon: <FilterIcon />
    },
    {
        value: '5',
        title: 'Изображения и текст',
        icon: <ImagesAndTextIcon />
    },
    {
        value: '7',
        title: 'Только изображение',
        icon: <ImagesIcon />
    },
    {
        value: '9',
        title: 'Только текст',
        icon: <TextIcon />
    }
]

export const MiniLayouts: React.FC = () => {
    const { dragActive } = useAppSelector(state => state.dnd)
    const { layoutsFilter } = useAppSelector(state => state.settings)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const { album, activeItemId } = useAppSelector(state => state.album)
    const dndEvents = useDragAndDrop()
    const dispatch = useAppDispatch()

    const actionBtnRef = useRef<HTMLDivElement>(null)
    const swiperRef = useRef<SwiperContainer>(null)
    const timer = useRef<number>()

    const [filter, setFilter] = useState(filters.find(f => f.value === layoutsFilter))
    const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
    const [activeTemplates, setActiveTemplates] = useState<Template[]>([])

    const handleClick = () => {
        const index = filters.findIndex(f => f.value === layoutsFilter)

        if (index < filters.length - 1)
            setFilter(filters[index + 1])
        else
            setFilter(filters[0])

        timer.current && clearTimeout(timer.current)
        timer.current = undefined

        setTooltipIsVisible(true)
        timer.current = setTimeout(() => setTooltipIsVisible(false), 1400)
    }

    // Filter templates by type
    useEffect(() => {
        if (!album || !activeItemId) return

        const activeItemTargetId = album.items[activeItemId].params.target_id
        setActiveTemplates(Object.values(templates).filter(tmpl => tmpl.params.target_id === activeItemTargetId))

    }, [album, activeItemId])

    // Set global filter state
    useEffect(() => {
        filter && dispatch(setLayoutsFilter(filter.value))
        setTimeout(() =>
            swiperRef.current?.swiper.update())
    }, [filter])

    // Lock slider when drag active
    useEffect(() => {
        if (!swiperRef.current) return
        if (dragActive)
            swiperRef.current.swiper.allowTouchMove = false
        else
            swiperRef.current.swiper.allowTouchMove = true
    }, [swiperRef.current, dragActive])

    // Set onboarding el position for template step 2
    useEffect(() => {
        if (actionBtnRef.current && onboardingMode === 'template' && step === 2) {
            const { height, left, top, width } = actionBtnRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                left: `${left + 1}px`,
                top: `${top + 1}px`,
                width: `${width - 2}px`,
                height: `${height - 2}px`,
            }))
        }
    }, [onboardingMode, step])

    const filteredTemplates = Object.values(activeTemplates)
        .filter(el => layoutsFilter === '0' || el.params.group_id === layoutsFilter)

    return (
        <>
            <div className="btns" ref={actionBtnRef}>
                {filter &&
                    <md-icon-button onClick={handleClick}>
                        {filter.icon}
                    </md-icon-button>}

                {tooltipIsVisible && filter && createPortal(
                    <div className="gallery-btns-tooltip">{filter.title}</div>,
                    document.querySelector('.toolbar-panel') ?? document.body
                )}
            </div>
            <swiper-container
                class="layouts"
                ref={swiperRef}
                slides-per-view={3}
                slides-per-group={3}
                space-between={7}
            >
                {filteredTemplates.length > 0
                    ?
                    filteredTemplates.map(el =>
                        <swiper-slide key={`toolbar-panel-layout-${el.params.id}`}>
                            <div
                                className="layout-element draggable"
                                data-layout-id={el.params.id}
                                data-target-id={el.params.target_id}
                                style={{ aspectRatio: `${album?.product.width ?? 1}/${album?.product.height ?? 1}` }}
                                {...dndEvents}
                            >
                                <img src={`${BASE_URL}/templates/${el.params.icon}`} />
                                <div className="overlay" />
                            </div>
                        </swiper-slide>)
                    :
                    <div className='nothing-found'>Нет шаблонов</div>}

            </swiper-container>
        </>
    )
}