import { Item, ItemTemplate } from "./src/@types/album"

// --------------------------------------------------------------------------------

export class AlbumItem {
    constructor(
        id: string | number,
        index: string | number
    ) {
        this.params.id = `${id}`
        this.params.sort = `${index}`
    }

    params = {
        id: '',
        system_id: null,
        project_id: "837255722",
        template_id: "6372",
        target_id: "3",
        color_id: null,
        selected: "0",
        sort: '100',
        month: null
    }
    color = null
    template = defaultTemplate
    components = []

    toJSON() {
        return Object.assign({}, this) as Item
    }
}

const defaultTemplate: ItemTemplate = {
    "params": {
        "id": "6372",
        "system_id": null,
        "name": "6 фото",
        "target_id": "3",
        "orientation_id": "1",
        "product_id": "57",
        "group_id": "7",
        "is_default": "0",
        "active": "1",
        "is_changed": "0",
        "archive": "0",
        "sort": null,
        "type": "1",
        "icon": "153333087160c36e91c55ef5.42134557.png",
        "display_device": null,
        "to_delete": "0"
    },
    "components": [
        {
            "id": "29261",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "22.00",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "1",
            "tag": "foto1",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29262",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "22.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "2",
            "tag": "foto2",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29263",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "146.00",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "3",
            "tag": "foto2",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29264",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "146.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "4",
            "tag": "foto3",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29265",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "269.37",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "5",
            "tag": "foto5",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29266",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "269.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "6",
            "tag": "foto6",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        }
    ],
    "coordinateComponents": [
        {
            "id": "29261",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "22.00",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "1",
            "tag": "foto1",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29262",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "22.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "2",
            "tag": "foto2",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29263",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "146.00",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "3",
            "tag": "foto2",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29264",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "146.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "4",
            "tag": "foto3",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29266",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "269.00",
            "y": "106.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "6",
            "tag": "foto6",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        },
        {
            "id": "29265",
            "system_id": null,
            "template_id": "6372",
            "component_id": "3",
            "x": "269.37",
            "y": "23.00",
            "width": "117.00",
            "height": "78.00",
            "editable": "1",
            "level": "5",
            "tag": "foto5",
            "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
            "value": null,
            "params": null,
            "view_in_pdf": "1",
            "type_value": "1",
            "view_type": null
        }
    ]
}