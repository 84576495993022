import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// --------------------------------------------------------------------------------

const localData = localStorage.getItem('onboarding')
const localCompleted = localData ? JSON.parse(localData) as Record<string, boolean> : {}

interface DOMRectJson {
    width: string,
    height: string,
    top: string,
    left: string
}

type Modes = 'main' | 'gallery' | 'template' | 'background'

const initialState: {
    onboardingMode?: Modes
    elPosition?: DOMRectJson
    step: number
    completed: Record<Modes, boolean>
} = {
    onboardingMode: undefined,
    completed: localCompleted,
    step: 1
}

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {

        enableOnboardingMode: (state, action: PayloadAction<typeof initialState['onboardingMode']>) => {
            state.onboardingMode = action.payload
        },

        disableOnboardingMode: (state) => {
            const currentMode = state.onboardingMode as string
            state.completed = { ...state.completed, [currentMode]: true }

            state.onboardingMode = undefined
            state.elPosition = undefined
            state.step = 1

            localStorage.setItem('onboarding', JSON.stringify(state.completed))
        },

        setElPosition: (state, action: PayloadAction<DOMRectJson>) => {
            state.elPosition = action.payload
        },

        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload
        },

        resetOnboarding: (state) => {
            state.completed = {} as Record<Modes, boolean>
            localStorage.removeItem('onboarding')
            state.onboardingMode = 'main'
        },
    }
})

export const {
    enableOnboardingMode,
    disableOnboardingMode,
    setElPosition,
    resetOnboarding,
    setStep
} = onboardingSlice.actions

export default onboardingSlice.reducer