import { Album } from "./src/@types/album"

// --------------------------------------------------------------------------------

export const album: Album = {
    "params": {
        "id": "837255722",
        "system_id": null,
        "user_id": null,
        "name": "Проект",
        "product_id": "57",
        "store_product_id": "60",
        "sync_id": null,
        "min_sides": "10",
        "max_sides": "50",
        "is_fixed_sides": null,
        "status": "0",
        "create_date": "2023-08-01 15:19:15",
        "type": "0",
        "design_id": null,
        "enabled": "1",
        "is_changed": "0",
        "position_id": "64c8f857dc39a",
        "style_id": null,
        "mode": "1",
        "calc_params": null,
        "autocomplete": null,
        "manager_id": null,
        "service_id": null,
        "approval_status_id": "0",
        "price": "1062.00",
        "step_price": null,
        "is_album_created": "0",
        "agency_id": null,
        "count_back_sides": "10"
    },
    "product": {
        "id": "57",
        "system_id": null,
        "name": "200 х 200 Фотокнига Layflat",
        "width": "400.00",
        "height": "200.00",
        "sides_id": "2",
        "cut_zone": "3.00",
        "safe_zone": "5.00",
        "front_cut_zone": "21.00",
        "book_gap": "2.00",
        "type": "2",
        "type_layout": "2",
        "is_changed": "0",
        "type_product": "1",
        "forced_autocomplete": "0",
        "cover_width": "400.00",
        "cover_height": "200.00",
        "format_output_layout": "1",
        "cut_zone_bend": "3.00",
        "numerationImage": "/assets/dfc8ee74/img/default-numerator.png"
    },
    "templates": {
        "6370": {
            "id": "6370",
            "system_id": null,
            "name": "Название + фото",
            "target_id": "2",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "9",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "37628340460c36dc01e3d89.89398854.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/37628340460c36dc01e3d89.89398854.png"
        },
        "6371": {
            "id": "6371",
            "system_id": null,
            "name": "Название",
            "target_id": "2",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "9",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "111940869460c36df70509c2.50368116.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/111940869460c36df70509c2.50368116.png"
        },
        "6372": {
            "id": "6372",
            "system_id": null,
            "name": "6 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "153333087160c36e91c55ef5.42134557.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/153333087160c36e91c55ef5.42134557.png"
        },
        "6373": {
            "id": "6373",
            "system_id": null,
            "name": "5 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "154518925760c36f035f20a6.25692616.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/154518925760c36f035f20a6.25692616.png"
        },
        "6374": {
            "id": "6374",
            "system_id": null,
            "name": "3 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "158985613560c36f4875de49.50385870.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/158985613560c36f4875de49.50385870.png"
        },
        "6375": {
            "id": "6375",
            "system_id": null,
            "name": "6 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "145751012860c36fad11b1b7.47732508.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/145751012860c36fad11b1b7.47732508.png"
        },
        "6376": {
            "id": "6376",
            "system_id": null,
            "name": "2 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "29526855460c36fe5082994.16511282.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/29526855460c36fe5082994.16511282.png"
        },
        "6377": {
            "id": "6377",
            "system_id": null,
            "name": "1 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "183783261960c37001e2a8a1.14609659.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/183783261960c37001e2a8a1.14609659.png"
        },
        "6378": {
            "id": "6378",
            "system_id": null,
            "name": "4 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "142986036760c370736d91b1.97301187.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/142986036760c370736d91b1.97301187.png"
        },
        "6379": {
            "id": "6379",
            "system_id": null,
            "name": "4 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "19385099660c3709e37d475.00217019.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/19385099660c3709e37d475.00217019.png"
        },
        "6380": {
            "id": "6380",
            "system_id": null,
            "name": "2 фото",
            "target_id": "3",
            "orientation_id": "1",
            "product_id": "57",
            "group_id": "7",
            "is_default": "0",
            "active": "1",
            "is_changed": "0",
            "archive": "0",
            "sort": null,
            "type": "1",
            "icon": "144495380660c370c67d0bb9.08389790.png",
            "display_device": null,
            "to_delete": "0",
            "iconUrl": "https://static2.wow2print.com/storage/2177/editor/template/144495380660c370c67d0bb9.08389790.png"
        }
    },
    "bookSpines": [
        {
            "id": "6",
            "product_id": "57",
            "page_count": "24",
            "thickness": "10",
            "enable_text": "0"
        },
        {
            "id": "7",
            "product_id": "57",
            "page_count": "36",
            "thickness": "12",
            "enable_text": "0"
        },
        {
            "id": "8",
            "product_id": "57",
            "page_count": "48",
            "thickness": "14",
            "enable_text": "0"
        },
        {
            "id": "9",
            "product_id": "57",
            "page_count": "56",
            "thickness": "16",
            "enable_text": "0"
        },
        {
            "id": "10",
            "product_id": "57",
            "page_count": "64",
            "thickness": "18",
            "enable_text": "0"
        },
        {
            "id": "11",
            "product_id": "57",
            "page_count": "72",
            "thickness": "20",
            "enable_text": "0"
        },
        {
            "id": "12",
            "product_id": "57",
            "page_count": "78",
            "thickness": "22",
            "enable_text": "0"
        },
        {
            "id": "13",
            "product_id": "57",
            "page_count": "84",
            "thickness": "25",
            "enable_text": "0"
        },
        {
            "id": "14",
            "product_id": "57",
            "page_count": "90",
            "thickness": "27",
            "enable_text": "0"
        },
        {
            "id": "15",
            "product_id": "57",
            "page_count": "95",
            "thickness": "30",
            "enable_text": "0"
        },
        {
            "id": "16",
            "product_id": "57",
            "page_count": "100",
            "thickness": "32",
            "enable_text": "0"
        }
    ],
    "files": {},
    "items": {
        "1000222266": {
            "params": {
                "id": "1000222266",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6370",
                "target_id": "2",
                "color_id": null,
                "selected": "0",
                "sort": "1",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6370",
                    "system_id": null,
                    "name": "Название + фото",
                    "target_id": "2",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "9",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "37628340460c36dc01e3d89.89398854.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29259",
                        "system_id": null,
                        "template_id": "6370",
                        "component_id": "1",
                        "x": "263.00",
                        "y": "65.00",
                        "width": "120.00",
                        "height": "20.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "name1",
                        "style": "{\"font_size\":\"35\",\"font_color_id\":\"140\",\"font_id\":\"9\",\"angle\":0,\"font_style\":\"normal\",\"align\":\"center\",\"font_limit_status\":false,\"font_limit_min_size\":\"\",\"font_limit_max_size\":\"\",\"font_limit_font_family\":null}",
                        "value": "Отпуск 2021",
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29342",
                        "system_id": null,
                        "template_id": "6370",
                        "component_id": "3",
                        "x": "248.00",
                        "y": "100.00",
                        "width": "150.00",
                        "height": "100.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "name1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29342",
                        "system_id": null,
                        "template_id": "6370",
                        "component_id": "3",
                        "x": "248.00",
                        "y": "100.00",
                        "width": "150.00",
                        "height": "100.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "name1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29259",
                        "system_id": null,
                        "template_id": "6370",
                        "component_id": "1",
                        "x": "263.00",
                        "y": "65.00",
                        "width": "120.00",
                        "height": "20.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "name1",
                        "style": "{\"font_size\":\"35\",\"font_color_id\":\"140\",\"font_id\":\"9\",\"angle\":0,\"font_style\":\"normal\",\"align\":\"center\",\"font_limit_status\":false,\"font_limit_min_size\":\"\",\"font_limit_max_size\":\"\",\"font_limit_font_family\":null}",
                        "value": "Отпуск 2021",
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222267": {
            "params": {
                "id": "1000222267",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "2",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222268": {
            "params": {
                "id": "1000222268",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "3",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222269": {
            "params": {
                "id": "1000222269",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "4",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222270": {
            "params": {
                "id": "1000222270",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "5",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222271": {
            "params": {
                "id": "1000222271",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "6",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222272": {
            "params": {
                "id": "1000222272",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "7",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222273": {
            "params": {
                "id": "1000222273",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "8",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222274": {
            "params": {
                "id": "1000222274",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "9",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222275": {
            "params": {
                "id": "1000222275",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "10",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        },
        "1000222276": {
            "params": {
                "id": "1000222276",
                "system_id": null,
                "project_id": "837255722",
                "template_id": "6372",
                "target_id": "3",
                "color_id": null,
                "selected": "0",
                "sort": "11",
                "month": null
            },
            "color": null,
            "template": {
                "params": {
                    "id": "6372",
                    "system_id": null,
                    "name": "6 фото",
                    "target_id": "3",
                    "orientation_id": "1",
                    "product_id": "57",
                    "group_id": "7",
                    "is_default": "0",
                    "active": "1",
                    "is_changed": "0",
                    "archive": "0",
                    "sort": null,
                    "type": "1",
                    "icon": "153333087160c36e91c55ef5.42134557.png",
                    "display_device": null,
                    "to_delete": "0"
                },
                "components": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ],
                "coordinateComponents": [
                    {
                        "id": "29261",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "1",
                        "tag": "foto1",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29262",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "22.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "2",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29263",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "3",
                        "tag": "foto2",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29264",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "146.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "4",
                        "tag": "foto3",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29266",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.00",
                        "y": "106.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "6",
                        "tag": "foto6",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    },
                    {
                        "id": "29265",
                        "system_id": null,
                        "template_id": "6372",
                        "component_id": "3",
                        "x": "269.37",
                        "y": "23.00",
                        "width": "117.00",
                        "height": "78.00",
                        "editable": "1",
                        "level": "5",
                        "tag": "foto5",
                        "style": "{\"offsetX\":null,\"offsetY\":null,\"zoom\":null,\"angle\":0,\"topCutZone\":\"0\",\"rightCutZone\":\"0\",\"leftCutZone\":\"0\",\"bottomCutZone\":\"0\"}",
                        "value": null,
                        "params": null,
                        "view_in_pdf": "1",
                        "type_value": "1",
                        "view_type": null
                    }
                ]
            },
            "components": []
        }
    },
    "albums": [],
    "social": {
        "instagram": {
            "appId": "1582370948583518",
            "redirectUrl": "https://demo.wow2print.com/api/index/instagram"
        },
        "vk": {
            "appId": "5621805"
        }
    },
    "style": null,
    "textColors": {
        "28": [
            {
                "id": "140",
                "r": "0",
                "g": "0",
                "b": "0",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "100",
                "sort": "1",
                "group_id": "28",
                "rgb": "0,0,0"
            },
            {
                "id": "141",
                "r": "87",
                "g": "87",
                "b": "86",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "80",
                "sort": "2",
                "group_id": "28",
                "rgb": "87,87,86"
            },
            {
                "id": "142",
                "r": "178",
                "g": "178",
                "b": "178",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "40",
                "sort": "3",
                "group_id": "28",
                "rgb": "178,178,178"
            },
            {
                "id": "143",
                "r": "227",
                "g": "227",
                "b": "227",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "15",
                "sort": "4",
                "group_id": "28",
                "rgb": "227,227,227"
            },
            {
                "id": "144",
                "r": "255",
                "g": "255",
                "b": "255",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "0",
                "sort": "5",
                "group_id": "28",
                "rgb": "255,255,255"
            }
        ],
        "29": [
            {
                "id": "145",
                "r": "19",
                "g": "51",
                "b": "113",
                "c": "100",
                "m": "80",
                "y": "5",
                "k": "30",
                "sort": "1",
                "group_id": "29",
                "rgb": "19,51,113"
            },
            {
                "id": "146",
                "r": "0",
                "g": "105",
                "b": "180",
                "c": "100",
                "m": "50",
                "y": "0",
                "k": "0",
                "sort": "2",
                "group_id": "29",
                "rgb": "0,105,180"
            },
            {
                "id": "147",
                "r": "0",
                "g": "154",
                "b": "216",
                "c": "100",
                "m": "5",
                "y": "5",
                "k": "0",
                "sort": "3",
                "group_id": "29",
                "rgb": "0,154,216"
            },
            {
                "id": "148",
                "r": "101",
                "g": "193",
                "b": "227",
                "c": "55",
                "m": "5",
                "y": "5",
                "k": "0",
                "sort": "4",
                "group_id": "29",
                "rgb": "101,193,227"
            },
            {
                "id": "149",
                "r": "224",
                "g": "241",
                "b": "245",
                "c": "15",
                "m": "0",
                "y": "5",
                "k": "0",
                "sort": "5",
                "group_id": "29",
                "rgb": "224,241,245"
            }
        ],
        "30": [
            {
                "id": "150",
                "r": "0",
                "g": "93",
                "b": "100",
                "c": "95",
                "m": "0",
                "y": "35",
                "k": "55",
                "sort": "1",
                "group_id": "30",
                "rgb": "0,93,100"
            },
            {
                "id": "151",
                "r": "0",
                "g": "153",
                "b": "152",
                "c": "80",
                "m": "0",
                "y": "35",
                "k": "20",
                "sort": "2",
                "group_id": "30",
                "rgb": "0,153,152"
            },
            {
                "id": "152",
                "r": "0",
                "g": "177",
                "b": "170",
                "c": "75",
                "m": "0",
                "y": "40",
                "k": "0",
                "sort": "3",
                "group_id": "30",
                "rgb": "0,177,170"
            },
            {
                "id": "153",
                "r": "121",
                "g": "198",
                "b": "192",
                "c": "55",
                "m": "0",
                "y": "30",
                "k": "0",
                "sort": "4",
                "group_id": "30",
                "rgb": "121,198,192"
            },
            {
                "id": "154",
                "r": "214",
                "g": "235",
                "b": "226",
                "c": "20",
                "m": "0",
                "y": "15",
                "k": "0",
                "sort": "5",
                "group_id": "30",
                "rgb": "214,235,226"
            }
        ],
        "31": [
            {
                "id": "155",
                "r": "0",
                "g": "91",
                "b": "60",
                "c": "95",
                "m": "0",
                "y": "75",
                "k": "55",
                "sort": "1",
                "group_id": "31",
                "rgb": "0,91,60"
            },
            {
                "id": "156",
                "r": "0",
                "g": "171",
                "b": "78",
                "c": "90",
                "m": "0",
                "y": "100",
                "k": "0",
                "sort": "2",
                "group_id": "31",
                "rgb": "0,171,78"
            },
            {
                "id": "157",
                "r": "98",
                "g": "187",
                "b": "70",
                "c": "65",
                "m": "0",
                "y": "100",
                "k": "0",
                "sort": "3",
                "group_id": "31",
                "rgb": "98,187,70"
            },
            {
                "id": "158",
                "r": "196",
                "g": "216",
                "b": "46",
                "c": "30",
                "m": "0",
                "y": "100",
                "k": "0",
                "sort": "4",
                "group_id": "31",
                "rgb": "196,216,46"
            },
            {
                "id": "159",
                "r": "248",
                "g": "245",
                "b": "193",
                "c": "5",
                "m": "0",
                "y": "33",
                "k": "0",
                "sort": "5",
                "group_id": "31",
                "rgb": "248,245,193"
            }
        ],
        "32": [
            {
                "id": "160",
                "r": "253",
                "g": "185",
                "b": "19",
                "c": "0",
                "m": "30",
                "y": "100",
                "k": "0",
                "sort": "1",
                "group_id": "32",
                "rgb": "253,185,19"
            },
            {
                "id": "161",
                "r": "255",
                "g": "212",
                "b": "0",
                "c": "0",
                "m": "15",
                "y": "100",
                "k": "0",
                "sort": "2",
                "group_id": "32",
                "rgb": "255,212,0"
            },
            {
                "id": "162",
                "r": "255",
                "g": "242",
                "b": "3",
                "c": "0",
                "m": "0",
                "y": "95",
                "k": "0",
                "sort": "3",
                "group_id": "32",
                "rgb": "255,242,3"
            },
            {
                "id": "163",
                "r": "255",
                "g": "247",
                "b": "153",
                "c": "0",
                "m": "0",
                "y": "50",
                "k": "0",
                "sort": "4",
                "group_id": "32",
                "rgb": "255,247,153"
            },
            {
                "id": "164",
                "r": "255",
                "g": "252",
                "b": "223",
                "c": "0",
                "m": "0",
                "y": "15",
                "k": "0",
                "sort": "5",
                "group_id": "32",
                "rgb": "255,252,223"
            }
        ],
        "33": [
            {
                "id": "165",
                "r": "116",
                "g": "8",
                "b": "9",
                "c": "20",
                "m": "100",
                "y": "100",
                "k": "50",
                "sort": "1",
                "group_id": "33",
                "rgb": "116,8,9"
            },
            {
                "id": "166",
                "r": "184",
                "g": "23",
                "b": "28",
                "c": "5",
                "m": "100",
                "y": "100",
                "k": "20",
                "sort": "2",
                "group_id": "33",
                "rgb": "184,23,28"
            },
            {
                "id": "167",
                "r": "238",
                "g": "49",
                "b": "36",
                "c": "0",
                "m": "95",
                "y": "100",
                "k": "0",
                "sort": "3",
                "group_id": "33",
                "rgb": "238,49,36"
            },
            {
                "id": "168",
                "r": "247",
                "g": "148",
                "b": "60",
                "c": "0",
                "m": "50",
                "y": "85",
                "k": "0",
                "sort": "4",
                "group_id": "33",
                "rgb": "247,148,60"
            },
            {
                "id": "169",
                "r": "251",
                "g": "204",
                "b": "152",
                "c": "0",
                "m": "25",
                "y": "45",
                "k": "0",
                "sort": "5",
                "group_id": "33",
                "rgb": "251,204,152"
            }
        ],
        "34": [
            {
                "id": "170",
                "r": "101",
                "g": "0",
                "b": "56",
                "c": "20",
                "m": "100",
                "y": "20",
                "k": "60",
                "sort": "1",
                "group_id": "34",
                "rgb": "101,0,56"
            },
            {
                "id": "171",
                "r": "184",
                "g": "12",
                "b": "75",
                "c": "5",
                "m": "100",
                "y": "50",
                "k": "20",
                "sort": "2",
                "group_id": "34",
                "rgb": "184,12,75"
            },
            {
                "id": "172",
                "r": "234",
                "g": "54",
                "b": "142",
                "c": "0",
                "m": "90",
                "y": "5",
                "k": "0",
                "sort": "3",
                "group_id": "34",
                "rgb": "234,54,142"
            },
            {
                "id": "173",
                "r": "236",
                "g": "128",
                "b": "179",
                "c": "0",
                "m": "65",
                "y": "0",
                "k": "0",
                "sort": "4",
                "group_id": "34",
                "rgb": "236,128,179"
            },
            {
                "id": "174",
                "r": "247",
                "g": "216",
                "b": "232",
                "c": "0",
                "m": "20",
                "y": "0",
                "k": "0",
                "sort": "5",
                "group_id": "34",
                "rgb": "247,216,232"
            }
        ],
        "35": [
            {
                "id": "175",
                "r": "41",
                "g": "15",
                "b": "66",
                "c": "90",
                "m": "100",
                "y": "10",
                "k": "60",
                "sort": "1",
                "group_id": "35",
                "rgb": "41,15,66"
            },
            {
                "id": "176",
                "r": "59",
                "g": "37",
                "b": "127",
                "c": "95",
                "m": "100",
                "y": "0",
                "k": "5",
                "sort": "2",
                "group_id": "35",
                "rgb": "59,37,127"
            },
            {
                "id": "177",
                "r": "120",
                "g": "54",
                "b": "140",
                "c": "65",
                "m": "90",
                "y": "0",
                "k": "0",
                "sort": "3",
                "group_id": "35",
                "rgb": "120,54,140"
            },
            {
                "id": "178",
                "r": "178",
                "g": "141",
                "b": "192",
                "c": "35",
                "m": "50",
                "y": "0",
                "k": "0",
                "sort": "4",
                "group_id": "35",
                "rgb": "178,141,192"
            },
            {
                "id": "179",
                "r": "232",
                "g": "230",
                "b": "244",
                "c": "10",
                "m": "10",
                "y": "0",
                "k": "0",
                "sort": "5",
                "group_id": "35",
                "rgb": "232,230,244"
            }
        ],
        "36": [
            {
                "id": "180",
                "r": "112",
                "g": "71",
                "b": "59",
                "c": "40",
                "m": "65",
                "y": "65",
                "k": "45",
                "sort": "1",
                "group_id": "36",
                "rgb": "112,71,59"
            },
            {
                "id": "181",
                "r": "146",
                "g": "101",
                "b": "75",
                "c": "20",
                "m": "50",
                "y": "60",
                "k": "40",
                "sort": "2",
                "group_id": "36",
                "rgb": "146,101,75"
            },
            {
                "id": "182",
                "r": "170",
                "g": "121",
                "b": "71",
                "c": "0",
                "m": "40",
                "y": "65",
                "k": "40",
                "sort": "3",
                "group_id": "36",
                "rgb": "170,121,71"
            },
            {
                "id": "183",
                "r": "203",
                "g": "166",
                "b": "117",
                "c": "0",
                "m": "25",
                "y": "50",
                "k": "25",
                "sort": "4",
                "group_id": "36",
                "rgb": "203,166,117"
            },
            {
                "id": "184",
                "r": "224",
                "g": "215",
                "b": "196",
                "c": "15",
                "m": "15",
                "y": "25",
                "k": "0",
                "sort": "5",
                "group_id": "36",
                "rgb": "224,215,196"
            }
        ]
    },
    "bgColors": {
        "19": [
            {
                "id": "95",
                "r": "0",
                "g": "0",
                "b": "0",
                "c": "60",
                "m": "60",
                "y": "60",
                "k": "100",
                "sort": "1",
                "group_id": "19",
                "rgb": "0,0,0"
            },
            {
                "id": "96",
                "r": "87",
                "g": "87",
                "b": "86",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "80",
                "sort": "2",
                "group_id": "19",
                "rgb": "87,87,86"
            },
            {
                "id": "97",
                "r": "178",
                "g": "178",
                "b": "178",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "40",
                "sort": "3",
                "group_id": "19",
                "rgb": "178,178,178"
            },
            {
                "id": "98",
                "r": "227",
                "g": "227",
                "b": "227",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "15",
                "sort": "4",
                "group_id": "19",
                "rgb": "227,227,227"
            },
            {
                "id": "99",
                "r": "255",
                "g": "255",
                "b": "255",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "0",
                "sort": "5",
                "group_id": "19",
                "rgb": "255,255,255"
            }
        ],
        "37": [
            {
                "id": "185",
                "r": "179",
                "g": "163",
                "b": "159",
                "c": "31",
                "m": "32",
                "y": "32",
                "k": "7",
                "sort": null,
                "group_id": "37",
                "rgb": "179,163,159"
            },
            {
                "id": "186",
                "r": "190",
                "g": "175",
                "b": "170",
                "c": "28",
                "m": "28",
                "y": "29",
                "k": "4",
                "sort": null,
                "group_id": "37",
                "rgb": "190,175,170"
            },
            {
                "id": "187",
                "r": "203",
                "g": "192",
                "b": "187",
                "c": "23",
                "m": "22",
                "y": "24",
                "k": "1",
                "sort": null,
                "group_id": "37",
                "rgb": "203,192,187"
            },
            {
                "id": "188",
                "r": "216",
                "g": "208",
                "b": "204",
                "c": "18",
                "m": "16",
                "y": "18",
                "k": "0",
                "sort": null,
                "group_id": "37",
                "rgb": "216,208,204"
            },
            {
                "id": "189",
                "r": "230",
                "g": "222",
                "b": "218",
                "c": "11",
                "m": "12",
                "y": "13",
                "k": "0",
                "sort": null,
                "group_id": "37",
                "rgb": "230,222,218"
            }
        ],
        "38": [
            {
                "id": "190",
                "r": "70",
                "g": "151",
                "b": "166",
                "c": "71",
                "m": "19",
                "y": "27",
                "k": "13",
                "sort": null,
                "group_id": "38",
                "rgb": "70,151,166"
            },
            {
                "id": "191",
                "r": "112",
                "g": "168",
                "b": "180",
                "c": "59",
                "m": "18",
                "y": "24",
                "k": "7",
                "sort": null,
                "group_id": "38",
                "rgb": "112,168,180"
            },
            {
                "id": "192",
                "r": "147",
                "g": "186",
                "b": "196",
                "c": "46",
                "m": "15",
                "y": "20",
                "k": "3",
                "sort": null,
                "group_id": "38",
                "rgb": "147,186,196"
            },
            {
                "id": "193",
                "r": "177",
                "g": "204",
                "b": "209",
                "c": "35",
                "m": "11",
                "y": "17",
                "k": "0",
                "sort": null,
                "group_id": "38",
                "rgb": "177,204,209"
            },
            {
                "id": "194",
                "r": "210",
                "g": "224",
                "b": "228",
                "c": "20",
                "m": "7",
                "y": "9",
                "k": "0",
                "sort": null,
                "group_id": "38",
                "rgb": "210,224,228"
            }
        ],
        "39": [
            {
                "id": "195",
                "r": "232",
                "g": "191",
                "b": "183",
                "c": "10",
                "m": "29",
                "y": "25",
                "k": "0",
                "sort": null,
                "group_id": "39",
                "rgb": "232,191,183"
            },
            {
                "id": "196",
                "r": "233",
                "g": "201",
                "b": "194",
                "c": "10",
                "m": "24",
                "y": "21",
                "k": "0",
                "sort": null,
                "group_id": "39",
                "rgb": "233,201,194"
            },
            {
                "id": "197",
                "r": "235",
                "g": "212",
                "b": "206",
                "c": "9",
                "m": "18",
                "y": "17",
                "k": "0",
                "sort": null,
                "group_id": "39",
                "rgb": "235,212,206"
            },
            {
                "id": "198",
                "r": "237",
                "g": "224",
                "b": "221",
                "c": "8",
                "m": "12",
                "y": "11",
                "k": "0",
                "sort": null,
                "group_id": "39",
                "rgb": "237,224,221"
            },
            {
                "id": "199",
                "r": "255",
                "g": "255",
                "b": "255",
                "c": "0",
                "m": "0",
                "y": "0",
                "k": "0",
                "sort": null,
                "group_id": "39",
                "rgb": "255,255,255"
            }
        ],
        "40": [
            {
                "id": "200",
                "r": "144",
                "g": "159",
                "b": "89",
                "c": "46",
                "m": "20",
                "y": "77",
                "k": "13",
                "sort": null,
                "group_id": "40",
                "rgb": "144,159,89"
            },
            {
                "id": "201",
                "r": "154",
                "g": "169",
                "b": "99",
                "c": "42",
                "m": "18",
                "y": "73",
                "k": "10",
                "sort": null,
                "group_id": "40",
                "rgb": "154,169,99"
            },
            {
                "id": "202",
                "r": "164",
                "g": "179",
                "b": "108",
                "c": "39",
                "m": "15",
                "y": "69",
                "k": "7",
                "sort": null,
                "group_id": "40",
                "rgb": "164,179,108"
            },
            {
                "id": "203",
                "r": "180",
                "g": "195",
                "b": "123",
                "c": "34",
                "m": "10",
                "y": "64",
                "k": "3",
                "sort": null,
                "group_id": "40",
                "rgb": "180,195,123"
            },
            {
                "id": "204",
                "r": "195",
                "g": "209",
                "b": "137",
                "c": "28",
                "m": "7",
                "y": "58",
                "k": "1",
                "sort": null,
                "group_id": "40",
                "rgb": "195,209,137"
            }
        ],
        "41": [
            {
                "id": "205",
                "r": "173",
                "g": "156",
                "b": "194",
                "c": "37",
                "m": "40",
                "y": "6",
                "k": "3",
                "sort": null,
                "group_id": "41",
                "rgb": "173,156,194"
            },
            {
                "id": "206",
                "r": "182",
                "g": "179",
                "b": "210",
                "c": "30",
                "m": "29",
                "y": "4",
                "k": "1",
                "sort": null,
                "group_id": "41",
                "rgb": "182,179,210"
            },
            {
                "id": "207",
                "r": "199",
                "g": "191",
                "b": "215",
                "c": "26",
                "m": "24",
                "y": "6",
                "k": "1",
                "sort": null,
                "group_id": "41",
                "rgb": "199,191,215"
            },
            {
                "id": "208",
                "r": "211",
                "g": "207",
                "b": "225",
                "c": "20",
                "m": "18",
                "y": "5",
                "k": "0",
                "sort": null,
                "group_id": "41",
                "rgb": "211,207,225"
            },
            {
                "id": "209",
                "r": "221",
                "g": "217",
                "b": "235",
                "c": "15",
                "m": "14",
                "y": "2",
                "k": "0",
                "sort": null,
                "group_id": "41",
                "rgb": "221,217,235"
            }
        ],
        "42": [
            {
                "id": "210",
                "r": "222",
                "g": "209",
                "b": "55",
                "c": "16",
                "m": "10",
                "y": "93",
                "k": "0",
                "sort": null,
                "group_id": "42",
                "rgb": "222,209,55"
            },
            {
                "id": "211",
                "r": "228",
                "g": "218",
                "b": "96",
                "c": "13",
                "m": "7",
                "y": "76",
                "k": "0",
                "sort": null,
                "group_id": "42",
                "rgb": "228,218,96"
            },
            {
                "id": "212",
                "r": "232",
                "g": "225",
                "b": "134",
                "c": "12",
                "m": "6",
                "y": "60",
                "k": "0",
                "sort": null,
                "group_id": "42",
                "rgb": "232,225,134"
            },
            {
                "id": "213",
                "r": "241",
                "g": "235",
                "b": "176",
                "c": "7",
                "m": "4",
                "y": "39",
                "k": "0",
                "sort": null,
                "group_id": "42",
                "rgb": "241,235,176"
            },
            {
                "id": "214",
                "r": "244",
                "g": "242",
                "b": "213",
                "c": "5",
                "m": "3",
                "y": "21",
                "k": "0",
                "sort": null,
                "group_id": "42",
                "rgb": "244,242,213"
            }
        ]
    },
    "autocompletedChildrenProjectsNumber": "0",
    "completionPercent": "100",
    "layoutCheckRules": {
        "4": {
            "value": "1",
            "actionNotEqual": "2"
        }
    },
    "userAlbums": []
}