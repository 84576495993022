
interface Options {
    width: number
    height: number
}

export const readImageFile = async (file: File, options?: Options): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        const { width, height } = options ?? { width: 256, height: 256 }

        reader.onload = () => {
            if (reader.result) {

                let img = new Image()
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    canvas.width = width
                    canvas.height = height

                    const aspectRatio = img.width / img.height
                    const maxSize = img.width >= img.height ? { width } : { height }

                    const imgWidth = maxSize.width ? width * aspectRatio : width
                    const imgHeigth = maxSize.height ? height / aspectRatio : height

                    canvas.getContext("2d")?.drawImage(img, canvas.width / 2 - imgWidth / 2, canvas.height / 2 - imgHeigth / 2, imgWidth, imgHeigth)
                    resolve(canvas.toDataURL())
                }
                img.onerror = (e) => {
                    reject(e)
                }
                img.src = reader.result as string
            }
        }
    })
}