import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { SwiperContainer } from "swiper/element"
import { ImageTile } from "./ui/ImageTile"
import { FolderTile } from "./ui/FolderTile"
import { deselectFolder, selectFolder } from "../store/reducers/gallerySlice"
import { setBottomSheetData } from "../store/reducers/bottomSheetSlice"
import { AddIcon, ChevronLeftIcon } from "./ui/icons"
import { setElPosition } from "../store/reducers/onboardingSlice"

// --------------------------------------------------------------------------------

export const MiniGallery: React.FC = () => {
    const dispatch = useAppDispatch()
    const { dragActive } = useAppSelector(state => state.dnd)
    const { gallery, folders, selectedFolder } = useAppSelector(state => state.gallery)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const swiperRef = useRef<SwiperContainer>(null)
    const dndEvents = useDragAndDrop()

    const actionBtnRef = useRef<HTMLDivElement>(null)

    const swiper = swiperRef.current?.swiper

    // Lock slider when drag active
    useEffect(() => {
        if (!swiperRef.current) return
        if (dragActive)
            swiperRef.current.swiper.allowTouchMove = false
        else
            swiperRef.current.swiper.allowTouchMove = true
    }, [swiperRef.current, dragActive])

    useEffect(() => {
        if (swiper) swiper.update()

    }, [swiperRef.current, selectedFolder])

    // Set onboarding el position for gallery step 2
    useEffect(() => {
        if (actionBtnRef.current && onboardingMode === 'gallery' && step === 2) {
            const { height, left, top, width } = actionBtnRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                left: `${left + 1}px`,
                top: `${top + 1}px`,
                width: `${width - 2}px`,
                height: `${height - 2}px`,
            }))
        }
        if (onboardingMode === 'gallery' && folders && !selectedFolder)
            dispatch(selectFolder(folders[0]))
    }, [onboardingMode, step])

    return (
        <>
            <div className="btns gallery" ref={actionBtnRef}>
                {folders && !selectedFolder &&
                    <md-icon-button onClick={() => dispatch(setBottomSheetData({ upload: true }))}>
                        <AddIcon />
                    </md-icon-button>}

                {selectedFolder &&
                    <md-icon-button onClick={() => dispatch(deselectFolder())}>
                        <ChevronLeftIcon />
                    </md-icon-button>}
            </div>
            <swiper-container
                class="gallery"
                ref={swiperRef}
                slides-per-group={4}
                slides-per-view={4}
                lazy-preload-prev-next={5}
            >
                {gallery && gallery.map((image, index) =>
                    <swiper-slide key={`toolbar-panel-gallery-img-${image.url.slice(-10)}`}>
                        <ImageTile el={image} events={dndEvents} index={index === 1 ? index : undefined} lazy />
                    </swiper-slide>
                )}

                {folders && !selectedFolder && folders.map(folder =>
                    <swiper-slide key={`toolbar-panel-gallery-folder-${folder.id}`}>
                        <FolderTile folder={folder} onClick={() => dispatch(selectFolder(folder))} />
                    </swiper-slide>
                )}

                {selectedFolder && selectedFolder.images.map((image, index) =>
                    <swiper-slide key={`toolbar-panel-gallery-img-${image.url.slice(-10) + index}`}>
                        <ImageTile el={image} events={dndEvents} index={index === 1 ? index : undefined} lazy />
                    </swiper-slide>
                )}
            </swiper-container>
        </>
    )
}