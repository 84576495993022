import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ItemComponent, TemplateComponent } from '../../@types/album'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    upload?: boolean
    gallery?: boolean
    editorData?: {
        itemId: string
        templateComponent: TemplateComponent
    }
    template?: boolean
    background?: boolean
    auth?: boolean
    signup?: boolean
    isVisible?: boolean
} = {
    upload: undefined,
    gallery: undefined,
    template: undefined,
    background: undefined,
    auth: undefined,
    signup: undefined,
    isVisible: false
}

const bottomSheetSlice = createSlice({
    name: 'bottomSheet',
    initialState,
    reducers: {

        setBottomSheetData: (state, action: PayloadAction<typeof initialState>) => {
            state.upload = action.payload.upload
            state.gallery = action.payload.gallery
            state.editorData = action.payload.editorData
            state.template = action.payload.template
            state.background = action.payload.background
            state.auth = action.payload.auth
            state.signup = action.payload.signup
            state.isVisible = true
        },

        resetBottomSheetData: (state) => {
            state.upload = undefined
            state.gallery = undefined
            state.template = undefined
            state.background = undefined
            state.auth = undefined
            state.signup = undefined
        },

        hideBottomSheet: (state) => {
            state.isVisible = false
            state.editorData = undefined
        }
    }
})

export const { setBottomSheetData, resetBottomSheetData, hideBottomSheet } = bottomSheetSlice.actions

export default bottomSheetSlice.reducer