import { useCallback, useEffect, useRef, useState } from "react"
import { AlbumSheet } from "./AlbumSheet"
import { useAppDispatch, useAppSelector } from "../store"
import { SwiperContainer } from "swiper/element"
import {
    addAlbumItem,
    addImageToSheet,
    disablePriviewMode,
    enablePreviewMode,
    resetAlbum,
    setActiveItemId,
    setImagesUsed,
    setItemSortOrder,
    setSheetIsFull
} from "../store/reducers/albumSlice"
import { Item } from "../@types/album"
import Swiper from "swiper"
import { sortItems } from "../utils/sort"
import { showSnackbar } from "../store/reducers/snackbarSlice"
import {
    AddIcon,
    CartIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DownloadIcon,
    ErrorInfoIcon,
    HelpIcon,
    SettingsIcon
} from "./ui/icons"
import { ModalBottomSheet } from "./ui/ModalBottomSheet"
import { resetOnboarding, setElPosition } from "../store/reducers/onboardingSlice"
import { Helmet } from "react-helmet"
import { DesktopToolbar } from "./DesktopToolbar"
import { MdRipple } from "@material/web/ripple/ripple"
import { AlbumItem } from "../../defaultItem"

// --------------------------------------------------------------------------------

export const Main: React.FC = () => {
    const dispatch = useAppDispatch()
    const {
        album,
        previewMode,
        imageErrors,
        textErrors,
        sheetIsFull,
        imgToAdd,
        isEditable
    } = useAppSelector(state => state.album)
    const {
        dragComponent,
        draggedSheetId,
        dragActive
    } = useAppSelector(state => state.dnd)
    const { isVisible: bsVisible } = useAppSelector(state => state.bottomSheet)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const { textEditMode } = useAppSelector(state => state.textEditor)

    const swiperContainerRef = useRef<HTMLDivElement>(null)
    const swiperRef = useRef<SwiperContainer>(null)
    const swiperNavRef = useRef<SwiperContainer>(null)
    const mainRef = useRef<HTMLElement>(null)
    const timer = useRef<number>()
    const hoveredSlide = useRef<number>(0)
    const shoppingCardRef = useRef<HTMLDivElement>(null)
    const slideChanged = useRef(false)
    const blankComponents = useRef(0)
    const nextButton = useRef<HTMLDivElement>(null)
    const prevButton = useRef<HTMLDivElement>(null)
    const prevRippleRef = useRef<MdRipple>(null)
    const nextRippleRef = useRef<MdRipple>(null)

    const [albumItems, setAlbumItems] = useState<Item[]>()
    const [errorsModalOpened, setErrorsModalOpened] = useState(false)

    const isMobile = 'ontouchstart' in window

    // Slides drag event callback
    const handleTouchMove = useCallback((e: TouchEvent | PointerEvent) => {
        const swiper = isMobile ? swiperRef.current?.swiper : swiperNavRef.current?.swiper
        const height = document.body.getBoundingClientRect().height

        let touch
        if ('touches' in e)
            touch = e.touches[0]
        else
            touch = e

        const y = touch.clientY

        // "Scroll" slider when dragging slide
        if (y / height <= 0.2 && !timer.current) {
            timer.current = setInterval(() => swiper?.slidePrev(), 800)

            if (!slideChanged.current) {
                slideChanged.current = true
                swiper?.slidePrev()
            }
        }

        if (y / height >= 0.8 && !timer.current) {
            timer.current = setInterval(() => swiper?.slideNext(), 800)

            if (!slideChanged.current) {
                slideChanged.current = true
                swiper?.slideNext()
            }
        }

        if (timer.current && 0.2 < y / height && y / height < 0.8) {
            clearInterval(timer.current)
            timer.current = undefined
            slideChanged.current = false
        }

        // Set new album order on slide hovered by dragged element
        swiper?.slides.forEach((slide, index) => {
            const { top, bottom } = slide.getBoundingClientRect()
            if (draggedSheetId === undefined || slideChanged.current) return

            if (top < y && y < bottom && hoveredSlide.current !== index && album) {
                hoveredSlide.current = index

                let hoveredSheetId = (slide.children[0] as HTMLDivElement).dataset.sheetId
                if (!hoveredSheetId || draggedSheetId === hoveredSheetId || album.items[hoveredSheetId].params.target_id === '2') return

                dispatch(setItemSortOrder([
                    { id: draggedSheetId, sort: album.items[hoveredSheetId].params.sort },
                    { id: hoveredSheetId, sort: album.items[draggedSheetId].params.sort },
                ]))
            }

        })
    }, [dragComponent, swiperRef.current, swiperNavRef.current, draggedSheetId, album, timer])

    // Submit preview mode
    const handleStartPreviewMode = () => {
        showSnackbar({
            text: 'Перейти в предпросмотр?',
            actions: {
                submit: 'ДА',
                cancel: 'НЕТ',
                submitAction: () => dispatch(enablePreviewMode())
            },
        })
    }

    // Check errors and proceed to shpping cart
    const handleShoppingCardClick = () => {
        if (!album) return

        blankComponents.current = Object.values(album.items)
            .map(item => {
                const imageTemplateComponents = item.template.components.filter(comp => comp.component_id === '3').map(comp => comp.id)
                const imageComponents = item.components.filter(comp => imageTemplateComponents.includes(comp.template_component_id))
                return imageTemplateComponents.length - imageComponents.length
            })
            .reduce((sum, val) => sum + val, 0)

        setErrorsModalOpened(true)
    }

    const handleProjectSubmit = () => {
        setErrorsModalOpened(false)

        // TODO: save project and navigate to shopping cart
    }

    // Add sheet
    const handleAddSheet = () => {
        if (!album || !swiperRef.current) return

        const swiper = swiperRef.current.swiper

        const ids = Object.values(album.items).map(item => +item.params.id)
        const newId = Math.max(...ids) + 1
        const newIndex = Number(ids.length + 2)
        const newItem = new AlbumItem(newId, newIndex).toJSON()

        setTimeout(() => {
            dispatch(addAlbumItem(newItem))
            setTimeout(() => swiper?.slideTo(newIndex), 500)
        }, 300)
    }

    // Add touchmove listener on drag start
    useEffect(() => {
        if (dragComponent === "sheet") {
            if (isMobile)
                document.addEventListener('touchmove', handleTouchMove, { passive: true })
            else
                document.addEventListener('pointermove', handleTouchMove, { passive: true })
        }

        return () => {
            document.removeEventListener('touchmove', handleTouchMove)
            document.removeEventListener('pointermove', handleTouchMove)
            if (timer.current) clearInterval(timer.current)
            timer.current = undefined
        }

    }, [dragComponent, draggedSheetId, album])

    // Lock swiper 
    useEffect(() => {
        if (!swiperRef.current || !isMobile) return
        const swiper = swiperRef.current.swiper

        if (dragActive || bsVisible)
            swiper.allowTouchMove = false
        else
            swiper.allowTouchMove = true

    }, [dragActive, bsVisible])


    // Get sheet id from dataset
    const getActiveItemIdByIndex = (index: number, swiper: Swiper) => {
        return (swiper.slides[index].children[0] as HTMLDivElement).dataset.sheetId ?? ''
    }

    // Set active slide and hide header
    useEffect(() => {
        if (swiperRef.current && mainRef.current) {
            const swiper = swiperRef.current.swiper

            swiperRef.current.addEventListener('touchend', () => {
                swiper.slides.map((el, index) => {
                    el.classList.toggle('active', swiper.activeIndex === index)
                })
            })

            swiperRef.current.addEventListener('slidermove', () => {
                swiper.slides.map((el) => {
                    el.classList.remove('active')
                })
            })

            swiperRef.current.addEventListener('slidechange', () => {
                mainRef.current?.classList.toggle('hidden-header', swiper.activeIndex === 0)

                dispatch(setActiveItemId(getActiveItemIdByIndex(swiper.activeIndex, swiper)))

                shoppingCardRef.current?.classList.toggle('small', swiper.activeIndex > swiper.previousIndex)

                prevButton.current?.classList.toggle('disabled', swiper.activeIndex === 0)
                nextButton.current?.classList.toggle('disabled', swiper.activeIndex === swiper.slides.length - 1)

                if (nextRippleRef.current && prevRippleRef.current) {
                    prevRippleRef.current.disabled = swiper.activeIndex === 0
                    nextRippleRef.current.disabled = swiper.activeIndex === swiper.slides.length - 1
                }
            })

            // Update slide size on container dimensions change
            if (swiperContainerRef.current && swiperRef.current)
                new ResizeObserver(([entry]) => {
                    if (!album || !swiperRef.current) return

                    let prev = 0
                    console.log(entry.contentRect.height)

                    const albumWidth = +album.product.width + +album.product.cut_zone * 2
                    const albumHeight = +album.product.height + +album.product.cut_zone * 2
                    const aspectRatio = albumWidth / albumHeight

                    const containerHeight = entry.contentRect.height - 40

                    let sWidth = swiper.width * .7
                    let sHeight = sWidth / aspectRatio

                    if (sHeight > containerHeight)
                        sWidth = containerHeight * aspectRatio

                    document.documentElement.style.setProperty('--slide-width', `${sWidth.toFixed(0)}px`)

                    swiperRef.current.spaceBetween = (swiper.width - sWidth * 1.2) / 2

                }).observe(swiperContainerRef.current)
        }

    }, [swiperRef.current, mainRef.current])

    // Set sorted album items
    useEffect(() => {
        album?.items &&
            setAlbumItems(Object.values(album.items).sort(sortItems))

        dispatch(setImagesUsed())
    }, [album])

    // Update swiper
    useEffect(() => {
        if (!albumItems) return

        const swiper = swiperRef.current?.swiper
        const swiperNav = swiperNavRef.current?.swiper

        swiperNav?.update()
        swiper?.update()
        swiper?.slides.map((el, index) => {
            el.classList.toggle('active', swiperRef.current?.swiper.activeIndex === index)
        })

        if (swiper)
            dispatch(setActiveItemId(getActiveItemIdByIndex(swiper.activeIndex, swiper)))

    }, [albumItems])

    // Unable preview mode for album
    useEffect(() => {
        if (!swiperRef.current) return

        if (previewMode) {
            swiperRef.current.freeMode = { enabled: true }
            swiperRef.current.centeredSlides = false
            swiperRef.current.slidesOffsetAfter = 200
        } else {
            swiperRef.current.freeMode = { enabled: false }
            swiperRef.current.centeredSlides = true
            swiperRef.current.slidesOffsetAfter = undefined
        }

        setTimeout(() =>
            swiperRef.current?.swiper.update(), 300)
    }, [previewMode])

    // Slede to the next sheet if the current one is full
    useEffect(() => {
        if (sheetIsFull && swiperRef.current) {
            swiperRef.current.swiper.slideNext()

            dispatch(setSheetIsFull(false))

            if (imgToAdd)
                setTimeout(() =>
                    dispatch(addImageToSheet(imgToAdd)))
        }
    }, [sheetIsFull])

    // set onboarding el position for main step 5
    useEffect(() => {
        if (shoppingCardRef.current && onboardingMode === 'main' && step === 5) {
            const { height, left, top, width } = shoppingCardRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                left: `${left - 8}px`,
                top: `${top - 8}px`,
                width: `${width + 16}px`,
                height: `${height + 16}px`,
            }))
        }
    }, [onboardingMode, step])

    if (!album || !albumItems) return null

    return (
        <main
            ref={mainRef}
            className={`${isMobile ? 'mobile' : 'desktop'} ${previewMode ? 'preview' : ''} ${textEditMode ? 'hidden' : ''}`}>

            <Helmet>
                {previewMode && <meta name="theme-color" content="#1b1b1f" />}
            </Helmet>

            <div className="main-container">
                <div className="swiper-container" ref={swiperContainerRef}>
                    <div>
                        <swiper-container
                            direction={isMobile ? 'vertical' : 'horizontal'}
                            slides-per-view={'auto'}
                            centered-slides={isEditable}
                            free-mode={!isEditable}
                            slides-offset-after={!isEditable ? 200 : undefined}
                            ref={swiperRef}
                            allow-touch-move={isMobile}
                            thumbs-swiper=".nav-swiper"
                            speed={800}
                        >
                            {previewMode && isMobile &&
                                <swiper-slide class="preview-slide">
                                    <span>«{album.params.name}»</span>
                                    <span>{album.product.name}</span>
                                </swiper-slide>}

                            {albumItems.map((data, index) =>
                                <AlbumSheet
                                    itemId={data.params.id}
                                    itemIndex={index}
                                    activeIndex={swiperRef.current?.swiper?.activeIndex ?? 0}
                                    data={data}
                                    swiper={swiperRef.current?.swiper}
                                    key={`layout-frame-${data.params.id}`}
                                />)}

                        </swiper-container>

                        {!isMobile &&
                            <>
                                <div
                                    className="prev-region"
                                    onClick={() => swiperRef.current?.swiper.slidePrev()}
                                />
                                <div
                                    ref={prevButton}
                                    className="prev-button disabled"
                                    onClick={() => swiperRef.current?.swiper.slidePrev()}
                                >
                                    <md-ripple ref={prevRippleRef} disabled />
                                    <ChevronLeftIcon />
                                </div>
                                <div
                                    ref={nextButton}
                                    className="next-button"
                                    onClick={() => swiperRef.current?.swiper.slideNext()}
                                >
                                    <md-ripple ref={nextRippleRef} />
                                    <ChevronRightIcon />
                                </div>
                                <div
                                    className="next-region"
                                    onClick={() => swiperRef.current?.swiper.slideNext()}
                                />
                            </>}
                    </div>
                </div>

                {!isMobile &&
                    <>
                        <div className="main-container-tools">
                            <div className={`product-name`}>Размер: {album && album.product.name}</div>

                            <md-text-button>
                                <SettingsIcon slotted />
                                Изменить параметры
                            </md-text-button>
                            <md-text-button onClick={() => dispatch(resetAlbum())}>
                                <DownloadIcon slotted />
                                Скачать в PDF
                            </md-text-button>
                        </div>
                        <div className="main-container-help">
                            <md-text-button onClick={() => dispatch(resetOnboarding())}>
                                <HelpIcon slotted />
                                Помощь
                            </md-text-button>
                        </div>
                        <DesktopToolbar />
                    </>}
            </div>

            {!isMobile &&
                <>
                    <div className="nav-container">
                        <div className="nav-title">
                            <span>Развороты</span>
                            {Object.entries(album.items).length} шт
                        </div>
                        <swiper-container
                            class="nav-swiper"
                            direction={'vertical'}
                            slides-per-view={'auto'}
                            ref={swiperNavRef}
                            allow-touch-move={false}
                            free-mode
                            mousewheel
                        >
                            {albumItems.map((data, index) =>
                                <AlbumSheet
                                    itemId={data.params.id}
                                    itemIndex={index}
                                    activeIndex={swiperRef.current?.swiper.activeIndex}
                                    data={data}
                                    swiper={swiperRef.current?.swiper}
                                    key={`layout-frame-nav-${data.params.id}`}
                                    navPanel
                                />)}
                        </swiper-container>
                        <div className="add-sheet">
                            <md-text-button onClick={handleAddSheet}>
                                <AddIcon slotted />
                                <span>
                                    Добавить разворот
                                    <div>+34 ₽</div>
                                </span>
                            </md-text-button>
                        </div>
                    </div>
                </>}

            {isEditable &&
                <div
                    className={`shopping-cart ${previewMode ? 'preview' : ''}`}
                    ref={shoppingCardRef}
                    onClick={previewMode ? handleShoppingCardClick : handleStartPreviewMode}
                >
                    <md-elevation />
                    <md-ripple />
                    <CartIcon slotted />
                    <span>
                        В корзину {Number(album?.params.price).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}
                    </span>
                </div>}

            {previewMode && isEditable &&
                <div className='preview-header'>
                    <md-icon-button onClick={() => dispatch(disablePriviewMode())}>
                        <ChevronLeftIcon />
                    </md-icon-button>
                    <span>
                        Предпросмотр
                    </span>
                </div>}

            <ModalBottomSheet
                state={errorsModalOpened}
                setState={setErrorsModalOpened}
                className='album-errors-modal'
            >
                <span>Подтверждение дизайна</span>
                <div>

                    {imageErrors.length > 0 &&
                        <div>
                            <ErrorInfoIcon />
                            <span>Низкое качество фотографий</span>
                            <span>Замените изображение на более качественные</span>
                        </div>}

                    {textErrors.length > 0 &&
                        <div>
                            <ErrorInfoIcon />
                            <span>Ошибки в текстовых полях</span>
                            <span>Пожалуйста, отредактируйте текст, чтобы он умещался в выделенную область</span>
                        </div>}

                    {blankComponents.current !== 0 &&
                        <div>
                            <ErrorInfoIcon />
                            <span>Незаполненные поля</span>
                            <span>Пожалуйста, заполните {blankComponents.current} незаполненных {blankComponents.current.declination(['поле', 'поля', 'полей'])}</span>
                        </div>}

                </div>

                {(blankComponents.current !== 0 || imageErrors.length > 0 || textErrors.length > 0)
                    ?
                    <>
                        <md-filled-button
                            onClick={() => {
                                setErrorsModalOpened(false)
                                dispatch(disablePriviewMode())
                            }}
                        >
                            Вернуться и исправить
                        </md-filled-button>
                        <md-outlined-button
                            onClick={handleProjectSubmit}
                        >
                            Подтверждаю
                        </md-outlined-button>
                    </>
                    :
                    <md-filled-button
                        onClick={handleProjectSubmit}
                    >
                        Подтверждаю
                    </md-filled-button>}

            </ModalBottomSheet>
        </main>
    )
}