import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TemplateComponent } from '../../@types/album'
import { EditorState, LexicalEditor } from 'lexical'
import { MutableRefObject } from 'react'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    itemId?: string
    templateComponent?: TemplateComponent
    isVisible?: boolean
    textEditMode?: boolean
    slideIndex?: number
    position?: DOMRect
    ratio?: number
} = {
    isVisible: false,
    textEditMode: false,
    slideIndex: 0
}

const textEditorSlice = createSlice({
    name: 'textEditor',
    initialState,
    reducers: {

        enableTextEditMode: (state, action: PayloadAction<Partial<typeof initialState>>) => {
            state.textEditMode = true
            state.slideIndex = action.payload.slideIndex
            state.ratio = action.payload.ratio
            state.templateComponent = action.payload.templateComponent
            state.itemId = action.payload.itemId
        },

        setTextEditor: (state, action: PayloadAction<Partial<typeof initialState>>) => {
            state.position = action.payload.position
            state.isVisible = true
        },

        hideTextEditor: (state) => {
            state.templateComponent = undefined
            state.itemId = undefined
            state.isVisible = false
        },

        disableTextEditMode: (state) => {
            state.textEditMode = false
            state.templateComponent = undefined
            state.itemId = undefined
            state.isVisible = false
        },
    }
})

export const { setTextEditor, hideTextEditor, enableTextEditMode, disableTextEditMode } = textEditorSlice.actions

export default textEditorSlice.reducer