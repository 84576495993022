import { useCallback, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../store"
import { IS_APPLE } from "../utils/environment"

// --------------------------------------------------------------------------------

export const useBottomSheetSwipe = () => {
    const nav = useNavigate()
    const { dragActive } = useAppSelector(state => state.dnd)

    const el = useRef<HTMLDivElement | null>(null)
    const y = useRef(0)
    const yPrev = useRef(0)

    const start = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        if (!e.touches) return
        el.current = e.currentTarget
        yPrev.current = e.touches[0].clientY
    }, [])

    const move = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        if (!dragActive && el.current && e.touches) {
            y.current = yPrev.current - e.touches[0].clientY
            yPrev.current = e.touches[0].clientY

            const swiper = el.current?.getElementsByTagName('swiper-container')[0]?.swiper
            const form = swiper
                ? swiper.slides[swiper.activeIndex].children[0] as HTMLFormElement // active slide form element
                : null

            const content = el.current.children[1] as HTMLDivElement // Gallery, templates, backgrounds

            const bsContainer = form ?? content

            const { top } = el.current.getBoundingClientRect()
            const titleIsTarget = top - e.touches[0].clientY > -80

            if (bsContainer.scrollTop === 0 || titleIsTarget) {
                if (!IS_APPLE)
                    bsContainer.classList.toggle('no-scroll', top !== 0)

                let elTop = top - y.current > 0 ? top - y.current : 0
                el.current.style.top = `${elTop}px`
                el.current.style.transition = 'none'
            }
        }
    }, [dragActive])

    const end = useCallback(() => {
        if (el.current) {
            if (el.current.getBoundingClientRect().top > 50 && !dragActive) nav(-1)
            el.current.removeAttribute('style')
            el.current = null
        }
    }, [dragActive])

    return {
        onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => start(e),
        onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => move(e),
        onTouchEnd: (e: React.TouchEvent<HTMLDivElement>) => end()
    } as const
}