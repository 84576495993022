import { useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { Item } from "../@types/album"
import { SheetComponent } from "./SheetComponent"
import { addAlbumItem, removeActiveItem, setBackground, setTemplate } from "../store/reducers/albumSlice"
import { useDragAndDrop } from "../hooks/useDragAndDrop"
import { AlbumItem } from "../../defaultItem"
import { SwiperSlide } from "swiper/element"
import Swiper from "swiper"
import { showSnackbar } from "../store/reducers/snackbarSlice"
import { sortByLevel } from "../utils/sort"
import { AddIcon, DensityIcon, MinusCircleIcon } from "./ui/icons"
import { setElPosition } from "../store/reducers/onboardingSlice"
import { MdIconButton } from "@material/web/iconbutton/icon-button"

// --------------------------------------------------------------------------------

interface AlbumSheetProps {
    itemId: string
    itemIndex: number
    activeIndex?: number
    data: Item
    swiper?: Swiper
    navPanel?: boolean
}

export const AlbumSheet: React.FC<AlbumSheetProps> = ({ itemId, itemIndex, activeIndex, data, swiper, navPanel }) => {
    const {
        dropPosition,
        dragActive,
        dragComponent,
        backgroundGroupId,
        backgroundId,
        templateId,
        templateTargetId
    } = useAppSelector(state => state.dnd)
    const { album, previewMode } = useAppSelector(state => state.album)
    const { onboardingMode, step } = useAppSelector(state => state.onboarding)
    const dispatch = useAppDispatch()
    const dndEvents = useDragAndDrop()

    const slideRef = useRef<SwiperSlide>(null)
    const sheetRef = useRef<HTMLDivElement>(null)
    const addBtnRef = useRef<MdIconButton>(null)

    const isMobile = 'ontouchstart' in window
    const slidesPositionDndEvents = isMobile || navPanel ? dndEvents : {}

    // Remove sheet
    const handleRemove = () => {
        showSnackbar({
            text: 'Удалить разворот?',
            actions: {
                cancel: 'НЕТ',
                submit: 'ДА',
                submitAction: () => {
                    slideRef.current?.classList.add('remove')
                    setTimeout(() => dispatch(removeActiveItem()), 300)

                    setTimeout(() => {
                        showSnackbar({
                            text: 'Разворот удален.',
                            position: 'top',
                            leadingIcon:
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#22543D" />
                                    <path d="M16.7062 9.7071C17.0967 9.31657 17.0967 8.68341 16.7062 8.29289C16.3156 7.90237 15.6825 7.90237 15.2919 8.2929L10.4389 13.146L8.70709 11.4143C8.31655 11.0238 7.68339 11.0238 7.29287 11.4143C6.90236 11.8048 6.90238 12.438 7.29291 12.8285L9.73186 15.2673C10.1224 15.6578 10.7555 15.6578 11.1461 15.2673L16.7062 9.7071Z" fill="#68D391" />
                                </svg>
                        })
                    }, 500)
                }
            }
        })
    }

    // Add sheet
    const handleAdd = () => {
        if (!album || !swiper) return
        swiper.allowTouchMove = false

        const ids = Object.values(album.items).map(item => +item.params.id)
        const newId = Math.max(...ids) + 1
        const newIndex = Number(itemIndex + 2)
        const newItem = new AlbumItem(newId, newIndex).toJSON()

        // slideRef.current?.classList.add('new')
        setTimeout(() => {
            dispatch(addAlbumItem(newItem))
            setTimeout(() => swiper?.slideTo(itemIndex + 1))
            swiper.allowTouchMove = true
        }, 300)
    }

    // Handle template and background drop
    useEffect(() => {
        if (!sheetRef.current || dropPosition.length === 0 || !album) return
        const { top, bottom, left, right } = sheetRef.current.getBoundingClientRect()
        const [y, x] = dropPosition

        const miniPanelTop = document.getElementById('mini-panel')?.getBoundingClientRect().top ?? window.innerHeight - 110
        if (y > miniPanelTop) return


        const itemTargetId = album.items[itemId].params.target_id

        if (top < y && y < bottom && left < x && x < right)
            switch (true) {

                case !!backgroundId:
                    backgroundId && backgroundGroupId &&
                        dispatch(setBackground({
                            itemId,
                            backgroundGroupId,
                            backgroundId
                        }))
                    break

                case !!templateId:
                    templateId && itemTargetId === templateTargetId &&
                        dispatch(setTemplate({
                            itemId,
                            templateId
                        }))
                    break
            }

    }, [dropPosition])

    // Set global var with slide height
    useEffect(() => {
        if (!slideRef.current) return
        const slideHeight = `${slideRef.current.getBoundingClientRect().height}px`

        if (!document.documentElement.style.getPropertyValue('--slide-height'))
            document.documentElement.style.setProperty('--slide-height', slideHeight)

        setTimeout(() => slideRef.current?.removeAttribute('style'), 100)
    }, [slideRef.current])

    // set onboarding el position for main step 3 and step 4
    useEffect(() => {
        if (sheetRef.current && onboardingMode === 'main' && step === 3 && activeIndex === itemIndex) {
            const { height, left, top, width } = sheetRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                height: `${height}px`,
                left: `${left}px`,
                top: `${top}px`,
                width: `${width}px`,
            }))
        }
        if (addBtnRef.current && onboardingMode === 'main' && step === 4 && activeIndex === itemIndex) {
            const { left, top } = addBtnRef.current.getBoundingClientRect()
            dispatch(setElPosition({
                left: `${left + 4}px`,
                top: `${top + 4}px`,
                width: `32px`,
                height: `32px`,
            }))
        }
    }, [onboardingMode, step])

    if (!album) return null

    const bgrRGB = album.items[itemId] && album.items[itemId].color?.rgb
        ? album.items[itemId].color?.rgb
        : '255,255,255'

    const events = data.params.target_id === '3' ? dndEvents : {}

    const albumWidth = +album.product.width + +album.product.cut_zone * 2
    const albumHeight = +album.product.height + +album.product.cut_zone * 2

    const showDropArea = dragComponent === 'bgr' || dragComponent === 'tmpl'

    return (
        <>
            <swiper-slide
                ref={slideRef}
                class={`${dragComponent !== 'sheet' ? 'selector' : ''} ${previewMode ? 'preview' : ''}`}
            >
                <div
                    ref={sheetRef}
                    className={`sheet spread draggable ${previewMode ? 'preview' : ''}`}
                    style={{
                        aspectRatio: album ? `${albumWidth}/${albumHeight}` : 'unset',
                        backgroundColor: `rgb(${bgrRGB})`
                    }}
                    data-sheet-id={itemId}
                    data-sheet-index={itemIndex}
                    {...slidesPositionDndEvents}
                >
                    <md-elevation />
                    <svg
                        viewBox={`0 0 ${albumWidth} ${albumHeight}`}
                    >
                        {data.template.components
                            .slice()
                            .sort(sortByLevel)
                            .map(component =>
                                <SheetComponent
                                    itemId={itemId}
                                    slideIndex={itemIndex}
                                    templateComponent={component}
                                    itemComponent={data.components.find(c => c.template_component_id === component.id)}
                                    key={`sheet-component-${component.id}`}
                                    navPanel={navPanel}
                                />
                            )}
                    </svg>

                    {isMobile && itemIndex === activeIndex && data.params.target_id === '3' && !dragActive && !previewMode &&
                        <md-icon-button onClick={handleRemove} class="remove">
                            <MinusCircleIcon />
                        </md-icon-button>}

                    {isMobile && !dragActive && !previewMode &&
                        <md-icon-button onClick={handleAdd} class="add" ref={addBtnRef}>
                            <AddIcon />
                        </md-icon-button>}

                    {(isMobile || navPanel) && itemIndex === 0 && data.params.target_id === '2' &&
                        <div className="caption">
                            <span>Задняя обложка</span>
                            <span>Передняя обложка</span>
                        </div>}

                    {(isMobile || navPanel) && itemIndex > 0 && data.params.target_id === '3' &&
                        <div className="caption">
                            <span>{itemIndex * 2 - 1}</span>
                            <span>{itemIndex * 2}</span>
                        </div>}

                    {!navPanel && showDropArea &&
                        <div className={`drop-area ${dragComponent}`} />}

                    {previewMode &&
                        <div className={'book-layout'} />}

                    {navPanel &&
                        <div className="grab-icon">
                            <DensityIcon />
                        </div>}

                </div >
            </swiper-slide>

        </>
    )
}