import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TemplateComponent } from '../../@types/album'
// @types


// --------------------------------------------------------------------------------

const initialState: {
    opened: boolean
    headline?: string
    content?: string
    actions?: {
        submit?: string
        submitAction: () => void
        cancel?: string
        cancelAction?: () => void
    }
} = {
    opened: false
}

const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {

        showDialog: (state, action: PayloadAction<Partial<typeof initialState>>) => {
            state.opened = true
            state.headline = action.payload.headline
            state.content = action.payload.content
            state.actions = action.payload.actions
        },

        closeDialog: (state) => {
            state.opened = false
            state.headline = state.actions = state.content = undefined
        }
    }
})

export const { showDialog, closeDialog } = dialogSlice.actions

export default dialogSlice.reducer